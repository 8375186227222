import { DomainBacklinkResponse } from '../../types/domainBacklinks.type';

class DomainsBacklinksDto {
  response: DomainBacklinkResponse;
  constructor(data: DomainBacklinkResponse) {
    this.response = data;
  }
}

export default DomainsBacklinksDto;
