import { subDays, format, parseISO } from 'date-fns';

export const calculatePreviousDateRange = (startDate: string, endDate: string) => {
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  const daysDifference = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
  const previousEnd = subDays(start, 1);
  const previousStart = subDays(previousEnd, daysDifference);
  return {
    startDate: format(previousStart, 'yyyy-MM-dd'),
    endDate: format(previousEnd, 'yyyy-MM-dd')
  };
};

export const calculatePercentageDifference = (current: number, previous: number) => {
  return previous === 0 ? 0 : ((current - previous) / previous) * 100;
};

export const calculateListPercentageDifference = (currentList: any[], previousList: any[]) => {
  const diffList = currentList.map((current, index) => {
    const previous = previousList[index] || {};
    return {
      ...current,
      clicksDiff: calculatePercentageDifference(current.clicks, previous.clicks || 0),
      ctrDiff: (current.ctr - (previous.ctr || 0)),
      impressionsDiff: calculatePercentageDifference(current.impressions, previous.impressions || 0),
      positionDiff: (previous.position || 0) - current.position 
    };
  });
  return diffList;
};

export const findMaxAndMinClicks = (items: any) => {
  if (!items || items.length === 0) return { maxClicksItem: null, minClicksItem: null };

  let maxClicksItem = items[0];
  let minClicksItem = items[0];

  let maxImpressionsItem = items[0];
  let maxCTRItem = items[0];
  let maxPositionItem = items[0];

  items.forEach((item: any) => {
    if (item.clicks > maxClicksItem.clicks) maxClicksItem = item;
    if (item.clicks < minClicksItem.clicks) minClicksItem = item;

    if (item.impressions > maxImpressionsItem.impressions) maxImpressionsItem = item;
    if (item.ctr > maxCTRItem.ctr) maxCTRItem = item;
    if (item.position < maxPositionItem.position) maxPositionItem = item; 
  });

  return {
    maxClicksItem,
    minClicksItem,
    maxImpressionsItem,
    maxCTRItem,
    maxPositionItem,
  };
};

export const calculateDisplayedPercentages = (rows: any, maxMinClicks: any, metrics: any) => {
  if (!rows || rows.length === 0) return [];

  const {
    maxClicksItem = {},
    maxImpressionsItem = {},
    maxCTRItem = {},
    maxPositionItem = {},
  } = maxMinClicks || {};

  return rows.map((row: any) => {
    let percentageValue = 0;

    if (metrics.clicks && row.clicks !== undefined) {
      percentageValue = (100 * parseFloat(row.clicks)) / (maxClicksItem.clicks || 1);
    } else if (metrics.impressions && row.impressions !== undefined) {
      percentageValue = (100 * parseFloat(row.impressions)) / (maxImpressionsItem.impressions || 1);
    } else if (metrics.ctr && row.ctr !== undefined) {
      percentageValue = (100 * parseFloat(row.ctr)) / (maxCTRItem.ctr || 1);
    } else if (metrics.position && row.position !== undefined) {
      percentageValue = (100 * parseFloat(maxPositionItem.position)) / (row.position || 1);
    }

    return percentageValue || 0;
  });
};

const roundToTwoDecimal = (num) => Math.round(num * 100) / 100;

export const calculateDifferences = (firstData, secondData) => {
  if (!firstData || !secondData) return;

  const firstMap = new Map(firstData.items.map(item => [item.url, item.data]));
  const secondMap = new Map(secondData.items.map(item => [item.url, item.data]));

  const differences = {};

  for (const [url, firstEntries] of firstMap.entries()) {
    const secondEntries = secondMap.get(url);

    if (!secondEntries) continue;

    const firstTotals = firstEntries.reduce((acc, entry) => {
      acc.clicks += entry.clicks;
      acc.impressions += entry.impressions;
      acc.ctr += entry.ctr * entry.impressions;
      acc.position += entry.position * entry.impressions;
      return acc;
    }, { clicks: 0, impressions: 0, ctr: 0, position: 0 });

    firstTotals.ctr /= firstTotals.impressions;
    firstTotals.position /= firstTotals.impressions;

    const secondTotals = secondEntries.reduce((acc, entry) => {
      acc.clicks += entry.clicks;
      acc.impressions += entry.impressions;
      acc.ctr += entry.ctr * entry.impressions;
      acc.position += entry.position * entry.impressions;
      return acc;
    }, { clicks: 0, impressions: 0, ctr: 0, position: 0 });

    secondTotals.ctr /= secondTotals.impressions;
    secondTotals.position /= secondTotals.impressions;

    differences[url] = {
      clicksDiff: roundToTwoDecimal(calculatePercentageDifference(firstTotals.clicks, secondTotals.clicks)),
      impressionsDiff: roundToTwoDecimal(calculatePercentageDifference(firstTotals.impressions, secondTotals.impressions)),
      ctrDiff: roundToTwoDecimal(firstTotals.ctr - secondTotals.ctr),
      positionDiff: roundToTwoDecimal(firstTotals.position - secondTotals.position),
      secondTotals: {
        ...secondTotals,
        ctr: roundToTwoDecimal(secondTotals.ctr * 100),
        position: roundToTwoDecimal(secondTotals.position)
      }
    };
  }

  return differences;
};