import React from 'react';
import { useTranslation } from 'react-i18next';
import './IsLowThinContent.scss';
import { Stack, Tooltip, Typography } from '@mui/material';

interface Props {
  data?: {
    is_low_by_clicks?: boolean;
    is_low_by_impressions?: boolean;
    is_low_by_ctr?: boolean;
  };
}

const IsLowThinContent: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  if (data === undefined) return null;
  let is_low_by_clicks = data.is_low_by_clicks;
  let is_low_by_impressions = data.is_low_by_impressions;
  let is_low_by_ctr = data.is_low_by_ctr;

  if (is_low_by_clicks === undefined) return null;
  if (is_low_by_impressions === undefined) return null;
  if (is_low_by_ctr === undefined) return null;

  return (
    <Stack direction="row" spacing={1}>
      
        <Tooltip
          arrow
          followCursor
          title={
            <div>
              <div>{t(`is_low_by_clicks_${is_low_by_clicks.toString()}_tooltip`)}</div>
            </div>
          }>
          <div className="thin-content-problems">
            <div className={`border ${is_low_by_clicks.toString()}`}>
              <Typography fontWeight={'700'} className={is_low_by_clicks.toString()}>
                {t(`clicks`)}
              </Typography>
            </div>
          </div>
        </Tooltip>
      

      
        <Tooltip
          arrow
          followCursor
          title={
            <div>
              <div>{t(`is_low_by_impressions_${is_low_by_impressions.toString()}_tooltip`)}</div>
            </div>
          }>
          <div className="thin-content-problems">
            <div className={`border ${is_low_by_impressions.toString()}`}>
              <Typography fontWeight={'700'} className={is_low_by_impressions.toString()}>
                {t(`impressions`)}
              </Typography>
            </div>
          </div>
        </Tooltip>
      

        <Tooltip
          arrow
          followCursor
          title={
            <div>
              <div>{t(`is_low_by_ctr_${is_low_by_ctr.toString()}_tooltip`)}</div>
            </div>
          }>
          <div className="thin-content-problems">
            <div className={`border ${is_low_by_ctr.toString()}`}>
              <Typography fontWeight={'700'} className={is_low_by_ctr.toString()}>
                {t(`ctr`)}
              </Typography>
            </div>
          </div>
        </Tooltip>
      
    </Stack>
  );
};

export default IsLowThinContent;
