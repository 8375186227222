import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../../hooks/useUsers';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useColorModeContext } from '../../context/ColorModeContext';
import { classNames, generateIcon, getBaseUrl } from '../../components/Utils/Utils';
import { SERVICE_USERS_POST_SAVE_INVOICES } from '../../services/Endpoint';

import {
  DISABLED_FILTERABLE,
  COLUMNS_ACCOUNT_BILLINGS
} from '../../components/Utils/TableConstants';

import { Stack, Button, Typography, CircularProgress } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import TableComponent from '../../components/TableComponent';

export const AccountBillingsPage = () => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const { mode } = useColorModeContext();
  const { error, invoices, loadingInvoices, fetchGetInvoice } = useUsers();
  const [loadingIds, setLoadingIds] = useState<string[]>([]);

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleDownload = async (invoiceId: string) => {
    try {
      if (!token) {
        throw new Error('Token not available');
      }

      setLoadingIds((prev) => [...prev, invoiceId]);

      await delay(100);

      const url = `${getBaseUrl()}${SERVICE_USERS_POST_SAVE_INVOICES}/${invoiceId}`;
      const response = await axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const contentDisposition = response.headers['content-disposition'];
      let filename = `${invoiceId}.pdf`;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1].replace(/['"]/g, '');
        }
      }

      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar el archivo', error);
    } finally {
      setLoadingIds((prev) => prev.filter((id) => id !== invoiceId));
    }
  };

  const COLUMNS_CONNECTIONS: GridColDef[] = useMemo(
    () => [
      ...COLUMNS_ACCOUNT_BILLINGS(t),
      {
        field: 'action',
        headerName: t('action'),
        align: 'center',
        pinnable: false,
        headerAlign: 'center',
        ...DISABLED_FILTERABLE,
        renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
          <Button
            color="primary"
            onClick={() => handleDownload(params.row.id)}
            disabled={loadingIds.includes(params.row.id)}
          >
            {loadingIds.includes(params.row.id) ? (
              <CircularProgress size={24} />
            ) : (
              generateIcon('Download')
            )}
          </Button>
        )
      }
    ],
    [t, token, loadingIds]
  );

  useEffect(() => {
    if (token) {
      fetchGetInvoice();
    }
  }, [token, fetchGetInvoice]);

  return (
    <Stack>
      <h1>{t('routes.billings')}</h1>
      <TableComponent
        hideFooter
        rows={invoices}
        loading={loadingInvoices}
        checkboxSelection
        disableRowSelectionOnClick
        checkboxSelectionVisibleOnly
        columns={COLUMNS_CONNECTIONS}
        className={classNames('table-connections', mode)}
        slots={{
          noResultsOverlay: () => (
            <Stack className="connections-info">
              <Typography>{error?.message}</Typography>
            </Stack>
          ),
          noRowsOverlay: () => (
            <Stack className="connections-info">
              {error ? (
                <Typography>{error?.message}</Typography>
              ) : (
                <Typography>{t('no_billing_connected')}</Typography>
              )}
            </Stack>
          )
        }}
      />
    </Stack>
  );
};