import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const TrendsChart = () => {
  const [chartData, setChartData] = React.useState<any[]>([]);

  React.useEffect(() => {
    // Datos de prueba para la palabra "Argentina" desde 2000 a 2023
    const testData = Array.from({ length: 20 }, (_, i) => {
      const year = 2004 + i;
      const interest = Math.floor(Math.random() * 100);
      return {
        formattedTime: `${year}`,
        value: interest
      };
    });

    setChartData(testData);
  }, []);

  const handleDownload = () => {
    console.log('Descargando gráfica...');
  };

  const handleShare = () => {
    console.log('Compartiendo gráfica...');
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Grid item>
          <Typography variant="h6">Interés a lo largo del tiempo</Typography>
        </Grid>
        <Grid item>
          <Button startIcon={<CloudDownloadIcon />} variant="outlined" onClick={handleDownload}>
            Descargar
          </Button>
          <Button startIcon={<ShareIcon />} variant="outlined" onClick={handleShare}>
            Compartir
          </Button>
        </Grid>
      </Grid>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="formattedTime" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
