import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import './404.scss';
import { PageContainer } from '../components/Essential/PageContainer';

export const Error404: React.FC = () => {
  return (
    <PageContainer>
      <div className="error404-container">
        <h1>404 Error Page</h1>
        <Typography className="zoom-area">
          <b>Oh no!</b> Parece que esta página no existe o se ha eliminado
        </Typography>
        <section className="error-container">
          <span className="error-span">
            <span>4</span>
          </span>
          <span>0</span>
          <span className="error-span error-span2">
            <span>4</span>
          </span>
        </section>
        <Link to="/">
          <Button variant="contained" color="primary">
            Go Home
          </Button>
        </Link>
      </div>
    </PageContainer>
  );
};
