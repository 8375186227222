import './styles.css';
import React from 'react';

interface Props {
  style?: any;
  values: number;
  suffix?: string;
  htmlClass: string;
  progress?: number;
  reduction?: number;
  className?: string;
  background?: string;
  strokeWidth?: number;
  ballStrokeWidth?: number;
  transitionDuration?: number;
  transitionTimingFunction?: string;
  gradient?: {
    stop: number;
    color: string;
  }[];
}

function _Progress({
  style,
  className,
  htmlClass,
  values = 0,
  suffix = '%',
  progress = 100,
  reduction = 0.5,
  strokeWidth = 20,
  ballStrokeWidth = 16,
  transitionDuration = 0.5,
  background = '#dde2e9',
  transitionTimingFunction = 'ease',
  gradient = [
    { stop: 0.0, color: '#620096' },
    { stop: 0.7, color: '#d88fff' },
    { stop: 1, color: '#f4dfff' }
  ]
}: Readonly<Props>) {
  progress = Math.round(progress * 100) / 100;
  const width = 200;
  const center = width / 2;
  const height = width || center + center * Math.cos(reduction * Math.PI);
  const unique = Math.random().toString();
  const rotate = 90 + 180 * reduction;
  const r = center - strokeWidth / 2 - ballStrokeWidth / 2;
  const circumference = Math.PI * r * 2;
  const offset = (circumference * (100 - progress * (1 - reduction))) / 100;

  return (
    <div data-testid="percentage" className={`${className} progress`.trim()} style={style}>
      <svg viewBox={`0 0 ${width} ${height / 1.7}`} className="svg">
        <defs>
          <linearGradient id={'gradient' + unique} x1="0%" y1="0%" x2="0%" y2="100%">
            {gradient.map(({ stop, color }) => (
              <stop key={stop} offset={stop * 100 + (suffix || '')} stopColor={color} />
            ))}
          </linearGradient>
        </defs>
        <text
          x={center}
          y={center}
          className={htmlClass}
          data-testid={htmlClass}
          textAnchor="middle"
          fontSize={30}
          fontWeight={800}>
          {values}%
        </text>
        <circle
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference * reduction}
          fill="none"
          stroke={background}
          strokeLinecap="round"></circle>
        <circle
          style={{
            transition: `stroke-dashoffset ${transitionDuration}s ${transitionTimingFunction}`
          }}
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference}`}
          strokeDashoffset={offset}
          fill="none"
          stroke={`url(#gradient${unique})`}
          strokeLinecap="round"></circle>
      </svg>
    </div>
  );
}

const DifficultyProgress = React.memo(_Progress);
DifficultyProgress.displayName = 'DifficultyProgress';

export default DifficultyProgress;
