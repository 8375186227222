import { useState, useCallback } from 'react';

export const useSelectedPlan = () => {
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [selectedBilling, setSelectedBilling] = useState<string>('monthly');

    return {
        selectedPlan,
        selectedBilling,
        setSelectedPlan,
        setSelectedBilling
    };
}