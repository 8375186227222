import React, { useEffect, useState } from 'react';
import { Tooltip, Popover, Card, CardContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import GoogleIcon from '../../../../assets/img/Google.webp';
import { CustomDotProps } from '../../types';
import { formatGoogleUpdateDate } from '../../utils';
import GoogleStatus from '../GoogleStatus/GoogleStatus';

const CustomDot: React.FC<CustomDotProps> = ({ cx, cy, update, activeDotId, setActiveDotId }) => {
  const { t } = useTranslation('googleUpdates');

  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    if (activeDotId !== update.id) {
      handleClose();
    }
  }, [activeDotId]);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
    if (setActiveDotId) {
      setActiveDotId(update.id);
    } else {
      console.error("setActiveDotId is not provided");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (setActiveDotId) {
      setActiveDotId(null);
    } else {
      console.error("setActiveDotId is not provided");
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'update-popover' : undefined;
  const htmlKey = update.htmlKey;
  const updateTitle = update.title ?? 'Google Update';

  if (!cx || !cy || !update || !htmlKey) {
    return null;
  }

  return (
    <>
      <foreignObject x={cx - 7} y={cy - 10} width={20} height={20}>
        <Tooltip title={`${t(update.text)}: ${t(update.description)}`} placement="bottom">
          <img
            src={GoogleIcon}
            width={activeDotId === update.id ? 20 : 16}
            height={activeDotId === update.id ? 20 : 16}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
            alt="Google Update Icon"
            id={id}
          />
        </Tooltip>
      </foreignObject>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {
              width: '480px',
              maxHeight: '250px',
              overflow: 'hidden',
              borderRadius: '8px',
              backgroundColor: '#ffffff',
            },
          },
        }}
      >
        <Card style={{ backgroundColor: '#ffffff' }}>
          <CardContent style={{ padding: '16px', overflow: 'auto', maxHeight: '300px', backgroundColor: '#ffffff', color: '#000000' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#000000' }}>
              <Typography variant="h6" noWrap style={{ color: '#000000' }}>
                {t(updateTitle)}
              </Typography>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon style={{ color: '#000000' }} />
              </IconButton>
            </div>
            <Typography variant="body2" style={{ color: '#000000' }}>
              {formatGoogleUpdateDate(update.created)}
            </Typography>
            <GoogleStatus dataKey={htmlKey} />
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default CustomDot;