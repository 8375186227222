import React, { useState, useMemo, useCallback, FC, useEffect } from 'react';
import { Grid, useMediaQuery, Skeleton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChartHeader from './ChartHeader/ChartHeader';
import Chart from './Chart/Chart';
import PaperItem from '../../Essential/PaperItem';
import { googleUpdatesJsonData } from './GoogleUpdatesHistory';
import { SearchConsoleChartProps, GoogleUpdate } from '../types';
import { formatNumber } from '../utils';
import SummaryCards from './SummaryCards/SummaryCards';

type SearchConsoleChartWithOptionalSetMetricsProps = Omit<SearchConsoleChartProps, 'setMetrics'> & {
  setMetrics?: (metrics: any) => void;
  customIndex?: number;
  previousData?: any;
};

const SearchConsoleChart: FC<SearchConsoleChartWithOptionalSetMetricsProps> = ({
                                                                                 project,
                                                                                 searchData = [],
                                                                                 previousData = [],
                                                                                 googleUpdates = googleUpdatesJsonData,
                                                                                 isGeneralView = false,
                                                                                 showDots = true,
                                                                                 showLegend = true,
                                                                                 showCustomDots = false,
                                                                                 isCustomDomain = false,
                                                                                 blurTitle = false,
                                                                                 showChartHeader = true,
                                                                                 metrics,
                                                                                 setMetrics,
                                                                                 numberOfColumns,
                                                                                 isFavorite,
                                                                                 onFavoriteToggle,
                                                                                 loading,
                                                                                 customIndex
                                                                               }) => {

  const hasData = searchData.length > 0;

  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const location = useLocation();
  const isProjectInfoPage = location.pathname.startsWith('/projects/performance/detail/');

  const totalImpressions = useMemo(() => searchData.reduce((acc, item) => acc + (item?.impressions || 0), 0), [searchData]);
  const totalClicks = useMemo(() => searchData.reduce((acc, item) => acc + (item?.clicks || 0), 0), [searchData]);
  const avgCTR = useMemo(() => (totalClicks && totalImpressions ? ((totalClicks / totalImpressions) * 100) : 0), [totalClicks, totalImpressions]);
  const avgPosition = useMemo(() => (
    totalImpressions ? searchData.reduce((acc, item) => acc + ((item?.position || 0) * (item?.impressions || 0)), 0) / totalImpressions : 0
  ), [searchData, totalImpressions]);

  const prevTotalImpressions = useMemo(() => previousData.reduce((acc, item) => acc + (item?.impressions || 0), 0), [previousData]);
  const prevTotalClicks = useMemo(() => previousData.reduce((acc, item) => acc + (item?.clicks || 0), 0), [previousData]);
  const prevAvgCTR = useMemo(() => (prevTotalClicks && prevTotalImpressions ? ((prevTotalClicks / prevTotalImpressions) * 100) : 0), [prevTotalClicks, prevTotalImpressions]);
  const prevAvgPosition = useMemo(() => (
    prevTotalImpressions ? previousData.reduce((acc, item) => acc + ((item?.position || 0) * (item?.impressions || 0)), 0) / prevTotalImpressions : 0
  ), [previousData, prevTotalImpressions]);

  const [localMetrics, setLocalMetrics] = useState(metrics);

  useEffect(() => {
    setLocalMetrics(metrics);
  }, [metrics]);

  const handleMetricToggle = useCallback((metricName) => {
    if (!hasData) return;
    setLocalMetrics(prev => {
      const activeMetricsCount = Object.values(prev).filter(Boolean).length;
      const shouldToggle = activeMetricsCount > 1 || !prev[metricName];
      const newMetrics = shouldToggle ? { ...prev, [metricName]: !prev[metricName] } : prev;
      if (setMetrics) {
        setMetrics(newMetrics);
      }
      return newMetrics;
    });
  }, [hasData, setMetrics]);

  const hiddenLines = useMemo(() => {
    const hidden = {};
    Object.keys(localMetrics).forEach(key => {
      if (!localMetrics[key]) hidden[key] = true;
    });
    return hidden;
  }, [localMetrics]);

  const handleLegendClick = useCallback((payload) => handleMetricToggle(payload.dataKey), [handleMetricToggle]);

  const handleHeaderClick = useCallback(() => {
    if (!isCustomDomain && !isProjectInfoPage) {
      navigate(`/projects/performance/detail/${encodeURIComponent(project.originalUrl)}`, {
        state: { project: project.originalUrl, startDate: searchData[0]?.date || '', endDate: searchData[searchData.length - 1]?.date || '' }
      });
    }
  }, [project.originalUrl, searchData, isCustomDomain, navigate, isProjectInfoPage]);

  if (loading) {
    return (
      <Grid item xs={12} md={12} style={{ position: 'relative' }}>
        <PaperItem>
          {showChartHeader && (
            <div style={{ marginBottom: isMobile ? '28px' : '0' }}>
              <ChartHeader project={project} blurTitle={blurTitle} clickable={!isProjectInfoPage} loading={true} />
            </div>
          )}
          <Skeleton variant="rectangular" height={200} sx={{ mt: 2 }} />
        </PaperItem>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={12} style={{ position: 'relative' }}>
      <PaperItem>
        {showChartHeader && (
          <div style={{ marginBottom: isMobile ? '28px' : '0' }} onClick={handleHeaderClick}>
            <ChartHeader project={project} blurTitle={blurTitle} clickable={!isProjectInfoPage} />
          </div>
        )}
        <Chart
          searchData={searchData}
          metrics={localMetrics}
          googleUpdates={googleUpdates as GoogleUpdate[]}
          isGeneralView={isGeneralView}
          showDots={showDots}
          showLegend={showLegend}
          showCustomDots={showCustomDots}
          isCustomDomain={isCustomDomain}
          numberOfColumns={numberOfColumns}
          handleLegendClick={handleLegendClick}
          hiddenLines={hiddenLines}
          blurTitle={blurTitle}
        />
        {!isCustomDomain && (
          <SummaryCards
            formatNumber={formatNumber}
            totalClicks={totalClicks}
            totalImpressions={totalImpressions}
            avgCTR={avgCTR}
            avgPosition={avgPosition}
            prevTotalClicks={prevTotalClicks}
            prevTotalImpressions={prevTotalImpressions}
            prevAvgCTR={prevAvgCTR}
            prevAvgPosition={prevAvgPosition}
            metrics={localMetrics}
            alwaysShow={true}
            handleMetricToggle={handleMetricToggle}
            hasData={hasData}
            isFavorite={isFavorite}
            onFavoriteToggle={onFavoriteToggle}
            customIndex={customIndex}
          />
        )}
      </PaperItem>
    </Grid>
  );
};
export default SearchConsoleChart;