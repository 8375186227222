import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDomainsData } from '../../hooks/useDomainsData';
import { LOCATION_CODE_ES } from '../../components/Utils/Constants';
import { COLUMNS_DOMAIN_BLACKLINKS } from '../../components/Utils/TableConstants';

import { Grid, Typography } from '@mui/material';
import Search from '../../components/Search';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import { useSearchCache } from '../../context/SearchCacheContext';

const BACKLINKS_CACHE_KEY = 'backlinks_search';

export const BacklinksPage = () => {
  const { t } = useTranslation();
  const { domainsBacklinks, loadingBacklinks, fetchDomainBacklinks } = useDomainsData('backlinks_cache_key');
  const { getPagination, updatePagination } = useSearchCache();

  const context = 'backlinks';
  const [pagination, setPagination] = useState(() => getPagination(context));
  
  const handleSearchBtn = async (search: string) => {
    if (search.length === 0) return;
    const data = { location: LOCATION_CODE_ES, domain: search };
    await fetchDomainBacklinks(data);
  };

  const handlePaginationChange = (newPagination) => {
    setPagination(newPagination);
    updatePagination(context, newPagination);
  };

  useEffect(() => {
    const savedPagination = getPagination(context);
    setPagination(savedPagination);
  }, []);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Search
            loading={loadingBacklinks}
            onSearch={handleSearchBtn}
            cacheKey={BACKLINKS_CACHE_KEY}
          />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Typography className="mb-5">{t('routes.backlinks')}</Typography>
          <TableComponent
            getRowHeight={() => 'auto'}
            loading={loadingBacklinks}
            rows={domainsBacklinks.items}
            columns={COLUMNS_DOMAIN_BLACKLINKS(t)}
            paginationModel={pagination}
            onPaginationModelChange={handlePaginationChange}
            initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};