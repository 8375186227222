import React from 'react';
import Confetti from 'react-dom-confetti';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../styles/subscriptions.scss';

import { useUserContext } from '../context/UserContext';
import useRefreshToken from '../hooks/useRefreshToken';

import { Button, Container, Grid, Typography } from '@mui/material';
import { useUsers } from '../hooks/useUsers';

const config = {
  angle: 94,
  spread: 360,
  startVelocity: 40,
  elementCount: 90,
  dragFriction: 0.12,
  duration: 4000,
  stagger: 3,
  perspective: 454,
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

export default function SubscriptionThanksPage() {
  const [tokenRenewed, setTokenRenewed] = React.useState(false);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id') || '';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useUserContext();

  const { checkoutData, retrieveCheckout } = useUsers();

  const getNewToken = useRefreshToken();

  function dataLayerPush(data: any) {
    (window as any).dataLayer = (window as any).dataLayer || [];

    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: 'purchase',
      ecommerce: data
    });
  }

  React.useEffect(() => {
    const refreshToken = async () => {
      if (!tokenRenewed) {
        await getNewToken();
        setTokenRenewed(true);
      }
    };
    refreshToken();
  }, [tokenRenewed]);

  React.useEffect(() => {
    if (tokenRenewed) {
      const getCheckoutData = async () => {
        await retrieveCheckout(sessionId);
      };

      getCheckoutData();
    }
  }, [tokenRenewed]);

  React.useEffect(() => {
    if (checkoutData) {
      dataLayerPush(checkoutData);
    }
  }, [checkoutData]);

  return (
    <Container className="container-card" maxWidth="md" component="main">
      <Typography variant="h2" component="h1" color="primary" className="card-title" align="center">
        {t('payment_received')}
      </Typography>
      <Typography variant="h5" component="h1" className="card-title" align="center">
        {t('click_continue_to_start_using_rankerfy')}
      </Typography>
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Confetti active={isAuthenticated} config={config} />
      </Grid>
      <Button
        variant="contained"
        disabled={!tokenRenewed}
        sx={{ mt: 4, display: 'block', mx: 'auto' }}
        onClick={() => navigate('/', { replace: true })}>
        {t('continue')}
      </Button>
    </Container>
  );
}
