import React, { useState, useEffect } from 'react';
import { Grid, Divider, Paper, useMediaQuery } from '@mui/material';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useProjectsData } from '../../hooks/useProjectsData';
import SearchConsoleChart from '../../components/Charts/SearchConsoleChart';
import TableComponent from '../../components/TableComponent';
import SitemapsComponent from '../../components/SitemapsComponent/SitemapsComponent';
import ProjectSelection from '../../components/ProjectSelection/ProjectSelection';
import useProjectInfo from '../../hooks/useProjectInfo';
import { getColumnDefinitions } from '../../components/ColumnDefinitions/ColumnDefinitions';
import { formatRows, cleanUrl } from '../../utils/format';
import { calculateDisplayedPercentages } from '../../utils/calculations';
import { useTranslation } from 'react-i18next';
import PageSpeedInsights from '../../components/PageSpeedInsights/PageSpeedInsights';
import { useUserContext } from '../../context/UserContext';

export const ProjectDetail: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [projectUrl, setProjectUrl] = useState<string>('');
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<{ startDate: string, endDate: string }>({ startDate: '', endDate: '' });
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [showCustomDots, setShowCustomDots] = useState<boolean>(false);
  
  const initialMetrics = { impressions: true, clicks: true, ctr: false, position: false };
  const [metrics, setMetrics] = useState(initialMetrics);

  const { blur, toggleBlur } = useUserContext();
  const { dataProject, fetchProjectInfo: fetchProjectInfoCallback } = useProjectsData();
  const { projectInfo, loading: projectLoading } = useProjectInfo({
    projectUrl,
    dateRange,
    fetchProjectInfoCallback,
    isNavigating,
  });

  const { COLUMNS_QUERIES, COLUMNS_PAGES, COLUMNS_COUNTRIES, COLUMNS_DEVICES, COLUMNS_SEARCH_APPEARANCES } = getColumnDefinitions();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNarrowScreen = useMediaQuery('(max-width:1580px)');

  useEffect(() => {
    const blurParam = searchParams.get('blur');
    if (blurParam !== null) {
      if (blurParam === 'true' && !blur) {
        toggleBlur();
      } else if (blurParam === 'false' && blur) {
        toggleBlur();
      }
    }
  }, [searchParams, blur, toggleBlur]);

  useEffect(() => {
    let decodedProjectUrl = '';

    if (params.projectUrl) {
      decodedProjectUrl = decodeURIComponent(params.projectUrl as string);
    } else if (location.state?.project) {
      decodedProjectUrl = location.state.project;
    }

    setProjectUrl(decodedProjectUrl);

    if (location.state?.startDate && location.state?.endDate) {
      setDateRange({
        startDate: location.state.startDate,
        endDate: location.state.endDate,
      });
    }
  }, [params, location]);

  const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const project = JSON.parse(event.target.value);
    setIsNavigating(true);
    setSelectedProject(project);
    setProjectUrl(project.property);

    navigate(`/projects/performance/detail/${encodeURIComponent(project.property)}`, {
      state: {
        project: project.property,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    });
    setTimeout(() => setIsNavigating(false), 100);
  };

  const handleToggleCustomDots = () => setShowCustomDots((prev) => !prev);

  const handleGlobalMetricToggle = (metric: string) => {
    setMetrics((prev) => ({ ...prev, [metric]: !prev[metric] }));
  };

  const chartData =
    projectInfo?.currentData?.dates?.map((dateRecord) => ({
      date: dateRecord.keys[0],
      clicks: dateRecord.clicks,
      impressions: dateRecord.impressions,
      ctr: dateRecord.ctr,
      position: dateRecord.position,
    })) || [];

  const filteredColumns = (columns: any[]) => columns.filter((column) => column.field === 'keys' || metrics[column.field]);
  const formattedRows = (rows: any[], key: string) => formatRows(rows, projectInfo?.currentData?.maxMinClicks?.[key] || {});

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProjectSelection
            dataProject={dataProject}
            selectedProject={selectedProject}
            handleProjectChange={handleProjectChange}
            dateRange={dateRange}
            setDateRange={setDateRange}
            blurTitle={blur}
            toggleBlur={toggleBlur}
            showCustomDots={showCustomDots}
            handleToggleCustomDots={handleToggleCustomDots}
            t={t}
            projectUrl={projectUrl}
            metrics={metrics}
            handleGlobalMetricToggle={handleGlobalMetricToggle}
          />
        </Grid>
        {isNarrowScreen && <Grid item xs={12} sx={{ height: '50px' }} />}
      </Grid>
      <Divider sx={{ my: 3 }} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchConsoleChart
            project={{ id: projectUrl, url: cleanUrl(projectUrl), property: projectUrl }}
            isGeneralView={false}
            showChartHeader={false}
            searchData={chartData}
            previousData={projectInfo?.previousData?.dates || []}
            showDots={false}
            showLegend={true}
            blurTitle={blur}
            showSummaryCards={true}
            showCustomDots={showCustomDots}
            metrics={metrics}
            setMetrics={setMetrics}
            numberOfColumns={1}
            loading={loading || projectLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <PageSpeedInsights url={projectUrl} />
        </Grid>
        <Grid item xs={12}>
          {projectInfo?.currentData?.sitemaps && <SitemapsComponent sitemaps={projectInfo.currentData.sitemaps} />}
        </Grid>
        {projectInfo?.currentData?.queryList?.length > 0 && (
          <Grid item xs={12}>
            <TableComponent
              rows={formattedRows(projectInfo.currentData.queryList, 'queryList')}
              columns={filteredColumns(COLUMNS_QUERIES)}
              loading={loading || projectLoading}
              blurTitle={blur}
              overlayPercentages={calculateDisplayedPercentages(
                projectInfo.currentData.queryList,
                projectInfo.currentData.maxMinClicks?.queryList || {},
                metrics
              )}
            />
          </Grid>
        )}
        {projectInfo?.currentData?.pagesList?.length > 0 && (
          <Grid item xs={12}>
            <TableComponent
              rows={formattedRows(projectInfo.currentData.pagesList, 'pagesList')}
              columns={filteredColumns(COLUMNS_PAGES)}
              loading={loading || projectLoading}
              blurTitle={blur}
              overlayPercentages={calculateDisplayedPercentages(
                projectInfo.currentData.pagesList,
                projectInfo.currentData.maxMinClicks?.pagesList || {},
                metrics
              )}
            />
          </Grid>
        )}
        {projectInfo?.currentData?.countries?.length > 0 && (
          <Grid item xs={12}>
            <TableComponent
              rows={formattedRows(projectInfo.currentData.countries, 'countries')}
              columns={filteredColumns(COLUMNS_COUNTRIES)}
              loading={loading || projectLoading}
              overlayPercentages={calculateDisplayedPercentages(
                projectInfo.currentData.countries,
                projectInfo.currentData.maxMinClicks?.countries || {},
                metrics
              )}
            />
          </Grid>
        )}
        {projectInfo?.currentData?.devices?.length > 0 && (
          <Grid item xs={12}>
            <TableComponent
              rows={formattedRows(projectInfo.currentData.devices, 'devices')}
              columns={filteredColumns(COLUMNS_DEVICES)}
              loading={loading || projectLoading}
              overlayPercentages={calculateDisplayedPercentages(
                projectInfo.currentData.devices,
                projectInfo.currentData.maxMinClicks?.devices || {},
                metrics
              )}
            />
          </Grid>
        )}
        {projectInfo?.currentData?.searchAppearanceList?.length > 0 && (
          <Grid item xs={12}>
            <TableComponent
              rows={formattedRows(projectInfo.currentData.searchAppearanceList, 'searchAppearanceList')}
              columns={filteredColumns(COLUMNS_SEARCH_APPEARANCES)}
              loading={loading || projectLoading}
              overlayPercentages={calculateDisplayedPercentages(
                projectInfo.currentData.searchAppearanceList,
                projectInfo.currentData.maxMinClicks?.searchAppearanceList || {},
                metrics
              )}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};