import './PageContainer.scss';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from './Header';
import { SidebarAccount } from './SidebarAccount';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

export const AccountContainer: React.FC<React.PropsWithChildren> = ({ children }) => (
  <React.Fragment>
    <Header />
    <div className="page-container">
      <SidebarAccount />
      <div className="page-content">
        <Breadcrumb />
        {children ?? <Outlet />}
      </div>
    </div>
  </React.Fragment>
);
