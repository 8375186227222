import React from 'react';

import { format } from 'date-fns';
import * as CONSTANT from './../Utils/Constants';
import IsLowThinContent from '../IsLowThinContent';
import { BillingsResponse } from '../../types/common.type';
import {
  RenderCellTable,
  ColumnsReturnType,
  DomainBulkResponse,
  DomainIdeasResponse
} from '../../types';
import { classNames, formatAmount, formatDateNow, formatNumber, truncate } from './Utils';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Button, Chip, Stack, Typography } from '@mui/material';

// COMPONENTS
import SerpUrl from '../SerpUrl/SerpUrl';
import LinkRedirect from '../Links/Redirect';
import KeywordLinkBlank from '../KeywordLinkBlank';
import KeywordLink from '../../components/KeywordLink';
import ResultRankAbsolute from '../ResultRankAbsolute';
import KeywordRedirectLink from '../KeywordRedirectLink';
import ContainerIntents from '../KeywordIntent/Container';
import BacklinkPageFromTitle from '../Backlinks/PageFromTitle';
import ContainsKeyword from '../ContainsKeyword/ContainsKeyword';
import KeywordFeatures from '../KeywordFeatures/KeywordFeatures';
import KeywordDifficulty from '../../components/KeywordDifficulty';
import HistoryTrendChart from '../../components/HistoryTrendChart';
import CannibalizeResults from '../CannibalizeResults/CannibalizeResults';
import KeywordPosition from '../../components/KeywordPosition/KeywordPosition';
import OverviewRedirectLink from '../OverviewRedirectLink/KeywordRedirectLink';
import RankRangesWrapper from '../Recharts/RankRangesWrapper/RankRangesWrapper';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import '../../pages/domain/Comparator.scss';

const DiffIndicator = ({ value, isPercentage }) => {
  if (value === null || value === undefined) {
    return null;
  }

  const formatValue = isPercentage ? `${Math.abs(value).toFixed(2)}%` : `${Math.abs(value).toFixed(2)} pts`;
  const color = value > 0 ? 'hsl(142, 72%, 29%)' : 'hsl(0, 84.2%, 60.2%)';
  const Icon = value > 0 ? ArrowDropUpIcon : ArrowDropDownIcon;

  return (
    <sup style={{ color, marginLeft: '4px', display: 'inline-flex', alignItems: 'center', fontSize: '1rem' }}>
      <Icon sx={{ fontSize: '0.7rem', marginRight: '2px', color }} />
      <Typography variant="caption" sx={{ color, fontSize: '0.7rem' }}>
        {formatValue}
      </Typography>
    </sup>
  );
};

const formatPosition = (position) => {
  if (position % 1 === 0) {
    return position.toFixed(0);
  } else if ((position * 100) % 1 === 0) {
    return position.toFixed(1);
  }
  return position.toFixed(2);
};

const transformtValueIntent = (value) => {
  const mainIntent = value.row.search_intent_info?.main_intent
    ? [value.row.search_intent_info.main_intent]
    : [];
  const foreignIntent = value.row.search_intent_info?.foreign_intent || [];
  return [...mainIntent, ...foreignIntent].join(',');
};

export const DISABLED_FILTERABLE: Partial<GridColDef> = {
  filterable: false,
  disableColumnMenu: true
};

export const COLUMNS_WITH_GETTER: Partial<GridColDef> = {
  valueGetter: (params) => {
    if (params.value !== undefined) return params.value;
    return '—';
  }
};

export const COLUMNS_KEYWORD_ANALYSIS: ColumnsReturnType = (t, op) => [
  {
    field: 'keyword.keyword',
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    flex: 1,
    width: 350,
    minWidth: 200,
    type: 'string',
    align: 'left',
    headerAlign: 'left',
    valueGetter: (value) => value.row.keyword ?? '—',
    renderCell: (params) =>
      op?.isKeyword ? (
        <KeywordLink keyword={params.row.keyword} />
      ) : (
        <KeywordRedirectLink db={op?.code} keyword={params.row.keyword} />
      ),
    ...op
  },
  {
    field: 'keyword_info.search_volume',
    headerName: t('volume'),
    width: 100,
    sortable: true,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    description: t('tooltips.tables.volumen'),
    valueGetter: (value) => value.row.keyword_info?.search_volume ?? '—',
    ...op
  },
  {
    field: 'keyword_info.cpc',
    headerName: t('cpc'),
    description: t('tooltips.tables.cpc'),
    sortable: true,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => params.row.keyword_info?.cpc ?? '—',
    renderCell: (params) =>
      params.row.keyword_info?.cpc ? `$${params.row.keyword_info?.cpc.toFixed(2)}` : '—',
    ...op
  },
  {
    field: 'keyword_info.competition',
    headerName: t('competition'),
    description: t('tooltips.tables.competition'),
    sortable: true,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (value) => value.row.keyword_info?.competition ?? '—',
    ...op
  },
  {
    field: 'extra.keyword_difficulty',
    headerName: t('difficulty'),
    description: t('tooltips.tables.difficult'),
    sortable: true,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: (value) => value.row.extra?.keyword_difficulty,
    ...op
  },
  {
    ...DISABLED_FILTERABLE,
    sortable: false,
    field: 'search_intent_info.intent',
    headerName: t('intent.title'),
    description: t('tooltips.tables.intent'),
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: transformtValueIntent,
    ...op
  },
  {
    ...DISABLED_FILTERABLE,
    sortable: false,
    field: 'serp_info.item_types',
    headerName: t('features_SERP'),
    description: t('tooltips.tables.serps'),
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: (value) => value.row.serp_info?.item_types ?? null,
    ...op
  },
  {
    ...DISABLED_FILTERABLE,
    sortable: false,
    field: 'keyword_info.history',
    headerName: t('history'),
    description: t('tooltips.tables.history'),
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: (value) =>
      value.row.keyword_info?.history.map((element) => element.volume).join(',') || ''
  }
];

export const COLUMNS_DOMAIN_BULK_ANALYSIS: ColumnsReturnType<DomainBulkResponse['items'][0]> = (
  t,
  op
) => [
  {
    field: 'main_domain',
    headerName: t('domain'),
    description: t('tooltips.tables.keyword'),
    flex: 1,
    width: 100,
    minWidth: 50,
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (value) => value.row.main_domain ?? '—',
    renderCell: (params) => (
      <LinkRedirect location={op?.code} url={params.row.main_domain} />
    )
  },
  {
    field: 'total_traffic',
    headerName: t('traffic'),
    type: 'number',
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    sortable: true,
    description: t('tooltips.tables.volumen'),
    valueGetter: (params) =>
      params.row.total_traffic ? params.row.total_traffic.toFixed(2) : '—',
    renderCell: (params) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {params.value}
      </div>
    )
  },
  {
    field: 'total_traffic_cost',
    headerName: t('traffic_cost'),
    type: 'number',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    sortable: true,
    description: t('tooltips.tables.volumen'),
    valueGetter: (params) =>
      params.row.total_traffic_cost
        ? `$${params.row.total_traffic_cost.toFixed(2)}`
        : '—',
    renderCell: (params) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {params.value}
      </div>
    )
  },
  {
    field: 'keywords_count',
    headerName: t('keywords_count'),
    description: t('tooltips.tables.keyword'),
    width: 200,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    sortable: true,
    valueGetter: (value) => value.row.keywords_count ?? '—',
    renderCell: (params) => (
      <LinkRedirect
        location={op?.code}
        path="/domain/keywords/"
        url={params.row.main_domain}
        value={params.row.keywords_count}
      />
    )
  },
  {
    field: 'unique_urls_count',
    headerName: t('unique_urls_count'),
    description: t('tooltips.tables.keyword'),
    width: 170,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    sortable: true,
    valueGetter: (value) => value.row.unique_urls_count ?? '—',
    renderCell: (params) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {params.value}
      </div>
    )
  },
  {
    field: 'search_intent',
    headerName: t('Intenciones'),
    description: t('tooltips.tables.search_intent'),
    width: 170,
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    sortable: true,
    valueGetter: (value) => {
      const intent = value.row.search_intent;
      return intent ? Object.entries(intent).map(([key, val]) => `${key}: ${val}`).join(', ') : '—';
    }
  },
  {
    field: 'rank_ranges',
    headerName: t('Rangos'),
    description: t('tooltips.tables.rank_ranges'),
    width: 350,
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <div style={{ position: 'relative', left: '-5px', top: '10px' }}>
        <RankRangesWrapper data={params.row.rank_ranges} loading={false} height={100} />
      </div>
    )
  }
];

export const COLUMNS_DOMAIN_IDEAS_OVERVIEW: ColumnsReturnType<DomainIdeasResponse['items'][0]> = (
  t,
  op
) => [
  {
    field: 'domain',
    headerName: t('domain'),
    description: t('tooltips.tables.keyword'),
    flex: 1,
    width: 350,
    minWidth: 200,
    type: 'string',
    valueGetter: (value) => value.row.domain ?? '—',
    renderCell: (params) => <LinkRedirect location={op?.code} url={params.row.domain} />
  },
  {
    field: 'traffic',
    headerName: t('traffic'),
    type: 'number',
    minWidth: 50,
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    ...DISABLED_FILTERABLE,
    sortable: true,
    description: t('tooltips.tables.volumen'),
    valueGetter: (params) => (params.row.traffic ? params.row.traffic.toFixed(2) : '—')
  },
  {
    field: 'trafficCost',
    headerName: t('traffic_cost'),
    type: 'number',
    width: 130,
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    ...DISABLED_FILTERABLE,
    sortable: true,
    description: t('tooltips.tables.volumen'),
    valueGetter: (params) =>
      params.row.trafficCost ? `$${params.row.trafficCost.toFixed(2)}` : '—'
  },
  {
    field: 'totalKeywords',
    headerName: t('keywords_count'),
    description: t('tooltips.tables.keyword'),
    width: 180,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    ...DISABLED_FILTERABLE,
    sortable: true,
    valueGetter: (value) => value.row.totalKeywords ?? '—',
    renderCell: (params) => (
      <LinkRedirect
        location={op?.code}
        path="/domain/keywords/"
        url={params.row.domain}
        value={params.row.totalKeywords}
      />
    )
  },
  {
    field: 'urls',
    headerName: t('unique_urls_count'),
    description: t('tooltips.tables.keyword'),
    width: 150,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    ...DISABLED_FILTERABLE,
    sortable: true,
    valueGetter: (value) => value.row.urls ?? '—'
  }
];

export const COLUMNS_DOMAIN_KEYWORDS: ColumnsReturnType = (t, options) => [
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    field: 'keyword',
    sortable: false,
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    minWidth: 150,
    align: 'left',
    headerAlign: 'center',
    renderCell: (params) =>
      options?.isKeyword ? (
        <KeywordLink keyword={params.row.keyword} />
      ) : (
        <KeywordRedirectLink db={options?.code} keyword={params.row.keyword} />
      )
  },
  {
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    field: 'search_intent_info.intent',
    sortable: false,
    headerName: t('intent.title'),
    description: t('tooltips.tables.intent'),
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: transformtValueIntent,
    ...options
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    field: 'serp_info.serp.position',
    sortable: false,
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params)
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    field: 'traffic',
    sortable: false,
    headerName: t('traffic'),
    description: t('tooltips.tables.price_traffic'),
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) =>
      !isNaN(Number(params.row.serp_info.serp[0].etv))
        ? Number(params.row.serp_info.serp[0].etv).toFixed(2)
        : '—'
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'keyword_info.search_volume',
    headerName: t('volume'),
    description: t('tooltips.tables.volumen'),
    align: 'center',
    headerAlign: 'center',
    valueGetter: (value) => value.row.keyword_info?.search_volume ?? '—'
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'extra.keyword_difficulty',
    headerName: t('difficulty'),
    description: t('tooltips.tables.difficult'),
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params)
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'keyword_info.cpc',
    headerName: t('cpc'),
    description: t('tooltips.tables.cpc'),
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) =>
      params.row.keyword_info?.cpc ? `$${params.row.keyword_info?.cpc.toFixed(2)}` : '—'
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    field: 'serp_info.serp.url',
    sortable: false,
    headerName: t('url'),
    description: t('tooltips.tables.url'),
    flex: 1,
    minWidth: 350,
    align: 'left',
    headerAlign: 'center',
    renderCell: (params) => <KeywordLinkBlank href={params.row.serp_info.serp[0].url} />
  },
  {
    ...DISABLED_FILTERABLE,
    field: 'price',
    sortable: false,
    headerName: t('price'),
    description: t('tooltips.tables.price_traffic'),
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => {
      const traffic_cost = params.row.serp_info.serp[0].traffic_cost as number;
      if (traffic_cost !== undefined) return `$${traffic_cost.toFixed(2)}`;
      return '—';
    },
    ...options
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'keyword_info.competition',
    headerName: t('competition'),
    description: t('tooltips.tables.competition'),
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => params.row.keyword_info.competition ?? '—'
  }
];

export const COLUMNS_SERP_OVERVIEW: ColumnsReturnType = (t, op) => [
  {
    ...COLUMNS_WITH_GETTER,
    field: 'rank_absolute',
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    align: 'center',
    headerAlign: 'center',
    maxWidth: 80,
    valueGetter: (value) => value.row.rank_absolute,
    renderCell: (params) =>
      params.row.previous_rank_absolute > 0 ? (
        <KeywordPosition
          data={{
            position: params.row.rank_absolute,
            prevPos: params.row.previous_rank_absolute
          }}
        />
      ) : (
        params.row.rank_absolute
      )
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'url',
    headerName: t('url'),
    description: t('tooltips.tables.url'),
    flex: 1,
    minWidth: 350,
    renderCell: (params) => (
      <OverviewRedirectLink location={op?.code} domain={params.row.domain} url={params.row.url} />
    )
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'traffic_cost',
    width: 150,
    headerName: t('traffic_cost'),
    description: t('tooltips.tables.traffic_cost'),
    align: 'center',
    headerAlign: 'center',
    renderCell: (value) =>
      value.row?.traffic_cost ? `$${Number(value.row?.traffic_cost).toFixed(2)}` : '—',
    valueGetter: (value) => value.row?.traffic_cost
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'etv',
    width: 150,
    headerName: t('etv'),
    align: 'center',
    headerAlign: 'center',
    valueGetter: (value) => value.row?.etv
  }
];

export const COLUMNS_COMPETITORS_KEYWORDS: ColumnsReturnType = (t, options) => [
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'domain',
    headerName: t('domain'),
    description: t('tooltips.domains.competitor_domain'),
    flex: 1,
    renderCell: (params) => (
      <a href={`https://${params.row.domain}`} target="_blank">
        {params.row.domain}
      </a>
    )
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'common_keywords',
    headerName: t('common_keywords'),
    description: t('tooltips.domains.competitor_common_keyword'),
    align: 'center',
    headerAlign: 'center',
    minWidth: 200,
    renderCell: (params) => formatAmount(params.row[params.field])
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'traffic',
    headerName: t('traffic'),
    description: t('tooltips.domains.competitor_traffic'),
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => `${formatAmount(params.row[params.field])}`
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'traffic_cost',
    headerName: t('traffic_cost'),
    description: t('tooltips.domains.competitor_traffic_cost'),
    align: 'center',
    headerAlign: 'center',
    minWidth: 120,
    renderCell: (params) => `${formatAmount(params.row[params.field])}$`
  }
];

export const COLUMNS_DOMAIN_COMPARISON_COMMON: ColumnsReturnType = (t) => [
  {
    ...COLUMNS_WITH_GETTER,
    field: 'keyword',
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    flex: 1,
    minWidth: 150,
    renderCell: (params) => getComponent(params)
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'volume',
    headerName: t('volume'),
    description: t('tooltips.tables.volumen'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'cpc',
    headerName: t('cpc'),
    description: t('tooltips.tables.cpc'),
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => (params.row?.cpc ? `$${params.row?.cpc.toFixed(2)}` : '—')
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'competition',
    headerName: t('competition'),
    description: t('tooltips.tables.competition'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'difficulty',
    headerName: t('difficulty'),
    description: t('tooltips.tables.difficult'),
    headerAlign: 'right',
    align: 'right',
    renderCell: (params) => getComponent(params)
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'total_sites',
    headerName: t('total_sites'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'position',
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'url',
    headerName: t('url'),
    description: t('tooltips.tables.url'),
    flex: 1,
    renderCell: (params) => getComponent(params)
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'price',
    headerName: t('price'),
    description: t('tooltips.tables.price_traffic'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'traffic',
    headerName: t('traffic'),
    description: t('tooltips.tables.price_traffic'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'compare_position',
    headerName: t('compare_position'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'compare_url',
    headerName: t('compare_url'),
    flex: 1,
    renderCell: (params) => getComponent(params)
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'compare_price',
    headerName: t('compare_price'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'compare_traffic',
    headerName: t('compare_traffic'),
    headerAlign: 'right',
    align: 'right'
  }
];

// TODO This constant is not being used, it can be eliminated
export const COLUMNS_DOMAIN_COMPARISON_MISSING: ColumnsReturnType = (t, options) => [
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'keyword',
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    flex: 1,
    renderCell: (params) => getComponent(params)
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'volume',
    headerName: t('volume'),
    description: t('tooltips.tables.volumen'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'cpc',
    headerName: t('cpc'),
    description: t('tooltips.tables.cpc'),
    headerAlign: 'right',
    align: 'right',
    valueGetter: (params) => (params.row?.cpc ? `$${params.row?.cpc.toFixed(2)}` : '—')
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'competition',
    headerName: t('competition'),
    description: t('tooltips.tables.competition'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'difficulty',
    headerName: t('difficulty'),
    description: t('tooltips.tables.difficult'),
    headerAlign: 'right',
    align: 'right',
    renderCell: (params) => getComponent(params)
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'total_sites',
    headerName: t('total_sites'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'position',
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    headerAlign: 'right',
    align: 'right',
    renderCell: (params) => getComponent(params)
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'url',
    headerName: t('url'),
    description: t('tooltips.tables.url'),
    flex: 1,
    renderCell: (params) => getComponent(params)
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'price',
    headerName: t('price'),
    description: t('tooltips.tables.price_traffic'),
    headerAlign: 'right',
    align: 'right'
  },
  {
    ...options,
    ...COLUMNS_WITH_GETTER,
    field: 'traffic',
    headerName: t('traffic'),
    description: t('tooltips.tables.price_traffic'),
    headerAlign: 'right',
    align: 'right'
  }
];

export const COLUMNS_OPPORTUNITY_KEYWORD: ColumnsReturnType = (t) => [
  {
    ...COLUMNS_WITH_GETTER,
    field: 'keyword',
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    flex: 1,
    minWidth: 400,
    renderCell: (params) => <KeywordRedirectLink keyword={params.row.keyword} />
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'ctr',
    type: 'number',
    headerName: t('ctr'),
    description: t('tooltips.tables.ctr'),
    valueGetter: (value) => value.row.ctr ?? '—',
    renderCell: (value) => `${Number((value.row.ctr as number).toFixed(1))} %`
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'clicks',
    headerName: t('clicks'),
    description: t('tooltips.tables.clicks'),
    align: 'center',
    headerAlign: 'center',
    valueGetter: (value) => value.row.clicks ?? '—'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'impressions',
    headerName: t('impressions'),
    description: t('tooltips.tables.impressions'),
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    maxWidth: 100,
    valueGetter: (value) => value.row.impressions ?? '—'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'position',
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    maxWidth: 100,
    valueGetter: (value) => value.row.position ?? '—',
    renderCell: (value) => (
      value.row.position != null ? formatPosition(value.row.position) : '—'
    )
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'contains_keyword',
    headerName: t('contains_keyword'),
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
    minWidth: 225,
    valueGetter: (value) => value.row.contains_keyword ?? '—',
    renderCell: (params) => getComponent(params),
    filterOperators: [
      {
        label: t('contains_keyword'),
        value: 'contains_keyword',
        getApplyFilterFn: (filterItem) => {
          if (filterItem.value === undefined) return null;
          const valueAsBoolean = String(filterItem.value) === 'true';
          return (value): boolean => {
            return Boolean(value.row.contains_keyword) === valueAsBoolean;
          };
        },
        InputComponent: undefined,
        InputComponentProps: { type: 'boolean' },
        getValueAsString: (value: boolean) => `${value}`
      }
    ]
  }
];

export const COLUMNS_THIN_CONTENT: ColumnsReturnType = (t) => [
  {
    ...COLUMNS_WITH_GETTER,
    field: 'url',
    headerName: t('url'),
    description: t('tooltips.tables.url'),
    flex: 1,
    minWidth: 400,
    renderCell: (params) => getComponent(params)
  },
  {
    field: 'ctr',
    type: 'number',
    headerName: t('ctr'),
    description: t('tooltips.tables.ctr'),
    valueGetter: (value) => value.row.ctr ?? '—',
    renderCell: (value) => `${Number((value.row.ctr as number).toFixed(1))} %`
  },
  {
    type: 'number',
    field: 'clicks',
    headerName: t('clicks'),
    description: t('tooltips.tables.clicks'),
    valueGetter: (value) => value.row.clicks ?? '—'
  },
  {
    type: 'number',
    field: 'impressions',
    headerName: t('impressions'),
    description: t('tooltips.tables.impressions'),
    valueGetter: (value) => value.row.impressions ?? '—'
  },
  {
    type: 'number',
    field: 'average_position',
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    sortComparator: (v1, v2) => parseInt(v1) - parseInt(v2),
    valueGetter: (value) => value.row.average_position ?? '—',
    renderCell: (value) =>
      value.row.average_position != null ? formatPosition(value.row.average_position) : '—'
  },
  {
    field: 'is_low_by_clicks',
    headerName: t('detected_problems'),
    description: t('tooltips.tables.detected_problems'),
    minWidth: 250,
    renderCell: (params) => getComponent(params)
  }
];

export const COLUMNS_CANNIBALIZATION: ColumnsReturnType = (t) => [
  {
    ...COLUMNS_WITH_GETTER,
    field: 'keyword',
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    minWidth: 350,
    renderCell: (params) => <KeywordRedirectLink keyword={params.row.keyword} />
  },

  {
    ...COLUMNS_WITH_GETTER,
    field: 'results',
    headerName: t('it_is_positioned_in'),
    description: t('tooltips.tables.it_is_positioned_in'),
    flex: 1,
    colSpan: 4,
    editable: false,
    ...DISABLED_FILTERABLE,
    renderCell: (params) => <CannibalizeResults {...params.row} />
  },
  {
    field: 'impressions',
    headerName: t('impressions'),
    description: t('tooltips.tables.impressions'),
    groupable: true,
    width: 100,
    ...DISABLED_FILTERABLE
  },
  {
    field: 'clicks',
    headerName: t('clicks'),
    description: t('tooltips.tables.clicks'),
    groupable: true,
    width: 60,
    ...DISABLED_FILTERABLE
  },
  {
    field: 'position',
    headerName: t('position'),
    description: t('tooltips.tables.position'),
    groupable: true,
    width: 100,
    ...DISABLED_FILTERABLE
  }
];

export const COLUMNS_NICHE_IDEAS: ColumnsReturnType = (t, op) => [
  {
    ...COLUMNS_WITH_GETTER,
    field: 'keyword',
    headerName: t('keyword'),
    description: t('tooltips.tables.keyword'),
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    minWidth: 250,
    renderCell: (params) =>
      op?.isKeyword ? (
        <KeywordLink keyword={params.row.keyword} />
      ) : (
        <KeywordRedirectLink db={op?.code} keyword={params.row.keyword} />
      ),
    ...op
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'volume',
    headerName: t('volume'),
    description: t('tooltips.tables.volumen'),
    sortable: true,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    sortingOrder: ['asc', 'desc'],
    valueGetter: (value) => value.row.keyword_info?.search_volume ?? '—'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'keyword_info.cpc',
    headerName: t('cpc'),
    align: 'center',
    headerAlign: 'center',
    width: 100,
    type: 'number',
    description: t('tooltips.tables.cpc'),
    valueGetter: (params) => params.row.keyword_info?.cpc ?? '—',
    renderCell: (params) =>
      params.row.keyword_info?.cpc ? `$${params.row.keyword_info?.cpc.toFixed(2)}` : '—'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'keyword_info.competition',
    headerName: t('competition'),
    align: 'center',
    headerAlign: 'center',
    description: t('tooltips.tables.competition'),
    valueGetter: (value) => value.row.keyword_info?.competition ?? '—'
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'extra.keyword_difficulty',
    headerName: t('difficulty'),
    align: 'center',
    headerAlign: 'center',
    description: t('tooltips.tables.difficult'),
    renderCell: (params) => getComponent(params),
    valueGetter: (value) => value.row.extra?.keyword_difficulty
  },
  {
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    sortable: false,
    field: 'search_intent_info.intent',
    headerName: t('intent.title'),
    align: 'center',
    headerAlign: 'center',
    description: t('tooltips.tables.intent'),
    renderCell: (params) => getComponent(params),
    valueGetter: transformtValueIntent,
  },
  {
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    sortable: false,
    field: 'serp_info.item_types',
    headerName: t('features_SERP'),
    description: t('tooltips.tables.serps'),
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: (value) => value.row.serp_info?.item_types ?? [].toString()
  },
  {
    ...COLUMNS_WITH_GETTER,
    ...DISABLED_FILTERABLE,
    sortable: false,
    field: 'keyword_info.history',
    headerName: t('history'),
    description: t('tooltips.tables.history'),
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => getComponent(params),
    valueGetter: (value) =>
      value.row.keyword_info?.history.map((element) => element.volume).join(',') || '',
    ...DISABLED_FILTERABLE
  }
];

export const COLUMNS_PROJECTS_MANAGE = (t, blurDomains): GridColDef[] => [
  {
    type: 'string',
    field: 'property',
    headerName: t('project'),
    description: t('tooltips.tables.project'),
    minWidth: 250,
    maxWidth: 350,
    align: 'left',
    sortable: false,
    flex: 1,
    filterable: false,
    headerAlign: 'left',
    disableColumnMenu: true,
    renderCell: (params) => {
      let domain;
      if (params.row.property.startsWith('sc-domain:')) {
        domain = params.row.property.replace('sc-domain:', '');
      } else {
        try {
          const url = new URL(params.row.property);
          domain = url.hostname;
        } catch (error) {
          domain = params.row.property;
        }
      }
      domain = new URL(`http://${domain}`).hostname;

      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <img
            src={`https://www.google.com/s2/favicons?sz=32&domain=${domain}`}
            loading="lazy"
            alt="favicon"
            width={32}
            height={32}
            style={{ borderRadius: '8px' }}
          />
          <Typography className={blurDomains ? 'blurred-text' : ''}>
            {truncate(domain)}
          </Typography>
        </Stack>
      );
    }
  },
  {
    type: 'string',
    field: 'accountInfo',
    headerName: t('account_info'),
    description: t('tooltips.tables.account'),
    flex: 1,
    align: 'left',
    minWidth: 250,
    sortable: false,
    filterable: false,
    headerAlign: 'left',
    disableColumnMenu: true,
    renderCell: (params) => (
      <div className="flex gap-2">
        <Avatar src={params.row.accountInfo.profile_url} alt={params.row.accountInfo.name} />
        <div className="flex flex-col">
          <Typography>{params.row.accountInfo.name}</Typography>
          <Typography variant="caption" className={blurDomains ? 'blurred-text' : ''}>
            {params.row.accountInfo.email}
          </Typography>
        </div>
      </div>
    )
  },
];
const getDomainName = (url: string) => {
  try {
    const hostname = new URL(url).hostname;
    return hostname.replace(/^www\./, '');
  } catch (error) {
    return url.replace(/^www\./, '');
  }
};

export const COLUMNS_DOMAIN_CONTENT_GAP = (t: any, op: any): GridColDef[] => {
  const baseColumnsBeforeCompetitors: GridColDef[] = [
    {
      field: 'keyword',
      headerName: t('keyword'),
      description: t('tooltips.tables.keyword'),
      flex: 1,
      minWidth: 350,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => <KeywordRedirectLink db={op?.code} keyword={params.row.keyword} />,
      type: 'string'
    },
    {
      field: 'search_intent_info.intent',
      sortable: false,
      headerName: t('intent.title'),
      description: t('tooltips.tables.intent'),
      renderCell: (params) => getComponent(params),
      valueGetter: transformtValueIntent,
      type: 'string'
    },
    {
      field: 'you_domain',
      headerName: op?.youDomain || 'Your domain',
      description: t('tooltips.domains.contentgap_your_domain'),
      minWidth: 140,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      valueGetter: (value) => {
        const youDomainName = getDomainName(op.youDomain);
        const domainData = value.row.serp_info?.serp.find((d: any) => getDomainName(d.domain) === youDomainName);
        return domainData?.rank_absolute ?? '—';
      },
      cellClassName: (params) => {
        const allRanks = params.row.serp_info?.serp.map((serp: any) => serp.rank_absolute).filter((rank: any) => rank !== null && rank !== undefined);
        if (allRanks.length === 0) return 'domain-cell';
        const lowestRank = Math.min(...allRanks);
        return classNames('domain-cell', params.value === lowestRank ? 'highlight-lowest-domain' : 'domain-positive');
      },
      type: 'number'
    }
  ];

  const competitorColumns = op.domains.map((domain: string, index: number): GridColDef => ({
    field: `domain_${index + 1}`,
    headerName: domain || `Domain competitor ${index + 1}`,
    description: t('tooltips.domains.contentgap_domain_competitor'),
    minWidth: 140,
    align: 'center' as GridAlignment,
    headerAlign: 'center' as GridAlignment,
    valueGetter: (value) => {
      const competitorDomainName = getDomainName(domain);
      const domainData = value.row.serp_info?.serp.find((d: any) => getDomainName(d.domain) === competitorDomainName);
      return domainData?.rank_absolute ?? '—';
    },
    cellClassName: (params) => {
      const allRanks = params.row.serp_info?.serp.map((serp: any) => serp.rank_absolute).filter((rank: any) => rank !== null && rank !== undefined);
      if (allRanks.length === 0) return 'domain-cell';
      const lowestRank = Math.min(...allRanks);
      return classNames('domain-cell', params.value === lowestRank ? 'highlight-lowest-domain' : 'domain-positive');
    },
    type: 'number'
  }));

  const baseColumnsAfterCompetitors: GridColDef[] = [
    {
      field: 'keyword_info.search_volume',
      headerName: t('volume'),
      description: t('tooltips.tables.volumen'),
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      valueGetter: (value) => formatAmount(value.row.keyword_info?.search_volume) ?? '—',
      type: 'number'
    },
    {
      field: 'extra.keyword_difficulty',
      headerName: t('difficulty'),
      description: t('tooltips.tables.difficult'),
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => getComponent(params),
      valueGetter: (value) => value.row.extra?.keyword_difficulty,
      type: 'number'
    },
    {
      field: 'keyword_info.cpc',
      headerName: t('cpc'),
      description: t('tooltips.tables.cpc'),
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => value.row.keyword_info?.cpc ? `${value.row.keyword_info.cpc.toFixed(2)}$` : '—',
      type: 'number'
    },
    {
      field: 'keyword_info.competition',
      headerName: t('competition'),
      description: t('tooltips.tables.competition'),
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => value.row.keyword_info?.competition ? formatNumber(value.row.keyword_info.competition) : '—',
      type: 'number'
    },
    {
      field: 'serp_info.se_results_count',
      headerName: t('results'),
      description: t('tooltips.tables.serps'),
      headerAlign: 'center' as GridAlignment,
      align: 'center',
      valueGetter: (value) => formatAmount(value.row.serp_info?.se_results_count || 0) ?? '—',
      type: 'number'
    },
  ];

  return baseColumnsBeforeCompetitors.concat(competitorColumns).concat(baseColumnsAfterCompetitors);
};

export const COLUMNS_DOMAIN_KEYWORD_INTENT_TABLE: ColumnsReturnType = (t) => [
  {
    field: 'name',
    headerName: t('intent.title'),
    description: t('tooltips.tables.intent'),
    flex: 1,
    ...DISABLED_FILTERABLE,
    renderCell: (params) => (
      <div className="intent-wrap">
        <div className="pin" style={{ backgroundColor: params.row.color }} />
        <Typography>{t(`intent.${params.row.name}`)}</Typography>
      </div>
    )
  },
  {
    field: 'result',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    hideable: true,
    renderCell: (params) => <Typography color="blue">{params.row.result}</Typography>,
    ...DISABLED_FILTERABLE
  },
  {
    field: 'keyword',
    headerName: t('keywords'),
    description: t('tooltips.tables.keyword'),
    headerAlign: 'center',
    align: 'center',
    ...DISABLED_FILTERABLE,
    ...COLUMNS_WITH_GETTER
  }
];

export const COLUMNS_DOMAIN_BLACKLINKS: ColumnsReturnType = (t) => [
  {
    ...COLUMNS_WITH_GETTER,
    field: 'domain_from_rank',
    headerName: t('domain_from_rank'),
    description: t('tooltips.domains.blacklinks_rank'),
    align: 'center',
    headerAlign: 'center',
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'backlink_spam_score',
    headerName: t('backlink_spam_score'),
    description: t('tooltips.domains.blacklinks_spam_score'),
    align: 'center',
    headerAlign: 'center',
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'page_from_title',
    headerName: t('page_from_title'),
    description: t('tooltips.domains.blacklinks_page_from_title'),
    align: 'left',
    headerAlign: 'center',
    flex: 1,
    minWidth: 300,
    renderCell: (params) => <BacklinkPageFromTitle {...params.row} />
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'anchor',
    headerName: t('anchor'),
    description: t('tooltips.domains.blacklinks_anchor'),
    align: 'left',
    headerAlign: 'center',
    flex: 1,
    minWidth: 300,
    renderCell: (params) => (
      <div className="flex flex-col">
        <Typography>{params.row.anchor}</Typography>
        <KeywordLinkBlank href={params.row.url_to} />
        <div className="flex gap-1 items-center">
          {Array.isArray(params.row.attributes) &&
            params.row.attributes.length > 0 &&
            params.row.attributes.map((item) => <Chip key={item} label={item} />)}
          {params.row.item_type && <Chip label={params.row.item_type} />}
          {params.row.semantic_location && <Chip label={params.row.semantic_location} />}
        </div>
      </div>
    )
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'page_from_external_links',
    headerName: t('page_from_external_links'),
    description: t('tooltips.domains.blacklinks_external_links'),
    align: 'center',
    headerAlign: 'center',
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'page_from_internal_links',
    headerName: t('page_from_internal_links'),
    description: t('tooltips.domains.blacklinks_internal_links'),
    align: 'center',
    headerAlign: 'center',
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'first_seen',
    headerName: t('first_seen'),
    description: t('tooltips.domains.blacklinks_first_seen'),
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <Typography className={classNames('seen-new', params.row.is_new ? 'active' : '')}>
        {formatDateNow(params.row.first_seen)}
      </Typography>
    )
  },
  {
    ...COLUMNS_WITH_GETTER,
    field: 'last_seen',
    headerName: t('last_seen'),
    description: t('tooltips.domains.blacklinks_last_seen'),
    minWidth: 120,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <Typography className={classNames('seen-lost', params.row.is_lost ? 'active' : '')}>
        {formatDateNow(params.row.last_seen)}
      </Typography>
    )
  }
];

export const COLUMNS_ACCOUNT_CONNECTIONS: ColumnsReturnType = (t) => [
  {
    field: 'name',
    headerName: t('account'),
    description: t('tooltips.tables.connection_account'),
    flex: 1,
    sortable: true,
    ...DISABLED_FILTERABLE,
    renderCell: (params) => (
      <Stack direction="row" spacing={2}>
        <Avatar src={params.row.profile_url} alt={params.row.name} />
        <Stack>
          <Typography>{params.row.name}</Typography>
          <Typography variant="caption" className="table-connections-email">
            {params.row.email}
          </Typography>
        </Stack>
      </Stack>
    )
  },
  {
    field: 'view_properties',
    headerName: t('view_properties'),
    description: t('tooltips.tables.connection_properties'),
    pinnable: false,
    width: 130,
    align: 'center',
    headerAlign: 'center',
    ...DISABLED_FILTERABLE,
    renderCell: () => (
      <Button>
        <VisibilityIcon />
      </Button>
    )
  }
];

export const COLUMNS_ACCOUNT_BILLINGS: ColumnsReturnType<BillingsResponse> = (t) => [
  {
    ...DISABLED_FILTERABLE,
    field: 'amount',
    headerName: t('amount'),
    flex: 1,
    sortable: true,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => params.row.amount ?? '—',
    renderCell: (params) => params.row.amount
  },
  {
    ...DISABLED_FILTERABLE,
    field: 'currency',
    headerName: t('currency'),
    flex: 1,
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => params.row.currency ?? '—',
    renderCell: (params) => params.row.currency
  },
  {
    ...DISABLED_FILTERABLE,
    field: 'status',
    headerName: t('status'),
    flex: 1,
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => params.row.status ?? '—',
    renderCell: (params) => params.row.status ?? '—'
  },
  {
    ...DISABLED_FILTERABLE,
    field: 'date',
    headerName: t('date'),
    flex: 1,
    sortable: true,
    type: 'date',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params) => {
      return params.row.date ? new Date(Number(params.row.date) * 1000) : new Date();
    },
    renderCell: (params) => {
      const date = params.row.date ? new Date(Number(params.row.date) * 1000) : new Date();
      return format(date, 'dd/MM/yyyy');
    }
  }
];

/**
 *
 * @param col column of the table
 * @param value the value to render
 * @returns the component to be painted depending of the type of column name
 */
const getComponent: RenderCellTable = (params) => {
  const value = params.row[params.field];
  switch (params.field) {
    case CONSTANT.COLUMN_DIFFICULTY_NAME:
      return <KeywordDifficulty value={params.row.extra?.keyword_difficulty} />;
    case CONSTANT.COLUMN_HISTORY_TREND_NAME:
      return <HistoryTrendChart data={value} />;
    case CONSTANT.COLUMN_HISTORY_NAME:
      return <HistoryTrendChart data={params.row.keyword_info?.history} />;
    case CONSTANT.COLUMN_POSITION_NAME:
      return <KeywordPosition data={{ position: value, prevPos: params.row.prevPos }} />;
    case CONSTANT.COLUMN_DOMAIN_POSITION_NAME:
      return (
        <KeywordPosition
          data={{
            position: params.row.serp_info.serp[0].rank_absolute,
            prevPos: params.row.serp_info.serp[0].previous_rank_absolute
          }}
        />
      );
    case CONSTANT.COLUMN_KEYWORDS_NAME:
      return <KeywordLink keyword={value} />;
    case CONSTANT.COLUMN_URLS_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_COMPARE_URLS_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_DOMAIN_NAME:
      return <KeywordLinkBlank href={value} />;
    case CONSTANT.COLUMN_INTENT_INFO_NAME:
      return <ContainerIntents data={params.row.search_intent_info} />;
    case CONSTANT.COLUMN_ITEM_TYPES_NAME:
      return <KeywordFeatures data={params.row.serp_info?.item_types} />;
    case CONSTANT.COLUMN_SERP_RANK_ABSOLUTE:
      return <ResultRankAbsolute position={params.row.serp_info?.serp?.rank_absolute} />;
    case CONSTANT.COLUMN_SERP_URL:
      return <SerpUrl url={params.row.url} domain={params.row.domain} />;
    case CONSTANT.COLUMN_CONTAINS_KEYWORD:
      return <ContainsKeyword value={params.row.contains_keyword} />;
    case CONSTANT.IS_LOW_BY_CLICKS:
      return (
        <IsLowThinContent
          data={{
            is_low_by_clicks: params.row.is_low_by_clicks,
            is_low_by_impressions: params.row.is_low_by_impressions,
            is_low_by_ctr: params.row.is_low_by_ctr
          }}
        />
      );
    default:
      return value ?? '—';
  }
};
