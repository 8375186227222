import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useColorModeContext } from '../../context/ColorModeContext';
import "./RankerfyUniquePlan.scss";

const RankerfyUniquePlan = ({ planName, price }) => {
  const { mode } = useColorModeContext();

  return (
    <div className={`pricing_card ${mode}`}>
      <div className="box_container">
        <header>
          <h3 className="content_type">
            {planName}
          </h3>
          <h4 className="content_number">
            <b>{price}€</b>
            <span>+ IVA / mes</span>
          </h4>
          <button className={`box_button ${mode}`}>SOY {planName}</button>
        </header>
        <main className="main_content">
          <p className="main_content_list_header">Esto incluye:</p>
          <div className="main_content_list">
            <p>
              <CheckCircleIcon className="icons" />
              Ideas de nicho
            </p>
            <p>
              <CheckCircleIcon className="icons" />
              Explorador de Keywords
            </p>
            <p>
              <CheckCircleIcon className="icons" />
              Análisis de dominio
            </p>
            <p>
              <CheckCircleIcon className="icons" />
              Ideas de keywords
            </p>
            <p>
              <CheckCircleIcon className="icons" />
              Creación de proyectos
            </p>
            <p>
              {planName === "ROOKIE" ? (<CloseIcon className="icons" color="error" />) : (<CheckCircleIcon className="icons" />)}
              Backlinks
            </p>
          </div>
        </main>
      </div>
    </div>
  );
}

export default RankerfyUniquePlan;