import React from 'react';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import useRefreshToken from './useRefreshToken';
import { useUserContext } from '../context/UserContext';
import { BASE_URL, ROUTES } from '../components/Utils/Constants';

const isProtectedRoutes = () => !/^\/buy-plan.*$/i.test(window.location.pathname) && window.location.pathname !== ROUTES.THANKS;

export const useAccessToken = () => {
  const [token, setToken] = React.useState<string>(() => localStorage.getItem('authToken') || '');
  const { t } = useTranslation();
  const refreshToken = useRefreshToken();
  const { getAccessTokenSilently, getAccessTokenWithPlan, loginWithRedirect, isAuthenticated } = useUserContext();

  React.useEffect(() => {
    if (!isAuthenticated && !isProtectedRoutes()) {
      return;
    }

    (async () => {
      try {
        const response = await getAccessTokenSilently();
        const decodedToken = jwtDecode(response);
        const currentDate = new Date().getTime();
        const expiresDate = decodedToken[`${BASE_URL}/subscription`]?.current_period_end;

        if (currentDate > expiresDate * 1000) {
          const newToken = await refreshToken();
          
          setToken(newToken);
          await getAccessTokenWithPlan();
          localStorage.setItem('authToken', newToken);
        } else {
          
          setToken(response);
          localStorage.setItem('authToken', response);
        }
      } catch (e) {
        if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
          const newToken = await refreshToken();
          
          setToken(newToken);
          localStorage.setItem('authToken', newToken);
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oppss...',
            text: t('error.api.unauthorized'),
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            didClose: async () => await loginWithRedirect()
          });
        }
      }
    })();
  }, [loginWithRedirect, getAccessTokenSilently, isAuthenticated]);

  return token;
};