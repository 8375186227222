import React from 'react';
import KeywordIntent from './KeywordIntent';
import { Keyword } from '../../types';

const ContainerIntents = ({ data }: { data: Keyword['search_intent_info'] }) => {
  return (
    <div className='flex-end gap-1'>
      <KeywordIntent value={data?.main_intent} />
      {data?.foreign_intent?.map((intent: string, index: number) => (
        <KeywordIntent key={index} value={intent} />
      ))}
    </div>
  );
};

export default ContainerIntents;
