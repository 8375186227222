import React from 'react';
import { Tooltip } from '@mui/material';

interface Props {
  content?: string;
  children: React.ReactElement<any, any>;
}

const CustomTooltip: React.FC<Props> = ({ content, children }) => (
  <Tooltip arrow followCursor title={<div>{content}</div>} sx={{ maxWidth: 'max-content' }}>
    {children}
  </Tooltip>
);

export default CustomTooltip;
