import React, { useMemo } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useProjectsData } from './useProjectsData';
import { ConnectedAccount, useUsersConnections } from './useUsersConnections';
import { SweetAlertIcon } from '../components/Utils/Constants';
import { COLUMNS_PROJECTS_MANAGE, DISABLED_FILTERABLE } from '../components/Utils/TableConstants';
import { Button, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Projects } from '../types';
import { useUserContext } from '../context/UserContext';

export default function useProjectsManage() {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [projectId, setProjectId] = React.useState<string>();
  const [propertyId, setPropertyId] = React.useState<string>();
  const [currentProject, setCurrentProject] = React.useState<string>();

  const { t } = useTranslation();
  const { loading, connectedAccounts, redirectAccount, getAccounts } = useUsersConnections();
  const {
    error,
    dataProject,
    fetchProjects,
    loadingProjects,
    dataProperties,
    fetchCreateProject,
    fetchGetProperties,
    fetchDeleteProjects
  } = useProjectsData();

  const { blur } = useUserContext();

  const COLUMNS_PROJECTS: GridColDef[] = useMemo(
    () => [
      ...COLUMNS_PROJECTS_MANAGE(t, blur),
      {
        field: 'actions',
        headerName: t('action'),
        align: 'center',
        maxWidth: 100,
        headerAlign: 'center',
        sortable: false,
        pinnable: false,
        ...DISABLED_FILTERABLE,
        renderCell: (params) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <Button color="error" onClick={() => handleDeleteProjectSwal(params.row)}>
              <DeleteIcon />
            </Button>
          </Stack>
        )
      }
    ],
    [blur, t]
  );

  const validateCurrentsProject = (siteUrl: string) =>
    dataProject.some(
      (item) => item.property === siteUrl && item.accountInfo.name === currentProject
    );

  const handleToggleModal = (prop: boolean) => setOpenModal(prop);

  const handleToggleProperty = (prop: string) => setPropertyId(prop);

  const handleRedirectNewAccount = async (title?: string) => {
    Swal.fire({
      title: t(title ?? 'connect_new_account'),
      text: t('you_are_going_to_be_redirected_to_google'),
      icon: SweetAlertIcon.INFO,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: t('cancel'),
      confirmButtonText: t('go_to_google')
    }).then(async (result) => {
      if (result.isConfirmed) await redirectAccount();
    });
  };

  const handleOpenModalProjects = async () => {
    await getAccounts().then(async (account) => {
      if (account !== undefined && account.length > 0) return setOpenModal(true);
      await handleRedirectNewAccount('to_create_a_project_you_need_an_account');
    });
  };

  const handleDeleteProjectSwal = (account: Projects) => {
    Swal.fire({
      title: t('are_you_delete_project'),
      icon: SweetAlertIcon.WARNING,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: t('cancel'),
      confirmButtonText: t('delete'),
      confirmButtonColor: '#de3030'
    }).then(async (result) => {
      if (result.isConfirmed) await handleDeleteProject(account.id);
    });
  };

  const handleDeleteProject = async (id: string) => {
    try {
      const res = await fetchDeleteProjects(id);
      if (res) {
        Swal.fire({
          title: t('Yes!'),
          icon: SweetAlertIcon.SUCCESS,
          text: t('your_project_has_been_successfully_deleted')
        });
        await fetchProjects();
      } else {
        Swal.fire({
          title: 'Oh...',
          icon: SweetAlertIcon.INFO,
          text: t('error_occurred_delete_project')
        });
      }
    } catch (error) {
      Swal.fire({ icon: SweetAlertIcon.ERROR, title: 'Oppss...', text: error });
    }
  };

  const fetchToProperties = async (values: string) => {
    const projects = JSON.parse(values) as ConnectedAccount;
    setProjectId(projects.id);
    setCurrentProject(projects.name);
    await fetchGetProperties({ accountId: projects.id });
  };

  const fetchCreateProjects = async () => {
    try {
      const res = await fetchCreateProject({ id: projectId, property: propertyId });
      if (res) {
        Swal.fire({
          title: t('Yes...'),
          icon: SweetAlertIcon.SUCCESS,
          text: t('your_project_has_been_successfully_created')
        });
        await fetchProjects();
      } else {
        Swal.fire({
          title: 'Oh...',
          icon: SweetAlertIcon.INFO,
          text: t('error_occurred_created_project')
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Oh...',
        icon: SweetAlertIcon.INFO,
        text: t('error_occurred_created_project')
      });
    } finally {
      setOpenModal(false);
    }
  };

  return {
    error,
    loading,
    openModal,
    projectId,
    propertyId,
    dataProject,
    dataProperties,
    loadingProjects,
    COLUMNS_PROJECTS,
    connectedAccounts,
    blur,

    fetchToProperties,
    fetchCreateProjects,

    handleToggleModal,
    handleToggleProperty,
    validateCurrentsProject,
    handleOpenModalProjects,
    handleRedirectNewAccount
  };
}