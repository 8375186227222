import React from 'react';
import { InputLabel, Select, FormControl, SelectChangeEvent } from '@mui/material';

interface Props {
  value: any;
  id?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (event: SelectChangeEvent<any>) => void;
  blurContent?: boolean;
}

const Selector: React.FC<React.PropsWithChildren<Props>> = ({ id, label, children, blurContent, ...props }) => {
  return (
    <FormControl required fullWidth>
      <InputLabel required disabled={props.disabled} size="small">
        {label}
      </InputLabel>
      <Select
        label={label}
        id={id ?? 'project'}
        size="small"
        required
        {...props}
        style={{ filter: blurContent ? 'blur(4px)' : 'none' }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default Selector;
