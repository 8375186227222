export const cleanUrl = (url: string): string => {
  return url?.replace(/^https?:\/\//, '').replace(/^www\./, '').replace(/\/$/, '');
};

export const formatValue = (value: number | string): string | number => {
  if (typeof value === 'number') {
    const formattedValue = value.toFixed(2);
    return formattedValue.endsWith('.00') ? parseInt(formattedValue, 10) : formattedValue;
  }
  return value;
};

export const formatCTR = (value: number | string): string => {
  if (typeof value === 'number') {
    const formattedValue = (value * 100).toFixed(2);
    return formattedValue.endsWith('.00') ? `${parseInt(formattedValue, 10)}%` : `${formattedValue}%`;
  }
  return value;
};

const removeTrailingZeroes = (value: string) => {
  return value.endsWith('.00') ? value.slice(0, -3) : value;
};

export const formatDiff = (diff: number | null): string | null => {
  if (diff === null) return null;
  const formattedValue = diff.toFixed(2);
  return removeTrailingZeroes(formattedValue) + (formattedValue.includes('%') ? '' : '%');
};

export const formatRows = (rows: any[], maxMinClicks: any) =>
  rows.map((row) => {
    const clicksDiff = row.clicksDiff !== undefined ? formatDiff(row.clicksDiff) : null;
    const ctrDiff = row.ctrDiff !== undefined ? formatDiff(row.ctrDiff) + ' pts' : null;
    const impressionsDiff = row.impressionsDiff !== undefined ? formatDiff(row.impressionsDiff) : null;
    const positionDiff = row.positionDiff !== undefined ? formatDiff(row.positionDiff) + ' pts' : null;

    const getFormattedDiff = (diff: string | null): string => {
      if (diff === '0%' || diff === '0 pts' || diff === null) return '';
      const diffValue = parseFloat(diff);
      const diffDirection = diffValue > 0 ? '⬆' : '⬇';
      const diffClass = diffValue > 0 ? 'positive' : 'negative';
      return `<sup class="${diffClass} diff">${diffDirection} ${diff}</sup>`;
    };

    const formatValueWithDiff = (value: number | string, diff: string | null): string => {
      const formattedDiff = getFormattedDiff(diff);
      return `${formatValue(value)} ${formattedDiff}`;
    };

    const formatCTRWithDiff = (value: number | string, diff: string | null): string => {
      const formattedDiff = getFormattedDiff(diff);
      return `${formatCTR(value)} ${formattedDiff}`;
    };

    let countryCode = row.keys[0];
    if (typeof row.keys[0] === 'object' && row.keys[0].countryCode) {
      countryCode = row.keys[0].countryCode;
    }

    return {
      ...row,
      maxClicks: maxMinClicks?.maxClicksItem,
      minClicks: maxMinClicks?.minClicksItem,
      clicksDiff: clicksDiff === '0%' ? '' : clicksDiff,
      ctrDiff: ctrDiff === '0 pts' ? '' : ctrDiff,
      impressionsDiff: impressionsDiff === '0%' ? '' : impressionsDiff,
      positionDiff: positionDiff === '0 pts' ? '' : positionDiff,
      clicks: formatValueWithDiff(row.clicks, clicksDiff),
      ctr: formatCTRWithDiff(row.ctr, ctrDiff),
      impressions: formatValueWithDiff(row.impressions, impressionsDiff),
      position: formatValueWithDiff(row.position, positionDiff),
      keys: [countryCode],
    };
  });

export const translateKeys = (rows: any[], t: (key: string) => string) =>
  rows.map(row => ({
    ...row,
    keys: row.keys.map(key => t(key))
  }));

export const formatNumber = (num: number): string => {
  return num.toFixed(2).endsWith('.00') ? num.toFixed(0) : num.toFixed(2);
};

export const displayFormattedNumber = (num: number): string => {
  return formatNumber(num);
};