import React from 'react';
import { useColorModeContext } from '../../context/ColorModeContext';

interface Props {
  db?: string;
  keyword: string;
}

const KeywordLink: React.FC<Props> = ({ keyword, db }) => {
  const { mode } = useColorModeContext();
  return <p className={`btn-link keyword ${mode}`}>{keyword}</p>;
};

export default KeywordLink;
