import { useEffect, useRef } from 'react';

const useDebouncedEffect = (effect: () => void, deps: any[], delay: number) => {
  const callback = useRef(effect);

  useEffect(() => {
    callback.current = effect;
  }, [effect]);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback.current();
    }, delay);

    return () => clearTimeout(handler);
  }, [...deps, delay]);
};

export default useDebouncedEffect;