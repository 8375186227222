import { useState, useEffect, useCallback } from 'react';
import { useAccessToken } from './useAccessToken';
import { useUserContext } from '../context/UserContext';
import { userConnectionService } from '../services/UserConnectionService';

interface IAccount {
  id: string;
  name: string;
  email: string;
  profile_url: string;
}

export type ConnectedAccount = IAccount;

export const useUsersConnections = () => {
  const [connectedAccounts, setConnectedAccounts] = useState<ConnectedAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [redirectAccountLoading, setRedirectAccountLoading] = useState<boolean>(false);
  const [redirectAddAccountToDatabase, setRedirectAddAccountToDatabase] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const token = useAccessToken();
  const { myUser } = useUserContext();

  useEffect(() => {
    setConnectedAccounts([]);
  }, []);

  const getAccounts = useCallback(async () => {
    setLoading(true);
    try {
      const data = await userConnectionService.getAll({ accessToken: token });
      setConnectedAccounts(data);
      localStorage.setItem('connectedAccounts', JSON.stringify(data));
      return data as ConnectedAccount[];
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const deleteAccounts = useCallback(
    async (account_id: string) => {
      setLoadingDelete(true);
      try {
        const data = await userConnectionService.deleteAccount({ accessToken: token }, account_id);
        return data === 'ok';
      } catch (e) {
        setError(e);
      } finally {
        setLoadingDelete(false);
      }
    },
    [token]
  );

  const redirectAccount = useCallback(async () => {
    setRedirectAccountLoading(true);
    try {
      const data = await userConnectionService.getAddAccountRedirectUrl(myUser?.sub ?? '');
      window.open(data, '_self', 'noopener noreferrer');
    } catch (e) {
      setError(e);
    } finally {
      setRedirectAccountLoading(false);
    }
  }, [myUser]);

  const fetchAccountToDatabase = useCallback(
    async (code: string) => {
      setRedirectAddAccountToDatabase(true);
      try {
        const data = await userConnectionService.addAccountToDatabase({ accessToken: token }, code);
        return data === 'account_connected_successfully';
      } catch (e) {
        setError(e);
      } finally {
        setRedirectAddAccountToDatabase(false);
      }
    },
    [token]
  );

  return {
    connectedAccounts,
    loading,
    loadingDelete,
    error,
    getAccounts,
    deleteAccounts,
    redirectAccount,
    redirectAccountLoading,
    setConnectedAccounts,
    fetchAccountToDatabase,
    redirectAddAccountToDatabase
  };
};
