import React, { useEffect } from 'react';
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  styled,
  Switch,
  Grid
} from '@mui/material';
import RankerfyUniquePlan from '../RankerfyUniquePlan/RankerfyUniquePlan';
import { useSelectedPlan } from '../../context/PlanContext';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

interface RankerfyPlansProps {
  plan: string | null;
  billing: string | null;
  coupon: string | null;
}

const RankerfyPlans: React.FC<RankerfyPlansProps> = ({ plan, billing, coupon }) => {
  const { selectedPlan, setSelectedPlan, selectedBilling, setSelectedBilling } = useSelectedPlan();

  useEffect(() => {
    if (plan) setSelectedPlan(plan);
    if (billing) setSelectedBilling(billing);
  }, [plan, billing, setSelectedPlan, setSelectedBilling]);

  const plans = [
    { name: 'ROOKIE', price: '27' },
    { name: 'ADVANCED', price: '35' },
    { name: 'EXPERT', price: '62' }
  ];

  const billingPrices = {
    monthly: {
      ROOKIE: '27',
      ADVANCED: '35',
      EXPERT: '62'
    },
    yearly: {
      ROOKIE: '24.3',
      ADVANCED: '31.5',
      EXPERT: '55.8'
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '10px',
    cursor: 'pointer',
    '&.active': {
      '.box_container': {
        border: '2px solid #1c244b'
      },
      '.box_button': {
        backgroundColor: '#1c244b'
      }
    }
  }));

  const getPrice = (planName: string) => {
    if (selectedBilling === 'monthly') {
      return billingPrices.monthly[planName];
    } else if (selectedBilling === 'yearly') {
      return billingPrices.yearly[planName];
    }
    return billingPrices.monthly[planName];
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBilling(event.target.checked ? 'yearly' : 'monthly');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {!selectedPlan && (
        <Alert variant="filled" severity="error" sx={{ mb: 2, maxWidth: 500, borderRadius: 1, margin: 'auto' }}>
        Debes seleccionar un plan para continuar
      </Alert>
      )}

      {
      <FormControl component="fieldset" sx={{ mb: 1 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={selectedBilling === 'yearly'}
                onChange={handleChange}
                name="yearly"
              />
            }
            label="Anual (10% dto)"
          />
        </FormGroup>
      </FormControl>
      }

      <Grid container spacing={2} justifyContent="center" >
        {plans.map((p, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <Item
              className={selectedPlan && p.name === selectedPlan ? 'active' : ''}
              onClick={() => {
                if (selectedPlan === p.name) {
                  setSelectedPlan(null);
                  return;
                }
                setSelectedPlan(p.name);
              }}>
              <RankerfyUniquePlan planName={p.name} price={getPrice(p.name)} />
            </Item>
          </Grid>
        ))}
      </Grid>
      <Alert
        icon={<KeyboardDoubleArrowDownIcon fontSize="inherit" />}
        severity="info"
        sx={{ mt: 2 }}>
        Obtén más información acerca de los planes y los límites en la{' '}
        <a href="https://rankerfy.com#planes" target="_blank" rel="noreferrer">
          página de precios
        </a>
        .
      </Alert>
    </Box>
  );
};

export default RankerfyPlans;
