import React from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../Utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

interface Props<Item = any> {
  current: Item;
  suggestions: Readonly<string[]>;
  onChange: (value: Item) => void;
}

const ButtonGroup: React.FC<Props> = ({ current, onChange, suggestions }) => {
  const { t } = useTranslation();
  const { mode } = useColorModeContext();
  return (
    <div className={classNames('btn-group-chip', mode)}>
      {suggestions.map((item) => (
        <button
          key={item}
          className="capitalize"
          onClick={() => onChange(item)}
          data-current={current === item}>
          {t(item)}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
