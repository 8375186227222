import React from 'react';
import { Skeleton } from '@mui/material';
import { generateUuid } from '../Utils/Utils';

interface Props {
  data: any;
  length?: number;
  children: React.ReactNode;
  width?: string | number | `${number}`;
  height?: string | number | `${number}`;
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
}

const WithSkeleton = ({
  data,
  children,
  length = 1,
  variant = 'text',
  width = 20,
  height = 20
}: Readonly<Props>) => {
  if (data === undefined || data === null || data === '')
    return (
      <div className="flex gap-1">
        {Array.from({ length }).map(() => (
          <Skeleton key={generateUuid()} variant={variant} width={width} height={height} />
        ))}
      </div>
    );
  else if (data === 0) return <p className="text-center">—</p>;
  else return children;
};
export default WithSkeleton;
