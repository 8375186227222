import { KeywordOverview, KeywordOverviewResponse } from '../../types';
import { convertObjectDateVolumeToArrayList } from '../../components/Utils/Utils';

const defaultValue: KeywordOverview = {
  items: { serp_info: { serp: [] } }
};

class KeywordOverviewDto {
  keywords: KeywordOverview;
  constructor(data: KeywordOverviewResponse) {
    this.keywords = this.mapKeywords(data);
  }
  private mapKeywords(data: KeywordOverviewResponse): KeywordOverview {
    if (data.items.length === 0) return defaultValue;
    const response = data.items[0];
    return {
      items: {
        ...response,
        keyword_info: {
          ...response.keyword_info,
          cpc: response.keyword_info?.cpc || 0,
          competition: response.keyword_info?.competition || 0,
          time_update: response.keyword_info?.time_update || '',
          search_volume: response.keyword_info?.search_volume || 0,
          history: this.mapKeywordHistory(response.keyword_info?.history ?? {}) as any
        }
      }
    };
  }

  private mapKeywordHistory(history: object) {
    const lastTwelveMonths = Object.fromEntries(Object.entries(history).slice(-24));
    return convertObjectDateVolumeToArrayList(lastTwelveMonths) ?? [];
  }
}

export default KeywordOverviewDto;
