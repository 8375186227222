import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import pkg from './../package.json';

const { version } = pkg;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'es',
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
    ns: ['translation', 'googleUpdates'],
    defaultNS: 'translation',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`,
    },
  });

export default i18n;