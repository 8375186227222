import './index.scss';
import './i18n';
import './script';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { UserProvider } from './context/UserContext';
import { PlanProvider } from './context/PlanContext';
import { ColorModeProvider } from './context/ColorModeContext';
import { KeywordsCacheProvider } from './context/KeywordsCacheContext';
import { KeywordsIdeasCacheProvider } from './context/KeywordsIdeasCacheContext';
import { SearchCacheProvider } from './context/SearchCacheContext';
import { DomainsCacheProvider } from './context/DomainsCacheContext';
import { ROUTES } from './components/Utils/Constants';

const isBuyPlanRoute = () => new URLSearchParams(window.location.search).get('state')?.includes('buy-plan');

const onRedirectCallback = (appState?: AppState) => {
  if (isBuyPlanRoute()) {
    window.history.replaceState({}, document.title, ROUTES.BUY_PLAN_USER);
  } else {
    const url = appState?.returnTo || window.location.pathname;
    window.history.replaceState({}, document.title, url);
  }
};

const renderApp = () => {
  return (
    <Auth0Provider
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation='localstorage'
      onRedirectCallback={onRedirectCallback}
      domain="dev-7iczrz4kq8jjusg8.eu.auth0.com"
      clientId="F9jCNUw5Z00TV60LQyciYVB3opkYedkQ"
      authorizationParams={{
        scope: 'profile offline_access',
        redirect_uri: window.location.origin,
        audience: 'https://dev-7iczrz4kq8jjusg8.eu.auth0.com/api/v2/',
      }}
    >
      <ColorModeProvider>
        <UserProvider>
          <KeywordsCacheProvider>
            <SearchCacheProvider>
            <PlanProvider>
            <DomainsCacheProvider>
              <KeywordsIdeasCacheProvider>
              <App />
              </KeywordsIdeasCacheProvider>
              </DomainsCacheProvider>
            </PlanProvider>
            </SearchCacheProvider>
          </KeywordsCacheProvider>
        </UserProvider>
      </ColorModeProvider>
    </Auth0Provider>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(renderApp());