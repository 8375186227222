import './styles.scss';
import React from 'react';

type Props = Array<{
  key: number;
  name: string;
  color: string;
  value: number;
  result: string;
}>;

const ProgressBarMultiple = (props: { data: Props }) => {
  return (
    <div className="progress-bar">
      {props.data.map((item) =>
        item.value > 0 ? (
          <span
            key={item.key}
            className="progress-item"
            style={{ width: item.result, backgroundColor: item.color }}
          />
        ) : null
      )}
    </div>
  );
};

export default ProgressBarMultiple;
