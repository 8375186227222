import { Paper, styled } from '@mui/material';
import { COLOR_MODE_DARK } from '../Utils/Constants';

const PaperItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === COLOR_MODE_DARK ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary
}));

export default PaperItem;
