import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DesktopIcon from '@mui/icons-material/DesktopWindows';
import MobileIcon from '@mui/icons-material/PhoneAndroid';
import TabletIcon from '@mui/icons-material/Tablet';
import Flag from 'react-world-flags';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const extractMainValue = (content: string) => {
  const regex = /^([^<]*)/g;
  const match = regex.exec(content);
  return match ? parseFloat(match[0].trim()) : 0;
};

const removeTrailingZeroes = (value: string) => {
  return value.endsWith('.00') ? value.slice(0, -3) : value;
};

const customComparator = (v1: string, v2: string) => {
  const value1 = extractMainValue(v1);
  const value2 = extractMainValue(v2);
  return value1 - value2;
};

const getDeviceKey = (device: string) => {
  const translations = {
    'DESKTOP': 'DESKTOP',
    'MOBILE': 'MOBILE',
    'TABLET': 'TABLET',
  };

  return translations[device] || null;
};

const renderDeviceIcon = (device: string, t: any) => {
  const deviceKey = getDeviceKey(device);
  switch (deviceKey) {
    case 'DESKTOP':
      return <DesktopIcon />;
    case 'MOBILE':
      return <MobileIcon />;
    case 'TABLET':
      return <TabletIcon />;
    default:
      return null;
  }
};

const renderDeviceCell = (params: GridRenderCellParams, t: any) => (
  <>
    {renderDeviceIcon(params.value[0], t)}
    <span style={{ marginLeft: 8 }}>{params.value[0]}</span>
  </>
);

const translateKey = (value: string, t: any) => {
  return t(value) || value;
};

const renderSearchAppearanceCell = (params: GridRenderCellParams, t: any) => (
  <span>{translateKey(params.value[0], t)}</span>
);

const renderCountryCell = (params: GridRenderCellParams, t: any) => {
  if (params.value && Array.isArray(params.value) && typeof params.value[0] === 'string') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Flag code={params.value[0].toUpperCase()} height="16" />
        <span style={{ marginLeft: 8 }}>{t(params.value[0].toUpperCase()) || params.value[0]}</span>
      </div>
    );
  }

  return <span>Unknown Country</span>;
};

const extractDiffValues = (content: string) => {
  let contentWithoutDiffs = content;
  const diffs: string[] = [];

  const regex = /([-+]?[0-9]*\.?[0-9]+% pts|[-+]?[0-9]*\.?[0-9]+%|[-+]?[0-9]*\.?[0-9]+ pts)/g;
  let match;
  while ((match = regex.exec(content)) !== null) {
    diffs.push(removeTrailingZeroes(match[0]));
    contentWithoutDiffs = contentWithoutDiffs.replace(match[0], '').trim();
  }

  return { contentWithoutDiffs, diffs };
};

const renderStyledCell = (params: GridRenderCellParams, color: string) => {
  const htmlContent = params.value;
  const { contentWithoutDiffs, diffs } = extractDiffValues(htmlContent);

  const tooltipContent = diffs.join(', ');

  return (
    <Tooltip title={tooltipContent} arrow>
      <div style={{ color }} dangerouslySetInnerHTML={{ __html: contentWithoutDiffs }} />
    </Tooltip>
  );
};

const extractDiffValuesForCTR = (content: string) => {
  let contentWithoutDiffs = content;
  const diffs: string[] = [];
  
  const regex = /([-+]?[0-9]*\.?[0-9]+% pts|[-+]?[0-9]*\.?[0-9]+%)/g;
  let match;

  match = regex.exec(content);
  let ctrValue = match ? removeTrailingZeroes(match[0]) : '';

  if (ctrValue) {
    contentWithoutDiffs = contentWithoutDiffs.replace(match[0], '').trim();
  }

  while ((match = regex.exec(content)) !== null) {
    diffs.push(removeTrailingZeroes(match[0]));
    contentWithoutDiffs = contentWithoutDiffs.replace(match[0], '').trim();
  }
  const arrowRegex = /<sup class=".*?">.*?<\/sup>/g;
  const arrowMatch = arrowRegex.exec(contentWithoutDiffs);
  const arrow = arrowMatch ? contentWithoutDiffs.substring(arrowMatch.index, arrowRegex.lastIndex) : '';
  if (arrow) {
    contentWithoutDiffs = contentWithoutDiffs.replace(arrow, '').trim();
  }

  return { ctrValue, contentWithoutDiffs, diffs, arrow };
};

const renderCTRStyledCell = (params: GridRenderCellParams, color: string) => {
  const htmlContent = params.value;
  const { ctrValue, contentWithoutDiffs, diffs, arrow } = extractDiffValuesForCTR(htmlContent);

  const tooltipContent = diffs.join(', ');

  return (
    <Tooltip title={tooltipContent} arrow>
      <div style={{ color }}>
        {ctrValue}
        {arrow && <span dangerouslySetInnerHTML={{ __html: arrow }}></span>}
        {contentWithoutDiffs}
      </div>
    </Tooltip>
  );
};

const renderHeaderWithTooltip = (headerName: string, tooltipText: string) => (
  <Tooltip title={tooltipText} arrow>
    <span>{headerName}</span>
  </Tooltip>
);

export const getColumnDefinitions = () => {
  const { t } = useTranslation();

  const COLUMNS_QUERIES: GridColDef[] = [
    { field: 'keys', headerName: t('Query'), minWidth: 200, flex: 1, renderHeader: () => renderHeaderWithTooltip(t('Query'), t('Query_tooltip')) },
    { 
      field: 'clicks', 
      headerName: t('Clicks'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#4285f4'), 
      renderHeader: () => renderHeaderWithTooltip(t('Clicks'), t('Clicks_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'impressions', 
      headerName: t('Impressions'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#5e35b1'), 
      renderHeader: () => renderHeaderWithTooltip(t('Impressions'), t('Impressions_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'ctr', 
      headerName: t('CTR'), 
      maxWidth: 200, 
      renderCell: (params) => renderCTRStyledCell(params, '#00897b'), 
      renderHeader: () => renderHeaderWithTooltip(t('CTR'), t('CTR_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'position', 
      headerName: t('Position'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#e8710a'), 
      renderHeader: () => renderHeaderWithTooltip(t('Position'), t('Position_tooltip')),
      sortComparator: customComparator,
    }
  ];

  const COLUMNS_PAGES: GridColDef[] = [
    { field: 'keys', headerName: t('Page'), minWidth: 200, flex: 1, renderHeader: () => renderHeaderWithTooltip(t('Page'), t('Page_tooltip')) },
    { 
      field: 'clicks', 
      headerName: t('Clicks'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#4285f4'), 
      renderHeader: () => renderHeaderWithTooltip(t('Clicks'), t('Clicks_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'impressions', 
      headerName: t('Impressions'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#5e35b1'), 
      renderHeader: () => renderHeaderWithTooltip(t('Impressions'), t('Impressions_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'ctr', 
      headerName: t('CTR'), 
      maxWidth: 200, 
      renderCell: (params) => renderCTRStyledCell(params, '#00897b'), 
      renderHeader: () => renderHeaderWithTooltip(t('CTR'), t('CTR_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'position', 
      headerName: t('Position'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#e8710a'), 
      renderHeader: () => renderHeaderWithTooltip(t('Position'), t('Position_tooltip')),
      sortComparator: customComparator,
    }
  ];

  const COLUMNS_COUNTRIES: GridColDef[] = [
    {
      field: 'keys',
      headerName: t('Country'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => renderCountryCell(params, t),
      renderHeader: () => renderHeaderWithTooltip(t('Country'), t('Country_tooltip'))
    },
    { 
      field: 'clicks', 
      headerName: t('Clicks'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#4285f4'), 
      renderHeader: () => renderHeaderWithTooltip(t('Clicks'), t('Clicks_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'impressions', 
      headerName: t('Impressions'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#5e35b1'), 
      renderHeader: () => renderHeaderWithTooltip(t('Impressions'), t('Impressions_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'ctr', 
      headerName: t('CTR'), 
      maxWidth: 200, 
      renderCell: (params) => renderCTRStyledCell(params, '#00897b'), 
      renderHeader: () => renderHeaderWithTooltip(t('CTR'), t('CTR_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'position', 
      headerName: t('Position'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#e8710a'), 
      renderHeader: () => renderHeaderWithTooltip(t('Position'), t('Position_tooltip')),
      sortComparator: customComparator,
    }
  ];

  const COLUMNS_DEVICES: GridColDef[] = [
    {
      field: 'keys',
      headerName: t('Device'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => renderDeviceCell(params, t),
      renderHeader: () => renderHeaderWithTooltip(t('Device'), t('Device_tooltip'))
    },
    { 
      field: 'clicks', 
      headerName: t('Clicks'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#4285f4'), 
      renderHeader: () => renderHeaderWithTooltip(t('Clicks'), t('Clicks_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'impressions', 
      headerName: t('Impressions'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#5e35b1'), 
      renderHeader: () => renderHeaderWithTooltip(t('Impressions'), t('Impressions_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'ctr', 
      headerName: t('CTR'), 
      maxWidth: 200, 
      renderCell: (params) => renderCTRStyledCell(params, '#00897b'), 
      renderHeader: () => renderHeaderWithTooltip(t('CTR'), t('CTR_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'position', 
      headerName: t('Position'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#e8710a'), 
      renderHeader: () => renderHeaderWithTooltip(t('Position'), t('Position_tooltip')),
      sortComparator: customComparator,
    }
  ];

  const COLUMNS_SEARCH_APPEARANCES: GridColDef[] = [
    {
      field: 'keys',
      headerName: t('Search Appearance'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => renderSearchAppearanceCell(params, t),
      renderHeader: () => renderHeaderWithTooltip(t('Search Appearance'), t('SearchAppearance_tooltip'))
    },
    { 
      field: 'clicks', 
      headerName: t('Clicks'), 
      maxWidth: 200,
      renderCell: (params) => renderStyledCell(params, '#4285f4'), 
      renderHeader: () => renderHeaderWithTooltip(t('Clicks'), t('Clicks_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'impressions', 
      headerName: t('Impressions'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#5e35b1'), 
      renderHeader: () => renderHeaderWithTooltip(t('Impressions'), t('Impressions_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'ctr', 
      headerName: t('CTR'), 
      maxWidth: 200, 
      renderCell: (params) => renderCTRStyledCell(params, '#00897b'), 
      renderHeader: () => renderHeaderWithTooltip(t('CTR'), t('CTR_tooltip')),
      sortComparator: customComparator,
    },
    { 
      field: 'position', 
      headerName: t('Position'), 
      maxWidth: 200, 
      renderCell: (params) => renderStyledCell(params, '#e8710a'), 
      renderHeader: () => renderHeaderWithTooltip(t('Position'), t('Position_tooltip')),
      sortComparator: customComparator,
    }
  ];

  return { COLUMNS_QUERIES, COLUMNS_PAGES, COLUMNS_COUNTRIES, COLUMNS_DEVICES, COLUMNS_SEARCH_APPEARANCES };
};