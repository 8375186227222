import React from 'react';
import { GridPaginationModel } from '@mui/x-data-grid';
import * as CONSTANTS from '../components/Utils/Constants';

export default function usePaginationModel(initialPagination?: GridPaginationModel) {
  const [queryOptions, setQueryOptions] = React.useState<{ sortModel: any[] }>({
    sortModel: []
  });
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>(
    initialPagination || {
      page: 0,
      pageSize: CONSTANTS.PAGE_SIZE
    }
  );

  const handlePaginationChange = (newPaginationModel: GridPaginationModel) =>
    setPaginationModel(newPaginationModel);

  const handleSortModelChange = React.useCallback(
    (sortModel) => {
      setQueryOptions({ sortModel: [...sortModel] });
    },
    [queryOptions]
  );

  const paginatedMemoized = React.useMemo(() => {
    const data: any = {
      limit: paginationModel.pageSize,
      from: paginationModel.page * paginationModel.pageSize
    };

    if (queryOptions.sortModel.length > 0) {
      const sorting = queryOptions.sortModel[0];
      data.field = sorting.field;
      data.sort = sorting.sort;
    }

    return data;
  }, [queryOptions, paginationModel]);

  return {
    queryOptions,
    paginationModel,
    paginatedMemoized,
    handleSortModelChange,
    handlePaginationChange
  };
}