import './PageContainer.scss';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from './Header';
import { Sidebar } from './Sidebar';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

export const PageContainer: React.FC<React.PropsWithChildren> = ({ children }) => (
  <React.Fragment>
    <Header />
    <div className="page-container">
      <Sidebar />
      <div className="page-content">
        <Breadcrumb />
        {children ?? <Outlet />}
      </div>
    </div>
  </React.Fragment>
);
