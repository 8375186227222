import apiClient from '../lib/apiClient';

class BaseService {
  public get(url: string, accessToken: string) {
    this.setToken(accessToken);
    return apiClient.get(url);
  }

  public post(url: string, data: any, accessToken: string) {
    this.setToken(accessToken);
    return apiClient.post(url, data);
  }

  private setToken(accessToken: string) {
    if (accessToken) {
      apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  }
}

export default BaseService;
