import React from 'react';
import { useTranslation } from 'react-i18next';

import { useKeywordsFiltersContext } from '../../../context/KeywordsFilterContext';
import {
  valuesSplitNumber,
  intentOfSearchesOptions,
  volumeOfSearchesOptions,
  differenceOfSearchesOptions
} from '../../Utils/Utils';

import CustomRange from '../../CustomRange';
import MenuSelector from '../../MenuSelector';
import ModeSelectorEmpty from '../../ModeSelectorEmpty';
import ModeSelectorWithCheck from '../../ModeSelectorWithCheck';

const KeywordsFilters = () => {
  const { t } = useTranslation();
  const {
    selected,
    cpcOfSearches,
    defaultValueCpc,
    volumeOfSearches,
    differenceOfSearches,
    defaultValueVolume,
    defaultValueDifference,
    hasCpcOfSearches,
    hasDifferenceOfSearches,
    hasVolumeOfSearches,
    handleChangeIntent,
    handleCpcOfSearches,
    handleDifferenceOfSearches,
    handleVolumeOfSearches
  } = useKeywordsFiltersContext();

  return (
    <React.Fragment>
      <MenuSelector
        value={volumeOfSearches}
        isSelected={hasVolumeOfSearches}
        defaultValue={defaultValueVolume}
        suggestion={volumeOfSearchesOptions}
        onChange={(values) => handleVolumeOfSearches(valuesSplitNumber(values.label))}>
        <CustomRange
          toValues={volumeOfSearches.to}
          fromValues={volumeOfSearches.from}
          onClick={(value) => handleVolumeOfSearches(value)}
        />
      </MenuSelector>
      <MenuSelector
        value={differenceOfSearches}
        isSelected={hasDifferenceOfSearches}
        defaultValue={defaultValueDifference}
        suggestion={differenceOfSearchesOptions}
        onChange={(values) => handleDifferenceOfSearches(valuesSplitNumber(values.label))}>
        <CustomRange
          toValues={differenceOfSearches.to}
          fromValues={differenceOfSearches.from}
          onClick={(values) => handleDifferenceOfSearches(values)}
        />
      </MenuSelector>
      <ModeSelectorEmpty
        isSelected={hasCpcOfSearches}
        defaultValue={defaultValueCpc}
        onClear={() => handleCpcOfSearches({ from: 0, to: 0 })}>
        <CustomRange
          toValues={cpcOfSearches.to}
          fromValues={cpcOfSearches.from}
          onClick={(values) => handleCpcOfSearches(values)}
        />
      </ModeSelectorEmpty>
      <ModeSelectorWithCheck
        selected={selected}
        onChange={handleChangeIntent}
        defaultValue={t('intent.title')}
        suggestion={intentOfSearchesOptions}
      />
    </React.Fragment>
  );
};

export default KeywordsFilters;
