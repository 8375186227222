const {
  VITE_HOST,
  VITE_SERVICE_DOMAINS_BLACKLINKS,
  VITE_SERVICE_DOMAINS_GET_BULK_OVERVIEW,
  VITE_SERVICE_DOMAINS_GET_COMPARISON,
  VITE_SERVICE_DOMAINS_GET_COMPETITORS,
  VITE_SERVICE_DOMAINS_GET_CONTENT_GAP,
  VITE_SERVICE_DOMAINS_GET_IDEA_OVERVIEW,
  VITE_SERVICE_DOMAINS_GET_INTENTS,
  VITE_SERVICE_DOMAINS_GET_KEYWORDS,
  VITE_SERVICE_DOMAINS_EXPORT_KEYWORDS,
  VITE_SERVICE_DOMAINS_GET_RANK_RANGES,
  VITE_SERVICE_DOMAINS_OVERVIEW_HISTORY,
  VITE_SERVICE_DOMAINS_OVERVIEW,
  VITE_SERVICE_DOMAINS_V2_GET_COMPARISON,
  VITE_SERVICE_DOMAINS_V2_GET_COMPETITORS,
  VITE_SERVICE_DOMAINS_V2_GET_KEYWORDS,
  VITE_SERVICE_DOMAINS_V2_PAGES,
  VITE_SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS,
  VITE_SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS,
  VITE_SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS,
  VITE_SERVICE_KEYWORDS_GET_IN_BULK,
  VITE_SERVICE_KEYWORDS_GET_KEYWORD_OVERVIEW,
  VITE_SERVICE_KEYWORDS_GET_KEYWORDS,
  VITE_SERVICE_KEYWORDS_GET_NICHE_IDEAS,
  VITE_SERVICE_PROJECTS_GET_CANNIBALIZATION,
  VITE_SERVICE_PROJECTS_GET_KEYWORDS_FROM_URL,
  VITE_SERVICE_PROJECTS_GET_THIN_CONTENT,
  VITE_SERVICE_PROJECTS_GET_PROJECTS_PERFORMANCE,
  VITE_SERVICE_PROJECTS_EXPORT_CANNIBALIZATION,
  VITE_SERVICE_PROJECTS_EXPORT_THIN_CONTENT,
  VITE_SERVICE_PROJECTS_EXPORT_KEYWORDS,
  VITE_SERVICE_PROJECTS_GET_PROJECT_INFO,
  VITE_SERVICE_USERS_CREATE_CHECKOUT,
  VITE_SERVICE_USERS_CREATE_PROJECT,
  VITE_SERVICE_USERS_DELETE_CONNECTED_ACCOUNT,
  VITE_SERVICE_USERS_DELETE_PROJECT,
  VITE_SERVICE_USERS_GET_CONNECTED_ACCOUNTS,
  VITE_SERVICE_USERS_GET_GOOGLE_TOKEN,
  VITE_SERVICE_USERS_GET_INVOICES,
  VITE_SERVICE_USERS_POST_SAVE_INVOICES,
  VITE_SERVICE_USERS_GET_PROJECTS,
  VITE_SERVICE_USERS_RETRIEVE_CHECKOUT,
  VITE_SERVICE_USERS_GET_PROPERTIES,
  VITE_SERVICE_USERS_GOOGLE_REDIRECT_URI,
  VITE_SERVICE_USERS_REDIRECT_STRIPE,
} = import.meta.env;

export {
  VITE_HOST,
  VITE_SERVICE_DOMAINS_BLACKLINKS,
  VITE_SERVICE_DOMAINS_GET_BULK_OVERVIEW,
  VITE_SERVICE_DOMAINS_GET_COMPARISON,
  VITE_SERVICE_DOMAINS_GET_COMPETITORS,
  VITE_SERVICE_DOMAINS_GET_CONTENT_GAP,
  VITE_SERVICE_DOMAINS_GET_IDEA_OVERVIEW,
  VITE_SERVICE_DOMAINS_GET_INTENTS,
  VITE_SERVICE_DOMAINS_GET_KEYWORDS,
  VITE_SERVICE_DOMAINS_EXPORT_KEYWORDS,
  VITE_SERVICE_DOMAINS_GET_RANK_RANGES,
  VITE_SERVICE_DOMAINS_OVERVIEW_HISTORY,
  VITE_SERVICE_DOMAINS_OVERVIEW,
  VITE_SERVICE_DOMAINS_V2_GET_COMPARISON,
  VITE_SERVICE_DOMAINS_V2_GET_COMPETITORS,
  VITE_SERVICE_DOMAINS_V2_GET_KEYWORDS,
  VITE_SERVICE_DOMAINS_V2_PAGES,
  VITE_SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS,
  VITE_SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS,
  VITE_SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS,
  VITE_SERVICE_KEYWORDS_GET_IN_BULK,
  VITE_SERVICE_KEYWORDS_GET_KEYWORD_OVERVIEW,
  VITE_SERVICE_KEYWORDS_GET_KEYWORDS,
  VITE_SERVICE_KEYWORDS_GET_NICHE_IDEAS,
  VITE_SERVICE_PROJECTS_GET_CANNIBALIZATION,
  VITE_SERVICE_PROJECTS_GET_KEYWORDS_FROM_URL,
  VITE_SERVICE_PROJECTS_GET_THIN_CONTENT,
  VITE_SERVICE_PROJECTS_GET_PROJECTS_PERFORMANCE,
  VITE_SERVICE_PROJECTS_EXPORT_CANNIBALIZATION,
  VITE_SERVICE_PROJECTS_EXPORT_THIN_CONTENT,
  VITE_SERVICE_PROJECTS_EXPORT_KEYWORDS,
  VITE_SERVICE_PROJECTS_GET_PROJECT_INFO,
  VITE_SERVICE_USERS_CREATE_CHECKOUT,
  VITE_SERVICE_USERS_CREATE_PROJECT,
  VITE_SERVICE_USERS_DELETE_CONNECTED_ACCOUNT,
  VITE_SERVICE_USERS_DELETE_PROJECT,
  VITE_SERVICE_USERS_GET_CONNECTED_ACCOUNTS,
  VITE_SERVICE_USERS_GET_GOOGLE_TOKEN,
  VITE_SERVICE_USERS_GET_INVOICES,
  VITE_SERVICE_USERS_POST_SAVE_INVOICES,
  VITE_SERVICE_USERS_GET_PROJECTS,
  VITE_SERVICE_USERS_RETRIEVE_CHECKOUT,
  VITE_SERVICE_USERS_GET_PROPERTIES,
  VITE_SERVICE_USERS_GOOGLE_REDIRECT_URI,
  VITE_SERVICE_USERS_REDIRECT_STRIPE,
};
