import React from 'react';
import { useTranslation } from 'react-i18next';
import './KeywordIntent.scss';
import { Tooltip, Typography } from '@mui/material';

interface Props {
  value?: string;
}

const KeywordIntent: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      arrow
      followCursor
      title={
        <div>
          <div>{t(`intent.${value}`)}</div>
        </div>
      }>
      <div className="intent">
        <div className={`border ${value}`}>
          <Typography fontWeight={"700"} className={value} >{value === undefined ? '—' : value.charAt(0).toUpperCase()}</Typography>
        </div>
      </div>
    </Tooltip>
  );
};

export default KeywordIntent;
