import React from 'react';

import './DomainInternLink.scss';

import { useColorModeContext } from '../../context/ColorModeContext';
import { isURL } from '../Utils/Utils';


interface Props {
  href: string;
}

const DomainInternLink: React.FC<Props> = ({ href }) => {
  const { mode } = useColorModeContext();
  const validateUrl = isURL(href) ? href : `https://${href}`;

  return (
      <a
        target="_blank"
        href={validateUrl}
        rel="noopener noreferrer"
        className={`btn-domain ${mode}`}>
        {href}
      </a>
  );
};

export default DomainInternLink;
