export interface DomainsOverviewHistoryDataItem {
  keywords_count: number;
  month: number;
  price_sum: number;
  top1_5: number;
  top6_10: number;
  top11_20: number;
  top21_50: number;
  top51_100: number;
  traffic_sum: number;
  year: number;
}

class DomainsOverviewHistoryDto {
  keywords: DomainsOverviewHistoryDataItem[];

  constructor(data: DomainsOverviewHistoryDataItem[]) {
    this.keywords = this.mapKeywords(data);
  }

  private mapKeywords(data: DomainsOverviewHistoryDataItem[]): DomainsOverviewHistoryDataItem[] {
    return data.map((item) => ({
      keywords_count: item.keywords_count,
      month: item.month,
      price_sum: item.price_sum,
      top1_5: item.top1_5,
      top6_10: item.top6_10,
      top11_20: item.top11_20,
      top21_50: item.top21_50,
      top51_100: item.top51_100,
      traffic_sum: item.traffic_sum,
      year: item.year
    }));
  }
}

export default DomainsOverviewHistoryDto;
