import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { ChartHeaderProps } from '../../types';

const ChartHeader: React.FC<ChartHeaderProps> = ({ project, blurTitle, loading = false, clickable = true }) => {
  const url = project.url || 'Unknown';
  const domain = url.replace(/^(https?:\/\/)?(www\.)?/, '');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 3,
        cursor: clickable ? 'pointer' : 'default'
      }}
    >
      {loading ? (
        <>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
        </>
      ) : (
        <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={`https://www.google.com/s2/favicons?sz=32&domain=${domain || 'example.com'}`}
            loading="lazy"
            alt="favicon"
            width={20}
            height={20}
            style={{ borderRadius: '50%' }}
          />
          <Typography
            variant="h6"
            marginLeft={1}
            sx={{
              filter: blurTitle ? 'blur(4px)' : 'none',
              display: 'inline-block',
            }}
          >
            {url}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ChartHeader;