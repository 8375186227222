import './styles.css';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useRangesDate from '../../hooks/useRangesDate';
import { useProjectsData } from '../../hooks/useProjectsData';
import useProjectsFilters from '../../hooks/useProjectsFilters';
import useExportData from '../../hooks/useExportData';

import * as CONSTANTS from '../../components/Utils/Constants';
import { COLUMNS_OPPORTUNITY_KEYWORD } from '../../components/Utils/TableConstants';
import { isURL, valuesSplitNumber, volumeOfSearchesOptions } from '../../components/Utils/Utils';

import { Grid, Button, MenuItem, TextField, Typography, FormControl, Box } from '@mui/material';
import ButtonExportExcel from '../../components/Buttons/ExportExcel';
import Selector from '../../components/Selector/Selector';
import DateRange from '../../components/DateRange/DateRange';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import CustomRange from '../../components/CustomRange/CustomRange';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import MenuSelector from '../../components/MenuSelector/MenuSelector';

type FilterModeType = (typeof CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY)[number];

export const ProjectsOpportunityKeywordsPage = () => {
  const inputRef = useRef<any | null>(null);
  const [projectUrl, setProjectUrl] = useState<string>();
  const [targetUrl, setTargetUrl] = useState<string>();
  const [dataKeyword, setDataKeyword] = useState<any[]>([]);
  const [selectedProject, setSelectedProject] = useState<any>();

  const [filterMode, setFilterMode] = useState<FilterModeType>(
    CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY[0]
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rangesDate, handleChangeRanges } = useRangesDate();
  const { loadingKeywords, dataProject, fetchKeywords } = useProjectsData();
  const { fetchExportProjectsKeywords, isLoading: isExportLoading } = useExportData();

  const {
    effectFilter,
    ctrOfSearches,
    clicksOfSearches,
    positionOfSearches,
    impressionsOfSearches,

    // DEFAULT VALUE
    defaultValueCtr,
    defaultValueClicks,
    defaultValuePositions,
    defaultValueImpression,

    // STATES
    hasCtrOfSearches,
    hasClicksOfSearches,
    hasPositionsOfSearches,
    hasImpressionsOfSearches,

    // CALLBACKS
    handleFiltersApply,
    handleCtrOfSearches,
    handleClicksOfSearches,
    handlePositionOfSearches,
    handleImpressionsOfSearches
  } = useProjectsFilters();

  const handleModeFilter = (value: FilterModeType) => setFilterMode(value);

  const convertOpportunityKeywordsResponse = (response) => {
    return response.items.map(item => ({
        keyword: item.keys[0],
        url: item.keys[1],
        ...item
    }));
  };

  const getKeywords = useCallback(async () => {
    if (!projectUrl || !targetUrl || !targetUrl?.includes(projectUrl)) return;
    const data = await fetchKeywords({ url: targetUrl, project: selectedProject.property, ...rangesDate, ...handleFiltersApply() });
    if (data) {
        const formattedData = convertOpportunityKeywordsResponse(data);
        setDataKeyword(formattedData);
    }
    handleModeFilter(CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY[0]);
  }, [projectUrl, targetUrl, rangesDate, fetchKeywords, handleFiltersApply]);

  const fetchGetKeywords = async () => await getKeywords();

  const handleExport = async () => {
    if (!targetUrl) return;
    const data = { url: targetUrl, project: selectedProject.property, ...rangesDate, ...handleFiltersApply() };
    await fetchExportProjectsKeywords(data);
  };

  const handleChangeProjects = (event: any) => {
    const project = JSON.parse(event.target.value);
    const property = project.property;
    const URL = property.search(':');
    let URL_TARJET = property;
    const pattern = /^(http(s?):\/\/)/;

    if (URL > 0 && !pattern.test(property)) {
      const URL = property.split(':');
      URL_TARJET = isURL(URL[1]);
    }

    setTargetUrl(URL_TARJET);
    setProjectUrl(URL_TARJET);
    setSelectedProject(project);
    inputRef.current.focus();
  };

  const validateProjectUrlMemoized = useMemo(() => {
    if (targetUrl && projectUrl) return !targetUrl.includes(projectUrl) || loadingKeywords;
    return true;
  }, [targetUrl, projectUrl, loadingKeywords]);

  useEffect(() => {
    if (projectUrl && targetUrl && projectUrl?.includes(targetUrl) && effectFilter > 0)
      fetchGetKeywords();
  }, [projectUrl, targetUrl, effectFilter]);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Selector value={projectUrl} label={t('project')} onChange={handleChangeProjects}>
            {dataProject.length > 0 ? (
              dataProject?.map((project) => (
                <MenuItem key={project.id} value={JSON.stringify(project)}>
                  {project.property}
                </MenuItem>
              ))
            ) : (
              <Button
                sx={{ p: 0 }}
                variant="text"
                className="capitalize w-full"
                onClick={() => navigate(CONSTANTS.ROUTES.PROJECTS_MANAGE)}>
                + {t('create_your_first_project')}
              </Button>
            )}
          </Selector>

          <FormControl fullWidth margin="normal">
            <TextField
              focused
              required
              label="URL"
              size="small"
              value={targetUrl}
              variant="outlined"
              inputRef={inputRef}
              inputProps={{ autoFocus: true }}
              disabled={projectUrl === undefined}
              onChange={(e) => setTargetUrl(e.target.value)}
              placeholder={t('the_url_must_have_the_domain')}
              onKeyDown={(e) =>
                e.key === CONSTANTS.KEY_ENTER_NAME && !validateProjectUrlMemoized
                  ? fetchGetKeywords()
                  : null
              }
            />
          </FormControl>

          <div className="flex gap-2 w-full items-center py-3">
            <MenuSelector
              value={clicksOfSearches}
              isSelected={hasClicksOfSearches}
              defaultValue={defaultValueClicks}
              suggestion={CONSTANTS.clicksOfSearchesOptions}
              onChange={(values) => handleClicksOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={clicksOfSearches.to}
                fromValues={clicksOfSearches.from}
                onClick={(value) => handleClicksOfSearches(value)}
              />
            </MenuSelector>
            <MenuSelector
              value={impressionsOfSearches}
              defaultValue={defaultValueImpression}
              suggestion={volumeOfSearchesOptions}
              isSelected={hasImpressionsOfSearches}
              onChange={(values) => handleImpressionsOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={impressionsOfSearches.to}
                fromValues={impressionsOfSearches.from}
                onClick={(value) => handleImpressionsOfSearches(value)}
              />
            </MenuSelector>
            <MenuSelector
              value={positionOfSearches}
              isSelected={hasPositionsOfSearches}
              defaultValue={defaultValuePositions}
              suggestion={CONSTANTS.potitionOfSearchesOptions}
              onChange={(values) => handlePositionOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={positionOfSearches.to}
                fromValues={positionOfSearches.from}
                onClick={(value) => handlePositionOfSearches(value)}
              />
            </MenuSelector>
            <MenuSelector
              value={ctrOfSearches}
              isSelected={hasCtrOfSearches}
              defaultValue={defaultValueCtr}
              suggestion={CONSTANTS.CRTOfSearchesOptions}
              onChange={(values) => handleCtrOfSearches(valuesSplitNumber(values.label))}>
              <CustomRange
                toValues={ctrOfSearches.to}
                fromValues={ctrOfSearches.from}
                onClick={(value) => handleCtrOfSearches(value)}
              />
            </MenuSelector>
            <div className="flex">
              <DateRange onChangeValue={handleChangeRanges} />
            </div>
          </div>
          <div className="w-full btn-obtain">
            <Button
              color="primary"
              variant="contained"
              onClick={fetchGetKeywords}
              disabled={validateProjectUrlMemoized}>
              {t('obtain')}
            </Button>
          </div>
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
            <Typography className="mb-5">{t('organic_keywords')}</Typography>
            <ButtonExportExcel isLoading={isExportLoading} onClick={handleExport} totalKeywords={dataKeyword.length} query={projectUrl || ""} />
          </Box>
          <TableComponent
            rows={dataKeyword}
            loading={loadingKeywords}
            getRowId={(row) => row.keyword}
            columns={COLUMNS_OPPORTUNITY_KEYWORD(t)}
            filterModel={{
              items: [
                {
                  id: 'shurify',
                  field: 'contains_keyword',
                  operator: 'contains_keyword',
                  value: filterMode === 'all' ? undefined : filterMode === 'included'
                }
              ]
            }}
            slots={{
              panel: () => (
                <div className="py-2">
                  <ButtonGroup
                    current={filterMode}
                    onChange={handleModeFilter}
                    suggestions={CONSTANTS.ALL_FILTER_MODE_OPPORTUNITY}
                  />
                </div>
              )
            }}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};