import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface Props extends React.PropsWithChildren {
  anchor: string;
  onClick?: () => void;
}

const ViewMoreButton: React.FC<Props> = ({ anchor, children, onClick }) => {
  return (
    <Stack direction="row" spacing={2} marginTop={2}>
      <Button variant="contained" href={anchor} onClick={onClick}>
        {children}
      </Button>
    </Stack>
  );
};

export default ViewMoreButton;