import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { LOCATION_CODE_ES } from '../components/Utils/Constants';

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface KeywordsIdeasCacheContextType {
  getKeywordsIdeasCache: (key: string) => string;
  updateKeywordsIdeasCache: (key: string, value: string) => void;
  getAllKeywordsIdeasCache: () => object;
  getLocation: (key: string) => number;
  updateLocation: (key: string, value: number) => void;
  getPagination: (key: string) => PaginationModel;
  updatePagination: (key: string, value: PaginationModel) => void;
}

const KeywordsIdeasCacheContext = createContext<KeywordsIdeasCacheContextType | undefined>(undefined);

interface KeywordsIdeasCacheProviderProps {
  children: ReactNode;
}

const LOCAL_STORAGE_KEY = 'keywordsIdeasContext';

export const KeywordsIdeasCacheProvider: React.FC<KeywordsIdeasCacheProviderProps> = ({ children }) => {
  const [context, setContext] = useState(() => {
    const storedContext = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedContext ? JSON.parse(storedContext) : {};
  });

  useEffect(() => {
    if (context) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(context));
    }
  }, [context]);

  const getKeywordsIdeasCache = (key: string) => {
    const value = context[key] || '';
    return value;
  };

  const updateKeywordsIdeasCache = (key: string, value: string) => {
    if (!value || value === context[key]) return;
    setContext((prevContext) => ({
      ...prevContext,
      [key]: value,
    }));
  };

  const getLocation = (key: string) => {
    const value = context[`${key}_location`] || LOCATION_CODE_ES;
    return value;
  };

  const updateLocation = (key: string, value: number) => {
    if (value === context[`${key}_location`]) return;
    setContext(prevContext => ({
      ...prevContext,
      [`${key}_location`]: value,
    }));
  };

  const getPagination = (key: string) => {
    const value = context[`${key}_pagination`] || { page: 0, pageSize: 10 };
    return value;
  };

  const updatePagination = (key: string, value: PaginationModel) => {
    setContext(prevContext => ({
      ...prevContext,
      [`${key}_pagination`]: value,
    }));
  };

  const getAllKeywordsIdeasCache = () => {
    return context;
  };

  return (
    <KeywordsIdeasCacheContext.Provider
      value={{ getKeywordsIdeasCache, updateKeywordsIdeasCache, getAllKeywordsIdeasCache, getLocation, updateLocation, getPagination, updatePagination }}
    >
      {children}
    </KeywordsIdeasCacheContext.Provider>
  );
};

export const useKeywordsIdeasCache = () => {
  const context = useContext(KeywordsIdeasCacheContext);
  if (!context) {
    throw new Error('useKeywordsIdeasCache must be used within a KeywordsIdeasCacheProvider');
  }
  return context;
};