import React from 'react';
import gsap from 'gsap';
import { useTranslation } from 'react-i18next';
// https://github.com/greensock/GSAP/issues/398
import { TextPlugin } from 'gsap/dist/TextPlugin';
import { Container, Typography } from '@mui/material';

import './SEOAnimation.scss';

gsap.registerPlugin(TextPlugin);

const SEOAnimation = () => {
  const animationRef = React.useRef(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    const tl = gsap.timeline();

    tl.to('.text-box', { text: t('routes.projects'), duration: 2, delay: 3 })
      .to('.text-box', { text: t('routes.ideas'), duration: 2, delay: 3 })
      .to('.text-box', { text: t('routes.niche-ideas'), duration: 2, delay: 3 })
      .to('.text-box', { text: t('routes.content-gap'), duration: 2, delay: 3 });
    tl.repeat(-1);
  }, []);

  return (
    <Container maxWidth="lg" className="animation-container">
      <div className="seo-animation" ref={animationRef}>
        <div className="chart">
          <div className="chart-bar"></div>
        </div>
        <Typography className="text-box">{t('discover-the-possibilities-of-rankerfy')}</Typography>
      </div>
    </Container>
  );
};

export default SEOAnimation;
