import './styles.scss';
import React, { ChangeEvent, KeyboardEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from './Textarea';
import ButtonSearch from '../ButtonSearch/ButtonSearch';
import CountryWithCodeSelector from '../CountryWithCodeSelector';
import { useSearchCache } from '../../context/SearchCacheContext';
import { LOCATION_CODE_ES } from '../Utils/Constants';

interface Props extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onClick' | 'onChange' | 'value'> {
  loading: boolean;
  onChangeLocation: (location: number) => void;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  value: string;
  onChange: (value: string) => void;
  cacheKey: string;
}

const TextareaWithCountry: React.FC<Props> = ({ loading, onClick, onChangeLocation, value, onChange, cacheKey, ...props }) => {
  const { t } = useTranslation();
  const { getSearchCache, updateSearchCache, getLocation, updateLocation } = useSearchCache();

  const initialCacheValue = getSearchCache(`${cacheKey}_input`) ?? value;
  const initialCacheLocation = getLocation(cacheKey) ?? LOCATION_CODE_ES;

  const [inputValue, setInputValue] = useState(initialCacheValue);
  const [location, setLocation] = useState<number>(initialCacheLocation);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    onChangeLocation(location);
  }, [location, onChangeLocation]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
    updateSearchCache(`${cacheKey}_input`, newValue);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault();
      const lines = inputValue.split('\n').filter(Boolean);

      let newValue = '';

      if (lines.length === 0) {
        newValue = `1. ${inputValue}`;
      } else {
        newValue = lines
          .map((line, index) => {
            if (line.trim().match(/^\d+\.\s/)) {
              return line;
            }
            return `${index + 1}. ${line}`;
          })
          .join('\n') + `\n${lines.length + 1}. `;
      }

      setInputValue(newValue);
      onChange(newValue);
      updateSearchCache(`${cacheKey}_input`, newValue);
    }
  };

  const handleLocationChange = (val: { location: number }) => {
    setLocation(val.location);
    updateLocation(cacheKey, val.location);
    onChangeLocation(val.location);
  };

  return (
    <div className="flex gap-2 w:full" id="modeSelectorType">
      <Textarea
        placeholder={t('button.search')}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <CountryWithCodeSelector only onChange={handleLocationChange} initialLocation={location} />
      <ButtonSearch title={t('button.search')} onClick={onClick} loading={loading} />
    </div>
  );
};

export default TextareaWithCountry;