import { format, subMonths } from 'date-fns';

export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';
export const NUMBER_MAX = 'MAX';
export const NUMBER_MIN = 'MIN';
export const KEYWORD_DIFFICULTY_CLASS_VERY_EASY = 'circle circle-very-easy';
export const KEYWORD_DIFFICULTY_CLASS_EASY = 'circle circle-easy';
export const KEYWORD_DIFFICULTY_CLASS_POSSIBLE = 'circle circle-possible';
export const KEYWORD_DIFFICULTY_CLASS_DIFFICULT = 'circle circle-difficult';
export const KEYWORD_DIFFICULTY_CLASS_HARD = 'circle circle-hard';
export const KEYWORD_DIFFICULTY_CLASS_VERY_HARD = 'circle circle-very-hard';
export const KEYWORD_DIFFICULTY_MAX_VALUE_VERY_EASY = 14;
export const KEYWORD_DIFFICULTY_MAX_VALUE_EASY = 29;
export const KEYWORD_DIFFICULTY_MAX_VALUE_POSSIBLE = 49;
export const KEYWORD_DIFFICULTY_MAX_VALUE_DIFFICULT = 69;
export const KEYWORD_DIFFICULTY_MAX_VALUE_HARD = 79;
export const KEYWORD_DIFFICULTY_MAX_VALUE_VERY_HARD = 100;
export const LIMIT_SCREEN_INNER_WIDTH = 1024;
export const BREADCRUMB_ENTER_DELAY = 2000;
export const COUNTRY_SELECT_OPTION_DEFAULT_WIDTH = 60;
export const COUNTRY_SELECT_OPTION_FONT_SIZE = 9.6;
export const COUNTRY_SELECT_OPTION_MARGIN = 6;
export const COLUMN_URLS_NAME = 'url';
export const COLUMN_COMPARE_URLS_NAME = 'compare_url';
export const COLUMN_DOMAIN_NAME = 'domain';
export const COLUMN_ITEM_TYPES_NAME = 'serp_info.item_types';
export const COLUMN_INTENT_INFO_NAME = 'search_intent_info.intent';
export const COLUMN_DIFFICULTY_NAME = 'extra.keyword_difficulty';
export const COLUMN_SEARCH_VOLUMEN = 'keyword_info.search_volume';
export const COLUMN_HISTORY_TREND_NAME = 'history_trend';
export const COLUMN_HISTORY_NAME = 'keyword_info.history';
export const COLUMN_SERP_RANK_ABSOLUTE = 'serp_info.serp.rank_absolute';
export const COLUMN_SERP_URL = 'serp_info.serp.url';
export const COLUMN_CONTAINS_KEYWORD = 'contains_keyword';
export const IS_LOW_BY_CLICKS = 'is_low_by_clicks';
export const COLUMN_POSITION_NAME = 'position';
export const COLUMN_DOMAIN_POSITION_NAME = 'serp_info.serp.position';
export const COLUMN_KEYWORDS_NAME = 'keyword';
export const COLUMN_ACTIONS_NAME = 'actions';
export const KEY_ENTER_NAME = 'Enter';
export const KEY_COMMAN_NAME = ',';
export const FIRST_COUNTRY = 'ES';
export const DEFAULT_CURRENCY = '€';
export const PAGE_SIZE = 10;
export const ANSWER_TYPE_ANSWER = 'answer';
export const ANSWER_TYPE_TABLE = 'table';
export const ANSWER_TYPE_UNORDERED_LIST = 'unordered_list';
export const COLOR_MODE_LIGHT = 'light';
export const COLOR_MODE_DARK = 'dark';
export const APP_NAME = 'Rankerfy';
export const LOCATION_CODE_ES = 2724;
export const BASE_URL = 'https://rankerfy.com';
export const FORMAT_DATE_RANGE = 'yyyy-MM-dd';
export const NOREFERRER = 'noopener noreferrer';
export const TOKEN_PROJECT = 'PROJECT_LISTS';

export enum ROUTES {
  HOME = '/',
  ACCOUNT = '/account/',
  ACCOUNT_BILLING = '/account/billings/',
  ACCOUNT_CONNECTIONS = '/account/connections/',
  ACCOUNT_MESSING_AROUND = '/account/messing-around/',
  ACCOUNT_SETTINGS = '/account/settings/',
  ACCOUNT_SUBS_INFO = '/account/subscription-info/',
  BATCH_ANALYSIS = '/keywords/bulk-analysis/',
  BUY_PLAN_USER = '/buy-plan/',
  DOMAIN_BACKLINKS = '/domain/backlinks/',
  DOMAIN_BULK_ANALYSIS = '/domain/bulk-analysis/',
  DOMAIN_COMPARATOR = '/domain/content-gap/',
  DOMAIN_COMPETITORS = '/domain/competitors/',
  DOMAIN_KEYWORDS = '/domain/keywords/',
  DOMAIN_VIEW = '/domain/view/',
  DOMAINS = '/domain/',
  EXPLORE = '/keywords/explorer/',
  IDEAS = '/keywords/ideas/',
  KEYWORDS = '/keywords/',
  PROJECTS = '/projects/',
  PROJECTS_CANNIBALIZATION = '/projects/cannibalization/',
  PROJECTS_CONTENT = '/projects/thin-content/',
  PROJECTS_MANAGE = '/projects/manage/',
  PROJECTS_OPPOTUNITY = '/projects/opportunity-keywords/',
  PROJECTS_DETAIL = '/projects/performance/detail/:domain',
  PROJECTS_PERFORMANCE = '/projects/performance/',
  SUBSCRIPTION = '/subscription/',
  THANKS = '/thanks/',
  TRENDS = '/keywords/trends/',
  NICHE_KEYWORDS = '/niche/keywords/',
  NICHE_DOMAINS = '/niche/domains/',
  NICHE_IDEAS = '/niche/'
}

export enum SweetAlertIcon {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  QUESTION = 'question'
}

export const CONTENT_GAP_URL_COLORS = Object.freeze({
  0: '#59ddaa',
  1: '#1dbfff',
  2: '#b046e8',
  3: '#ffb641'
});

export const DEFAULT_VALUES_FILTER = Object.freeze({
  from: 0,
  to: 0
});

export const MOCK_DATA_KEYWORDS_SERVICES = Object.freeze({
  items: [],
  avgCpc: undefined,
  totalKeywords: undefined,
  totalSearchVolume: undefined,
  avgKeywordDifficulty: undefined
});

export const DOMAIN_BUBLE_CHART_COLUMNS = {
  title: 'domain',
  tooltip: [
    { dataKey: 'domain' },
    { title: 'traffic', dataKey: 'traffic' },
    { title: 'total_keywords', dataKey: 'total_keywords' },
    { title: 'traffic_cost', dataKey: 'traffic_cost' }
  ],
  columns: [
    { axe: 'y', name: 'traffic', group: 'domain' },
    { axe: 'x', name: 'total_keywords', group: 'domain' }
  ],
  colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink']
};

export const TOPLEVELDOMAIN = [
  '.net',
  '.gov',
  '.com',
  '.es',
  '.info',
  '.mil',
  '.org',
  '.edu'
] as const;

export const ALL_FILTER_MODE_IDEAS = ['all', 'questions', 'related'] as const;
export const FILTER_MODE_MAPPING = {
  'related': 'kws',
  'all': 'all',
  'questions': 'questions'
};

export const ALL_FILTER_MODE_CONTENT_GAP = ['common', 'missing', 'unique'] as const;
export const ALL_FILTER_MODE_OPPORTUNITY = ['all', 'included', 'not_included'] as const;

export const FILTER_TYPE_CONTENT_GAP = {
  [ALL_FILTER_MODE_CONTENT_GAP[0]]: 0,
  [ALL_FILTER_MODE_CONTENT_GAP[1]]: 1,
  [ALL_FILTER_MODE_CONTENT_GAP[2]]: 1
};

export const topLevelDomainOptions = TOPLEVELDOMAIN.map((key) => ({
  value: key,
  label: key
}));

export const clicksOfSearchesOptions = [
  {
    value: 10,
    label: '0-10'
  },
  {
    value: 50,
    label: '11-50'
  },
  {
    value: 150,
    label: '51-150'
  },
  {
    value: 500,
    label: '151-500'
  },
  {
    value: 2500,
    label: 'más de 500'
  }
];

export const potitionOfSearchesOptions = [
  {
    value: 10,
    label: '0-10'
  },
  {
    value: 30,
    label: '11-30'
  },
  {
    value: 70,
    label: '31-70'
  },
  {
    value: 90,
    label: '71-90'
  },
  {
    value: 100,
    label: '91-100'
  }
];

export const CRTOfSearchesOptions = [
  {
    value: 1,
    label: '0-1'
  },
  {
    value: 5,
    label: '1-5'
  },
  {
    value: 10,
    label: '5-10'
  },
  {
    value: 15,
    label: '10-15'
  },
  {
    value: 25,
    label: 'más de 15'
  }
];

export const AREAS_DOMAINS_SUMS = [
  {
    xaxisDataKey: 'month',
    areasList: [
      { dataKey: 'keywords_count', stroke: '#922EB2', fill: '#EBD3F3' },
      { dataKey: 'traffic_sum', stroke: '#46A5D8', fill: '#D9EBF9' },
      { dataKey: 'price_sum', stroke: '#DB6B85', fill: '#FDE0E5' }
    ]
  }
];

export const AREAS_DOMAINS_TOP_POSITIONS = [
  {
    xaxisDataKey: 'month',
    areasList: [
      { dataKey: 'top1_5', stroke: '#ffc658', fill: '#fff1d5' },
      { dataKey: 'top6_10', stroke: '#46A5D8', fill: '#D9EBF9' },
      { dataKey: 'top11_20', stroke: '#DB6B85', fill: '#FDE0E5' },
      { dataKey: 'top21_50', stroke: '#0bbd8d', fill: '#74BBA8' },
      { dataKey: 'top51_100', stroke: '#922EB2', fill: '#EBD3F3' }
    ]
  }
];

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const DEFAULT_VALUE_RANGE = Object.freeze({
  key: 'selection',
  endDate: new Date(),
  startDate: subMonths(new Date(), 1)
});

export const DEFAULT_DATE_RANGE = Object.freeze({
  endDate: format(new Date(), FORMAT_DATE_RANGE),
  startDate: format(subMonths(new Date(), 1), FORMAT_DATE_RANGE)
});
