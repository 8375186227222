export const PLAN_ROOKIE = 'ROOKIE';
export const PLAN_ROOKIE_PLUS = 'ROOKIE_PLUS';
export const PLAN_ADVANCED = 'ADVANCED';
export const PLAN_EXPERT = 'EXPERT';
export const PLAN_BETA = 'BETA';
export const PLAN_LIFETIME = 'LIFETIME';

export const ALL_PLANS = [
  PLAN_BETA,
  PLAN_ROOKIE,
  PLAN_EXPERT,
  PLAN_ADVANCED,
  PLAN_ROOKIE_PLUS,
  PLAN_LIFETIME
] as const;

export const PER_YEAR_OR_MONTH = ['yearly', 'monthly'] as const;

export type AllPlansUnionType = (typeof ALL_PLANS)[number];
export type PerYearOrMonthUnionType = (typeof PER_YEAR_OR_MONTH)[number];
