import React from 'react';

import { LOCATION_CODE_ES } from '../../components/Utils/Constants';

import { Grid } from '@mui/material';
import Search from '../../components/Search';
import PaperItem from '../../components/Essential/PaperItem';
import { TrendsChart } from '../../components/Trends/TrendsCharts';

export const Trends = () => {
  const [location, setLocation] = React.useState<number>(LOCATION_CODE_ES);

  const handleSearchBtn = async (search: string) => {
    if (search.length === 0) return;
  };

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Search loading={false} onSearch={handleSearchBtn} onCountry={(v) => setLocation(v)} />
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <TrendsChart />
        </PaperItem>
      </Grid>
    </Grid>
  );
};
