import React from 'react';
import { useTranslation } from 'react-i18next';

interface States {
  onClose: () => void;
}

const MenuSelectorContext = React.createContext<States>({} as States);

const useMenuSelectorContext = () => {
  const { t } = useTranslation();
  const context = React.useContext(MenuSelectorContext);
  if (!context) throw new Error(t('componentMustBeInside'));
  return context;
};

export { MenuSelectorContext, useMenuSelectorContext };
