import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../Utils/Constants';
import { classNames } from '../Utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

interface Props {
  keyword: string;
  db?: string | number;
}

const KeywordRedirectLink: React.FC<Props> = ({ keyword, db }) => {
  const { mode } = useColorModeContext();
  const href = `${ROUTES.EXPLORE}?q=${keyword}&db=${db}`;
  return (
    <Link to={href} relative="path" replace className={classNames('btn-link keyword', mode)}>
      {keyword}
    </Link>
  );
};

export default KeywordRedirectLink;
