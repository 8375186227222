import './Search.css';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, InputBase, IconButton, CircularProgress } from '@mui/material';
import CountryWithCodeSelector from '../CountryWithCodeSelector/CountryWithCodeSelector';
import { useSearchCache } from '../../context/SearchCacheContext';
import useDebouncedEffect from '../../hooks/useDebouncedEffect';

interface Props {
  loading?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onSearch?: (values: string) => void;
  onChange?: (values: string) => void;
  onCountry?: (country: number) => void;
  cacheKey?: string;
  allowEmptySearches?: boolean;
}

const Search: React.FC<Props> = ({
  loading,
  onChange,
  onSearch,
  onCountry,
  placeholder,
  defaultValue,
  cacheKey = 'default',
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { getSearchCache, getLocation, updateSearchCache, updateLocation, getAllCacheContext } = useSearchCache();

  const [inputValue, setInputValue] = useState<string>(getSearchCache(cacheKey) || defaultValue || '');
  const [initialLocation, setInitialLocation] = useState<number>(getLocation(cacheKey));
  
  useEffect(() => {
    const keywords = searchParams.get('q');
    if (keywords && keywords !== inputValue) {
      setInputValue(keywords);
    }
  }, [searchParams]);

  useEffect(() => {
    const db = searchParams.get('db');
    if (db) {
      const dbNumber = parseInt(db, 10);
      setInitialLocation(dbNumber);
      updateLocation(cacheKey, dbNumber);
    }
  }, [searchParams, updateLocation, cacheKey]);

  useDebouncedEffect(() => {
    updateSearchCache(cacheKey, inputValue);
  }, [inputValue, updateSearchCache, cacheKey], 500);

  const logCacheData = useCallback(() => {
  }, [getAllCacheContext]);

  const onBtnSearch = useCallback(() => {
    if (onSearch) {
      onSearch(inputValue);
      updateSearchCache(cacheKey, inputValue);
      logCacheData();
    }
  }, [inputValue, onSearch, updateSearchCache, cacheKey, logCacheData]);

  const onBtnClearClick = useCallback(() => {
    if (onSearch) {
      onSearch('');
      updateSearchCache(cacheKey, '');
      setInputValue('');
      logCacheData();
    }
  }, [onSearch, updateSearchCache, cacheKey, logCacheData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (onChange) {
      onChange(value);
    }
    setInputValue(value);
  };

  const onBtnEnterSearch = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onSearch?.(event.currentTarget.value);
      updateSearchCache(cacheKey, event.currentTarget.value);
      logCacheData();
    }
  };

  const handleCountryChange = (location: number) => {
    setInitialLocation(location);
    updateLocation(cacheKey, location);
    if (onCountry) {
      onCountry(location);
    }
  };

  return (
    <div className="search">
      {onCountry && (
        <>
          <CountryWithCodeSelector initialLocation={initialLocation} onChange={(e) => handleCountryChange(e.location)} />
          <Divider sx={{ height: 45, m: 0 }} orientation="vertical" />
        </>
      )}
      <InputBase
        disabled={loading}
        sx={{ ml: 1, flex: 1 }}
        onKeyUp={onBtnEnterSearch}
        value={inputValue}
        placeholder={placeholder ?? t('button.search')}
        onChange={handleInputChange}
      />
      <IconButton
        type="button"
        aria-label="search"
        title={t('button.clear')}
        onClick={onBtnClearClick}
        sx={{ p: '5px', borderRadius: 0 }}
        style={{ opacity: inputValue ? 1 : 0 }}>
        <ClearIcon />
      </IconButton>
      <IconButton
        type="button"
        disabled={loading}
        aria-label="search"
        className="clearBtn"
        onClick={onBtnSearch}
        data-testid="search-button"
        title={t('button.search')}>
        {loading ? (
          <CircularProgress
            size={20}
            color="inherit"
            data-testid="loading-indicator"
            sx={{ bgcolor: 'transparent', color: 'white' }}
          />
        ) : (
          <SearchIcon sx={{ height: '25px' }} />
        )}
      </IconButton>
    </div>
  );
};

export default Search;