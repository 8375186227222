import React from 'react';
import { format } from 'date-fns';
import { Range } from 'react-date-range';

import { DateRangeType } from '../types/common.type';
import { DEFAULT_DATE_RANGE, FORMAT_DATE_RANGE } from '../components/Utils/Constants';

export default function useRangesDate() {
  const [rangesDate, setRangesDate] = React.useState<DateRangeType>(DEFAULT_DATE_RANGE);

  const handleChangeRanges = (date: Range) => {
    const ranges = {
      endDate: format(date.endDate as Date, FORMAT_DATE_RANGE),
      startDate: format(date.startDate as Date, FORMAT_DATE_RANGE)
    };
    setRangesDate(ranges);
  };

  return { rangesDate, handleChangeRanges };
}
