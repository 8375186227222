import React, { useState, useEffect, useCallback, FC } from 'react';
import { Grid, useMediaQuery, IconButton, Box, Typography, Skeleton, Collapse, Tooltip } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ColumnSelector from '../../components/Selectors/ColumnSelector/ColumnSelector';
import DateRangeSelector from '../../components/Selectors/DateRangeSelector/DateRangeSelector';
import { DateRange } from '../../components/Selectors/types';
import { useProjectsData } from '../../hooks/useProjectsData';
import SearchConsoleChart from '../../components/Charts/SearchConsoleChart';
import PaperItem from '../../components/Essential/PaperItem';
import GlobalMetricToggle from '../../components/Charts/SearchConsoleChart/GlobalMetricToggle/GlobalMetricToggle';
import ChartHeader from '../../components/Charts/SearchConsoleChart/ChartHeader/ChartHeader';
import { useUserContext } from '../../context/UserContext';

const cleanUrl = (url: string) => url
  .replace(/^(?:https?:\/\/)?(?:www\.)?(?:sc-domain:)?/, '')
  .replace(/\/$/, '');

const useMobileView = () => useMediaQuery('(max-width: 600px)');
const initialMetrics = { impressions: true, clicks: true, ctr: false, position: false };

const renderLoadingSkeleton = (columns: number, blurTitle: boolean) => Array.from({ length: columns }, (_, i) => (
  <Grid item xs={12 / columns} key={i}>
    <PaperItem sx={{ p: 3 }}>
      <ChartHeader project={{ url: '' }} blurTitle={blurTitle} loading />
      <Skeleton variant="rectangular" height={200} sx={{ mt: 2 }} />
    </PaperItem>
  </Grid>
));

export const ProjectsPerformance: FC = () => {
  const { t } = useTranslation();
  const { fetchProjectsPerformance, dataPerformance, loadingPerformance, fetchProjectInfo } = useProjectsData();
  const { blur, toggleBlur } = useUserContext();
  const [columns, setColumns] = useState(2);
  const [customDomainData, setCustomDomainData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>({ startDate: '', endDate: '' });
  const [globalMetrics, setGlobalMetrics] = useState(initialMetrics);
  const [favoriteProjects, setFavoriteProjects] = useState<Set<string>>(new Set());
  const [showFavorites, setShowFavorites] = useState(true);
  const [showNonFavorites, setShowNonFavorites] = useState(true);
  const isMobile = useMobileView();

  useEffect(() => {
    const storedFavorites = localStorage.getItem('favoriteProjects');
    const storedShowFavorites = localStorage.getItem('showFavorites');
    const storedShowNonFavorites = localStorage.getItem('showNonFavorites');
    if (storedFavorites) {
      setFavoriteProjects(new Set(JSON.parse(storedFavorites)));
    }
    if (storedShowFavorites !== null) {
      setShowFavorites(JSON.parse(storedShowFavorites));
    }
    if (storedShowNonFavorites !== null) {
      setShowNonFavorites(JSON.parse(storedShowNonFavorites));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('favoriteProjects', JSON.stringify(Array.from(favoriteProjects)));
  }, [favoriteProjects]);

  useEffect(() => {
    localStorage.setItem('showFavorites', JSON.stringify(showFavorites));
    localStorage.setItem('showNonFavorites', JSON.stringify(showNonFavorites));
  }, [showFavorites, showNonFavorites]);

  useEffect(() => {
    if (isMobile) setColumns(1);
  }, [isMobile]);

  const toggleFavorite = useCallback((projectUrl) => {
    setFavoriteProjects(prev =>
      new Set(prev.has(projectUrl) ? [...prev].filter(url => url !== projectUrl) : [...prev, projectUrl])
    );
  }, []);

  const fetchAndSetPerformanceData = useCallback(async () => {
    if (!dateRange.startDate || !dateRange.endDate) return;
    try {
      await fetchProjectsPerformance({ startDate: dateRange.startDate, endDate: dateRange.endDate });
    } catch (error) {
      console.error('Error fetching performance data:', error);
    }
  }, [fetchProjectsPerformance, dateRange]);

  const processPerformanceData = useCallback(() => {
    if (!dataPerformance) return;
    type AccumulatorType = {
      date: string;
      [key: string]: number | string;
    };
    const domainData = dataPerformance.items.flatMap(({ url, data }) =>
      data.map(({ keys, impressions }) => ({ url: cleanUrl(url), date: keys[0], impressions }))
    ).reduce((acc: AccumulatorType[], { url, date, impressions }) => {
      const entry = acc.find((e) => e.date === date);
      entry ? (entry[url] = impressions) : acc.push({ date, [url]: impressions });
      return acc;
    }, []);
    setCustomDomainData(domainData);
  }, [dataPerformance]);

  useEffect(() => {
    fetchAndSetPerformanceData();
  }, [fetchAndSetPerformanceData]);

  useEffect(() => {
    if (!loadingPerformance) processPerformanceData();
  }, [loadingPerformance, processPerformanceData]);

  const renderProjectChart = (projectUrl: string, fullUrl: string, numberOfColumns: number, isFavorite: boolean) => {
    const searchData = dataPerformance?.items.filter(item => cleanUrl(item.url) === projectUrl)
      .flatMap(item => item.data.map(data => ({ date: data.keys[0], ...data, originalUrl: item.url }))) || [];

    return (
      <SearchConsoleChart
        key={projectUrl}
        project={{ id: fullUrl, url: projectUrl, property: fullUrl, originalUrl: fullUrl }}
        isGeneralView={columns > 1 && !isMobile}
        searchData={searchData}
        showDots={false}
        showLegend={true}
        blurTitle={blur}
        showChartHeader={true}
        showSummaryCards={columns === 1 && !isMobile}
        metrics={globalMetrics}
        numberOfColumns={numberOfColumns}
        isFavorite={isFavorite}
        onFavoriteToggle={() => toggleFavorite(fullUrl)}
        fetchProjectInfo={fetchProjectInfo}
      />
    );
  };

  const handleGlobalMetricToggle = (metric: string) => setGlobalMetrics(prev => ({ ...prev, [metric]: !prev[metric] }));

  const favoriteUrls = Array.from(favoriteProjects);
  const nonFavoriteUrls = dataPerformance?.items.map(item => item.url).filter(url => !favoriteProjects.has(url)) || [];
  const hasFavorites = favoriteUrls.length > 0;

  const renderSection = (title: string, urls: { cleanedUrl: string, fullUrl: string }[], show: boolean, setShow: (show: boolean) => void) => (
    <>
      {hasFavorites && (
        <PaperItem sx={{ mb: 0, p: 0, mt: 3 }}>
          <Box display="flex" alignItems="center" onClick={() => setShow(!show)} sx={{ cursor: 'pointer', mb: 0, p: 2 }}>
            <Typography variant="h6">{title}</Typography>
            <IconButton>{show ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Box>
        </PaperItem>
      )}
      <Collapse in={show}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {urls.map(({ cleanedUrl, fullUrl }) => (
            <Grid item xs={12 / columns} key={cleanedUrl}>
              {renderProjectChart(cleanedUrl, fullUrl, columns, title === t('favoriteProjects'))}
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );

  const favoriteProjectData = favoriteUrls.map(url => ({ cleanedUrl: cleanUrl(url), fullUrl: url }));
  const nonFavoriteProjectData = nonFavoriteUrls.map(url => ({ cleanedUrl: cleanUrl(url), fullUrl: url }));

  return (
    <>
      <PaperItem sx={{ p: 3, mb: 2 }}>
        <Box mb={0} display="flex" justifyContent="flex-end" alignItems="center" gap={0.5}>
          <GlobalMetricToggle metrics={globalMetrics} onToggle={handleGlobalMetricToggle} />
          <Tooltip title={blur ? t('domains_hidden') : t('domains_visible')}>
            <IconButton sx={{ padding: 0 }} onClick={toggleBlur} aria-label="toggle blur">
              {blur ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
          {!isMobile && <ColumnSelector columns={columns} onChange={setColumns} />}
          <DateRangeSelector onChange={setDateRange} />
        </Box>
      </PaperItem>
      {loadingPerformance ? (
        <>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <PaperItem sx={{ p: 3 }}>
                <Skeleton variant="rectangular" height={40} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" height={100} />
              </PaperItem>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {renderLoadingSkeleton(columns, blur)}
          </Grid>
        </>
      ) : (
        <>
          <SearchConsoleChart
            project={{ id: 'General', url: 'General Overview', property: 'General Overview' }}
            isGeneralView={false}
            isCustomDomain={true}
            searchData={customDomainData}
            showDots={false}
            showLegend={true}
            blurTitle={blur}
            showChartHeader={false}
            showSummaryCards={false}
            showCustomDots={!isMobile}
            metrics={globalMetrics}
            numberOfColumns={columns}
            isFavorite={false}
          />
          {favoriteProjectData.length > 0 && renderSection(t('favoriteProjects'), favoriteProjectData, showFavorites, setShowFavorites)}
          {(hasFavorites || nonFavoriteProjectData.length > 0) && renderSection(t('otherProjects'), nonFavoriteProjectData, showNonFavorites, setShowNonFavorites)}
        </>
      )}
    </>
  );
};