const predefinedColors = [
    "#9061f9", "#ff8e00", "#ffd500", "#e2ff00", "#9cff00",
    "#4fff00", "#08ff00", "#00ff3f", "#00ff86", "#00ffcd",
    "#00e5ff", "#009eff", "#0057ff", "#0010ff", "#3700ff",
    "#8400ff", "#cb00ff", "#ff00ed", "#ff00a6", "#ff005f",
  ];

  
export const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  
  export const generateColorList = (n: number): string[] => {
    const colors = [...predefinedColors];
    while (colors.length < n) {
      colors.push(generateRandomColor());
    }
    return colors;
  };

export const normalizeData = (data, key, invert = false) => {
  const values = data.map(d => d[key]);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const normalizedData = data.map(d => {
    const normalizedValue = ((d[key] - min) / (max - min)) * 99 + 1;
    return { ...d, normalizedValue: invert ? 100 - normalizedValue : normalizedValue };
  });
  return normalizedData;
};

export const processData = (searchData, isCustomDomain, colorCache) => {
  searchData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  let normalizedSearchData = [], minCustom = Infinity, maxCustom = -Infinity;
  let minClicks = Infinity, maxClicks = -Infinity;
  let minCtr = Infinity, maxCtr = -Infinity;
  let minPosition = Infinity, maxPosition = -Infinity;
  let minImpressions = Infinity, maxImpressions = -Infinity;

  const adjustDomain = (value) => {
    minCustom = Math.min(value, minCustom);
    maxCustom = Math.max(value, maxCustom);
  };

  const adjustClickDomain = (value) => {
    minClicks = Math.min(value, minClicks);
    maxClicks = Math.max(value, maxClicks);
  };

  const adjustCtrDomain = (value) => {
    minCtr = Math.min(value, minCtr);
    maxCtr = Math.max(value, maxCtr);
  };

  const adjustPositionDomain = (value) => {
    minPosition = Math.min(value, minPosition);
    maxPosition = Math.max(value, maxPosition);
  };

  const adjustImpressionsDomain = (value) => {
    minImpressions = Math.min(value, minImpressions);
    maxImpressions = Math.max(value, maxImpressions);
  };

  let allDomains = [];
  if (isCustomDomain) {
    allDomains = [...new Set(searchData.flatMap(entry => Object.keys(entry).filter(key => key !== 'date')))];
  }

  if (isCustomDomain) {
    normalizedSearchData = searchData.map((entry) => {
      const normalizedEntry = { date: entry.date };
      allDomains.forEach((domain, i) => {
        const value = entry[domain] !== undefined ? entry[domain] : 0;
        adjustDomain(value);
        if (domain === 'clicks') {
          adjustClickDomain(value);
        }
        if (domain === 'ctr') {
          adjustCtrDomain(value);
        }
        if (domain === 'position') {
          adjustPositionDomain(value);
        }
        if (domain === 'impressions') {
          adjustImpressionsDomain(value);
        }
        normalizedEntry[domain] = value;
        if (!colorCache.current[domain]) colorCache.current[domain] = generateColorList(20)[i];
      });
      return normalizedEntry;
    });
  } else {
    const datasets = ['impressions', 'clicks', 'ctr', 'position'].reduce((acc, key) => {
      acc[key] = normalizeData(searchData, key, key === 'position').map(d => d.normalizedValue);
      return acc;
    }, {} as { [key: string]: number[] });

    normalizedSearchData = searchData.map((d, i) => ({
      date: d.date,
      impressions: datasets.impressions[i],
      clicks: datasets.clicks[i],
      ctr: datasets.ctr[i],
      position: datasets.position[i],
      originalImpressions: d.impressions,
      originalClicks: d.clicks,
      originalCtr: d.ctr,
      originalPosition: d.position,
    }));

    ['clicks', 'impressions', 'ctr', 'position'].forEach(key => {
      const minKey = Math.min(...searchData.map(d => d[key]));
      const maxKey = Math.max(...searchData.map(d => d[key]));
      adjustDomain(minKey);
      adjustDomain(maxKey);

      if (key === 'clicks') {
        adjustClickDomain(minKey);
        adjustClickDomain(maxKey);
      }

      if (key === 'ctr') {
        adjustCtrDomain(minKey);
        adjustCtrDomain(maxKey);
      }

      if (key === 'position') {
        adjustPositionDomain(minKey);
        adjustPositionDomain(maxKey);
      }

      if (key === 'impressions') {
        adjustImpressionsDomain(minKey);
        adjustImpressionsDomain(maxKey);
      }
    });
  }

  return { normalizedSearchData, minCustom, maxCustom, minClicks, maxClicks, minCtr, maxCtr, minPosition, maxPosition, minImpressions, maxImpressions, allDomains };
};