import React from 'react';
import BarChartTop from '../BarChartTop/BarChartTop';
import { DomainRankRanges } from '../../../types/domainRankRanges.type';

interface RankRangesWrapperProps {
  data: Record<string, number>;
  loading: boolean;
  height?: number;
  width?: number;
}

const RankRangesWrapper = ({ data, loading, height = 50, width = 350 }: RankRangesWrapperProps) => {
  const rankRanges: DomainRankRanges = {
    '1-5': data['1-5'] || 0,
    '6-10': data['6-10'] || 0,
    '11-20': data['11-20'] || 0,
    '21-50': data['21-50'] || 0,
    '51-100': data['51-100'] || 0
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', width: width }}>
      <BarChartTop data={rankRanges} loading={loading} height={height} width={width} />
    </div>
  );
};

export default RankRangesWrapper;