import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GoogleStatus.module.scss';
import logo from '../../../../assets/img/dashboard-logo.svg';
import available from '../../../../assets/img/available.svg';
import information from '../../../../assets/img/information.svg';
import disruption from '../../../../assets/img/disruption.svg';
import outage from '../../../../assets/img/outage.svg';
import { GoogleStatusData, Update, GoogleStatusProps } from '../../types';

const statusIcons = {
  available: (
    <img src={available} alt="Available status" className={styles.available} />
  ),
  information: (
    <img src={information} alt="Informational status" className={styles.information} />
  ),
  disruption: (
    <img src={disruption} alt="Disruption status" className={styles.disruption} />
  ),
  outage: (
    <img src={outage} alt="Outage status" className={styles.outage} />
  )
};

const GoogleStatus: React.FC<GoogleStatusProps> = ({ dataKey }) => {
  const { t } = useTranslation('googleUpdates');
  const data: GoogleStatusData | null = t(dataKey, { returnObjects: true }) as unknown as GoogleStatusData | null;
  const globalData: any = t('global', { returnObjects: true });

  if (!data || !globalData) {
    console.error(`No data found for key: ${dataKey}`);
    return null;
  }

  if (!Array.isArray(data.breadcrumbs)) {
    console.error('data.breadcrumbs is not an array', data.breadcrumbs);
    return null;
  }

  const combinedBreadcrumbs = [...globalData.breadcrumbs, ...data.breadcrumbs];

  return (
    <div className={styles.body}>
      <header className={styles.header}>
        <a href={globalData.headerLink} target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Dashboard Logo" className={styles.logo} />
        </a>
        <div></div>
      </header>

      <main className={styles.main}>
        <ul className={styles.breadcrumbs}>
          {combinedBreadcrumbs.map((breadcrumb, index) => (
            <li key={index} className={styles.breadcrumbItem}>
              <a href={breadcrumb.link} className={styles.breadcrumbLink} target="_blank" rel="noopener noreferrer">
                {breadcrumb.text}
              </a>
              {index < combinedBreadcrumbs.length - 1 && (
                <span className={styles.breadcrumbSeparator}></span>
              )}
            </li>
          ))}
        </ul>

        <div className={styles.headerContainer}>
          <h1 className={styles.dashboardTitle}>{globalData.title}</h1>
        </div>

        <div className={styles.intro}>
          <span>{globalData.intro}</span>
        </div>

        <ul className={styles.documentationList}>
          <li className={styles.documentationItem}>
            {statusIcons.available}
            <span>Available</span>
          </li>
          <li className={styles.documentationItem}>
            {statusIcons.information}
            <span>Service information</span>
          </li>
          <li className={styles.documentationItem}>
            {statusIcons.disruption}
            <span>Service disruption</span>
          </li>
          <li className={styles.documentationItem}>
            {statusIcons.outage}
            <span>Service outage</span>
          </li>
        </ul>

        <h2 className={styles.incidentHeader}>{data.incidentTitle}</h2>
        <p className={styles.incidentDescription}>{data.incidentDescription}</p>
        
        <p className={styles.incidentSummary}>
          Incident began at <strong>{data.incidentStartTime}</strong> and ended at <strong>{data.incidentEndTime}</strong> <span>(all times are <strong>{globalData.timeZone}</strong>).</span>
        </p>

        <table className={`${styles.psdTable} ${styles.statusUpdates}`}>
          <thead>
            <tr>
              <th className={styles.th}></th>
              <th className={styles.th}>Date</th>
              <th className={styles.th}>Time</th>
              <th className={styles.th}>Description</th>
            </tr>
          </thead>
          <tbody>
            {data.updates?.map((update: Update, index: number) => (
              <tr key={index}>
                <td className={`${styles.td} ${styles.status}`}>{statusIcons[update.status]}</td>
                <td className={`${styles.td} ${styles.date}`}>{update.date}</td>
                <td className={`${styles.td} ${styles.time}`}>{update.time}</td>
                <td className={styles.td}>
                  <p className={styles.description}>{update.description}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ul className={styles.footerList}>
          <li>{globalData.footer}</li>
        </ul>
      </main>
    </div>
  );
};

export default GoogleStatus;