import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDomainsData } from '../../hooks/useDomainsData';
import { generateUuid } from '../../components/Utils/Utils';
import * as CONSTANTS from '../../components/Utils/Constants';
import { COLUMNS_DOMAIN_CONTENT_GAP } from '../../components/Utils/TableConstants';

import { Grid, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TableComponent from '../../components/TableComponent';
import PaperItem from '../../components/Essential/PaperItem';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import SearchSimple from '../../components/SearchSimple/SearchSimple';
import ButtonSearch from '../../components/ButtonSearch/ButtonSearch';
import CountryWithCodeSelector from '../../components/CountryWithCodeSelector';
import './Comparator.scss';
import { useSearchCache } from '../../context/SearchCacheContext';

type FilterModeType = (typeof CONSTANTS.ALL_FILTER_MODE_CONTENT_GAP)[number];

export const Comparator = () => {
  const { updateSearchCache, getSearchCache, getLocation, updateLocation, getPagination, updatePagination } = useSearchCache();
  const context = 'comparator';

  const [youDomain, setYouDomain] = useState<string[]>(['']);
  const [competitors, setCompetitors] = useState<string[]>(['']);
  const [location, setLocation] = useState<number>(CONSTANTS.LOCATION_CODE_ES);
  const [competitorsAmount, setCompetitorsAmount] = useState<string[]>(() => {
    const savedAmounts = getSearchCache(context + '_competitorsAmount');
    return savedAmounts ? JSON.parse(savedAmounts) : [generateUuid()];
  });
  const [filterMode, setFilterMode] = useState<FilterModeType>(() => {
    const savedFilterMode = getSearchCache(context + '_filterMode');
    return savedFilterMode ? JSON.parse(savedFilterMode) : CONSTANTS.ALL_FILTER_MODE_CONTENT_GAP[0];
  });
  const [pagination, setPagination] = useState(() => getPagination(context));
  
  const { t } = useTranslation();
  const { loadingContentGap, fetchDomainContentGap, contentGapCommon } = useDomainsData('contentgap');

  const handleModeFilter = (value: FilterModeType) => {
    setFilterMode(value);
    updateSearchCache(context + '_filterMode', JSON.stringify(value));
  };

  useEffect(() => {
    handleSearchBtn();
  }, [filterMode]);

  const handleSetterCompetitors = (value: string, index: number) => {
    setCompetitors((current) => {
      const newCompetitors = [...current];
      newCompetitors[index] = value;
      return newCompetitors;
    });
  };

  const handleYouDomainChange = (value: string, index: number) => {
    setYouDomain((current) => {
      const newYouDomain = [...current];
      newYouDomain[index] = value;
      return newYouDomain;
    });
  };

  const handleSearchBtn = useCallback(async () => {
    if (youDomain.some(y => y.length === 0) || competitors.some(c => c.length === 0)) {
      return;
    }

    let data = {
      location,
      domain: youDomain,
      competitor: competitors,
      diff: CONSTANTS.FILTER_TYPE_CONTENT_GAP[filterMode]
    };

    if (filterMode === 'missing') {
      data = {
        ...data,
        domain: competitors,
        competitor: youDomain
      };
    }

    updateSearchCache(context + '_youDomain_0', youDomain[0]);
    competitors.forEach((comp, index) => {
      updateSearchCache(context + `_competitor_${index}`, comp);
    });
    updateLocation(context, location);
    updatePagination(context, pagination);

    await fetchDomainContentGap(data);
  }, [location, youDomain, filterMode, competitors, fetchDomainContentGap, updateSearchCache, updateLocation, pagination, updatePagination]);

  useEffect(() => {
    const initialYouDomain = getSearchCache(context + `_youDomain_0`) || '';
    const initialCompetitors = competitorsAmount.map((_, index) => getSearchCache(context + `_competitor_${index}`) || '');
    setYouDomain([initialYouDomain]);
    setCompetitors(initialCompetitors);
    const savedLocation = getLocation(context);
    setLocation(savedLocation);
    const savedPagination = getPagination(context);
    setPagination(savedPagination);
    if (filterMode && initialYouDomain && initialCompetitors.some(c => c)) {
      handleSearchBtn();
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(competitorsAmount) !== getSearchCache(context + '_competitorsAmount')) {
      updateSearchCache(context + '_competitorsAmount', JSON.stringify(competitorsAmount));
    }
  }, [competitorsAmount, getSearchCache, updateSearchCache]);

  useEffect(() => {
    const savedPagination = getPagination(context);
    if (pagination.page !== savedPagination.page || pagination.pageSize !== savedPagination.pageSize) {
      updatePagination(context, pagination);
    }
  }, [pagination, updatePagination]);

  const handleRemoveCompetitor = (index: number) => {
    setCompetitorsAmount((prev) => {
      const updated = prev.filter((_, idx) => idx !== index);
      updateSearchCache(context + '_competitorsAmount', JSON.stringify(updated));
      return updated;
    });
    setCompetitors((prev) => prev.filter((_, idx) => idx !== index));
    updateSearchCache(context + `_competitor_${index}`, '');
  };

  const handlePaginationChange = (newPagination) => {
    setPagination(newPagination);
    updatePagination(context, newPagination);
  };

  const columns = COLUMNS_DOMAIN_CONTENT_GAP(t, {
    youDomain: youDomain[0],
    code: location,
    domains: competitors
  });

  const columnVisibilityModel = {
    you_domain: filterMode !== 'missing',
    ...competitors.reduce((acc, _, index) => {
      acc[`domain_${index + 1}`] = filterMode !== 'unique';
      return acc;
    }, {}),
  };

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="flex w-full gap-2 flex-column boxShadow" sx={{ p: 3 }}>
          <div className="flex-col w-full gap-2">
            {youDomain.map((item, index) => (
              <SearchSimple
                key={index}
                pin="#CCEDFF"
                pinText={t('you')}
                onChange={(value) => handleYouDomainChange(value, index)}
                placeholder={t('search.enter_your_domain')}
                pinStyle={{
                  borderRadius: 12,
                  color: '#4BB3FF',
                  padding: '3px 8px',
                  width: 'max-content',
                  height: 'max-content'
                }}
                cacheKey={`${context}_youDomain_${index}`}
              />
            ))}
            {competitorsAmount.map((item, index) => (
              <div className="relative flex" key={item}>
                <SearchSimple
                  key={item}
                  onSearch={handleSearchBtn}
                  pin={CONSTANTS.CONTENT_GAP_URL_COLORS[index]}
                  placeholder={t('search.introduce_a_competitor')}
                  onChange={(c) => handleSetterCompetitors(c, index)}
                  cacheKey={`${context}_competitor_${index}`}
                />
                {index + 1 > 1 && (
                  <button
                    data-remove-item={index}
                    className="btn-clear-competitor"
                    onClick={() => handleRemoveCompetitor(index)}
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-end gap-2 w-full" id="modeSelectorType">
            {competitorsAmount.length < 4 && (
              <Button
                variant="text"
                className="capitalize btn-more-competitors"
                onClick={() => {
                  const newCompetitorId = generateUuid();
                  setCompetitorsAmount((pre) => {
                    const updated = [...pre, newCompetitorId];
                    updateSearchCache(context + '_competitorsAmount', JSON.stringify(updated));
                    return updated;
                  });
                  setCompetitors((pre) => [...pre, '']);
                }}>
                + {t('add_up_to_3_competitors')}
              </Button>
            )}
            <CountryWithCodeSelector
              only 
              onChange={(val) => {
                setLocation(val.location);
                updateLocation(context, val.location);
              }} 
              initialLocation={location}
            />
            <ButtonSearch
              title={t('compare')}
              onClick={handleSearchBtn}
              loading={loadingContentGap}
            />
          </div>
        </PaperItem>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <Typography className="mb-5">{t('routes.content-gap')}</Typography>
          <TableComponent
            rows={contentGapCommon}
            loading={loadingContentGap}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            paginationModel={pagination}
            onPaginationModelChange={handlePaginationChange}
            slots={{
              toolbar: () => (
                <div className="py-2">
                  <ButtonGroup
                    current={filterMode}
                    onChange={handleModeFilter}
                    suggestions={CONSTANTS.ALL_FILTER_MODE_CONTENT_GAP}
                  />
                </div>
              )
            }}
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};