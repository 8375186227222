import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { LOCATION_CODE_ES } from '../components/Utils/Constants';

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface SearchCacheContextType {
  getSearchCache: (key: string) => string;
  updateSearchCache: (key: string, value: string) => void;
  getPagination: (key: string) => PaginationModel;
  updatePagination: (key: string, value: PaginationModel) => void;
  getAllCacheContext: () => object;
  getLocation: (key: string) => number;
  updateLocation: (key: string, value: number) => void;
}

const SearchCacheContext = createContext<SearchCacheContextType | undefined>(undefined);

interface SearchCacheProviderProps {
  children: ReactNode;
}

const LOCAL_STORAGE_KEY = 'searchContext';
const NICHE_IDEAS_SEARCH_CACHE_CONTEXT = 'nicheIdeasSearch';

export const SearchCacheProvider: React.FC<SearchCacheProviderProps> = ({ children }) => {
  const [context, setContext] = useState(() => {
    const storedContext = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedContext ? JSON.parse(storedContext) : {};
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(context));
  }, [context]);

  const getSearchCache = (key: string) => context[key] || '';

  const updateSearchCache = (key: string, value: string) => {
    if (key === NICHE_IDEAS_SEARCH_CACHE_CONTEXT && value !== '') return;

    setContext(prevContext => {
      return {
        ...prevContext,
        [key]: value,
      };
    });
  };

  const getLocation = (key: string) => context[`${key}_location`] || LOCATION_CODE_ES;

  const updateLocation = (key: string, value: number) => {
    if (value === context[`${key}_location`]) return;
    setContext(prevContext => ({
      ...prevContext,
      [`${key}_location`]: value,
    }));
  };

  const getPagination = (key: string) => context[`${key}_pagination`] || { page: 0, pageSize: 10 };

  const updatePagination = (key: string, value: PaginationModel) => {
    setContext(prevContext => ({
      ...prevContext,
      [`${key}_pagination`]: value,
    }));
  };

  const getAllCacheContext = () => context;

  return (
    <SearchCacheContext.Provider
      value={{ getSearchCache, updateSearchCache, getPagination, updatePagination, getAllCacheContext, getLocation, updateLocation }}
    >
      {children}
    </SearchCacheContext.Provider>
  );
};

export const useSearchCache = () => {
  const context = useContext(SearchCacheContext);
  if (!context) {
    throw new Error('useSearchCache must be used within a SearchCacheProvider');
  }
  return context;
};