import { Typography } from '@mui/material';
import React from 'react';

const ResultRankAbsolute =  ({ position }) => {
  return (
    <div>
      <Typography>#{position}</Typography>
    </div>
  );
};

export default ResultRankAbsolute;
