import { VITE_HOST } from './lib/envVariables';
const G_CODE = 'L35LLB4YRZ';

(() => {
  if (VITE_HOST === 'https://app.rankerfy.com') {
    const script = document.createElement('script');
    const scriptUrl = document.createElement('script');

    scriptUrl.async = true;
    scriptUrl.src = `https://www.googletagmanager.com/gtag/js?id=G-${G_CODE}`;

    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-${G_CODE}');
    `;

    window.document.head.appendChild(scriptUrl);
    window.document.head.appendChild(script);
  }
})();
