import React from 'react';
import DomainInternLink from '../DomainInternLink';
import KeywordLinkBlank from '../KeywordLinkBlank';

interface Props {
  url: string;
  domain: string;
}

const SerpUrl:  React.FC<Props> = ({ url, domain }) => {
  return (
    <div>
      <KeywordLinkBlank href={url} />
      <DomainInternLink href={domain} />
    </div>
  );
};

export default SerpUrl;
