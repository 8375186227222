import { useUserContext } from '../context/UserContext';

export default function useRefreshToken() {
  const { getAccessTokenSilently } = useUserContext();

  const refreshToken = async () => {
    return await getAccessTokenSilently({
      cacheMode: 'off',
      authorizationParams: {
        ignoreCache: true,
        grant: 'refresh_token',
        scope: 'openid offline_access'
      }
    });
  };

  return refreshToken;
}
