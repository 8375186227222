import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop({ children }: Readonly<React.PropsWithChildren>) {
  const { pathname } = useLocation();
  React.useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return children;
}
