import React from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { formatNumber } from '../../utils';
import { CustomTooltipProps } from '../../types';
import styles from './CustomTooltip.module.scss';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { toZonedTime, format as formatTz } from 'date-fns-tz';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeDateParts = (dateString) => {
    return dateString.split(' ').map(capitalizeFirstLetter).join(' ');
};

const extractDomain = (url) => {
    return url.replace(/^(https?:\/\/)?(www\.)?/, '');
};

const formatDateWithDay = (date: string | number | Date, locale: any) => {
    const timeZone = 'UTC';
    const zonedDate = toZonedTime(parseISO(date), timeZone);
    const formattedDate = capitalizeDateParts(formatTz(zonedDate, 'EEEE, MMM dd', { locale }));
    return formattedDate;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, isCustomDomain, blurTitle }) => {
    const isMobile = useIsMobile();
    const { i18n } = useTranslation();

    if (active && payload && payload.length && label) {
        let dataFormatted;

        if (isCustomDomain) {
            dataFormatted = payload.map((entry: any) => ({
                icon: null,
                favicon: isMobile ? `https://www.google.com/s2/favicons?sz=64&domain_url=${extractDomain(entry.name)}` : null,
                value: `${formatNumber(entry.value)}`,
                name: entry.name,
                color: entry.color,
            }));
        } else {
            dataFormatted = payload.reduce((acc: any[], entry: any) => {
                if (entry.dataKey === 'impressions') {
                    acc.push({ icon: <RemoveRedEyeIcon style={{ color: '#5e35b1' }} />, value: formatNumber(entry.payload.originalImpressions) });
                } else if (entry.dataKey === 'clicks') {
                    acc.push({ icon: <AdsClickIcon style={{ color: '#4285f4' }} />, value: formatNumber(entry.payload.originalClicks) });
                } else if (entry.dataKey === 'ctr') {
                    const ctrPercentage = entry.payload.originalCtr * 100;
                    acc.push({ icon: <PercentIcon style={{ color: '#00897b' }} />, value: `${formatNumber(ctrPercentage)}%` });
                } else if (entry.dataKey === 'position') {
                    acc.push({ icon: <TrendingUpIcon style={{ color: '#e8710a' }} />, value: formatNumber(entry.payload.originalPosition) });
                }
                return acc;
            }, []);
        }

        const localeMap = {
            en: enLocale,
            es: esLocale,
        };

        const currentLocale = localeMap[i18n.language] || enLocale;

        return (
            <div className={`${styles.customTooltip} ${isMobile ? styles.mobile : ''}`}>
                <p className="label">{formatDateWithDay(label, currentLocale)}</p>
                <div className={styles.tooltipGrid}>
                    {dataFormatted.map((data, index) => (
                        <div key={index} className={styles.tooltipItem}>
                            <span className={styles.tooltipColor} style={{ backgroundColor: data.color }}></span>
                            {isMobile && isCustomDomain && data.favicon ? (
                                <img src={data.favicon} alt="favicon" className={styles.tooltipFavicon} />
                            ) : (
                                data.icon && <span className={styles.tooltipIcon}>{data.icon}</span>
                            )}
                            {isCustomDomain && blurTitle && !isMobile ? (
                                <>
                                    <span className={styles.tooltipBlurTitle}>{data.name}</span>: <span>{data.value}</span>
                                </>
                            ) : (
                                <span>{!isMobile && isCustomDomain ? `${data.name}: ` : ''}{data.value}</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return null;
};

export default CustomTooltip;