import '../KeywordPosition/KeywordPosition.scss';
import React from 'react';

import { Tooltip, Typography } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import StraightIcon from '@mui/icons-material/Straight';

interface Props {
  low_top?: number;
  high_top?: number;
}

const KeywordPositionLevel = ({ low_top, high_top }: Props) => {
  return (
    <Tooltip
      arrow
      followCursor
      title={
        <div>
          <div>High top: {high_top}</div>
          <div>Low top: {low_top}</div>
        </div>
      }>
      <div className="level-position flex gap-1">
        <div className="flex items-center">
          <Typography>{high_top}</Typography>
          <StraightIcon data-testid="up-icon" />
        </div>
        <div className="flex items-center">
          <Typography>{low_top}</Typography>
          <SouthIcon data-testid="down-icon" />
        </div>
      </div>
    </Tooltip>
  );
};

export default KeywordPositionLevel;
