import './styles.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useMenuSelectorContext } from '../../context/MenuSelectorContext';

interface Props {
  toValues?: number;
  fromValues?: number;
  onClick: (values: { from: number; to: number }) => void;
}
const REGEXP = /^[0-9.]*$/;

const BASE_INPUT = {
  min: 0,
  tabIndex: 1,
  type: 'text',
  maxLength: 19,
  placeholder: 'Desde',
  inputMode: 'numeric' as 'numeric',
  className: 'input from',
  pattern: '[0-9]{13,19}'
};

const CustomRange: React.FC<Props> = ({ toValues, fromValues, onClick }) => {
  const [toValue, setToValue] = React.useState<number | string>('');
  const [fromValue, setFromValue] = React.useState<number | string>('');

  const { t } = useTranslation();
  const { onClose } = useMenuSelectorContext();

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => e.stopPropagation();

  const handleFilter = (e) => {
    e.preventDefault();
    onClick({
      to: !isNaN(Number(toValue)) ? Number(toValue) : 0,
      from: !isNaN(Number(fromValue)) ? Number(fromValue) : 0
    });
    onClose();
  };

  React.useEffect(() => {
    if (toValues !== undefined) setToValue(toValues);
  }, [toValues]);

  React.useEffect(() => {
    if (fromValues !== undefined) setFromValue(fromValues);
  }, [fromValues]);

  return (
    <form className="custom-range flex-col gap-2">
      <Typography>{t('custom_range')}</Typography>
      <div className="flex">
        <input
          {...BASE_INPUT}
          value={fromValue !== undefined ? fromValue : ''}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') setFromValue('');
            else if (REGEXP.test(value)) setFromValue(value as unknown as number);
          }}
        />
        <input
          {...BASE_INPUT}
          value={toValue !== undefined ? toValue : ''}
          placeholder="Hasta"
          className="input to"
          onKeyDown={onKeyDown}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') setToValue('');
            else if (REGEXP.test(value)) setToValue(value as unknown as number);
          }}
        />
      </div>
      <Button type="submit" variant="contained" onClick={handleFilter}>
        {t('apply')}
      </Button>
    </form>
  );
};

export default CustomRange;