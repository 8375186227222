import React from 'react';
import { DomainBacklink } from '../../types';
import { Chip, Typography } from '@mui/material';
import KeywordLinkBlank from '../KeywordLinkBlank';
import { generateIcon } from '../../components/Utils/Utils';

const BacklinkPageFromTitle: React.FC<Partial<DomainBacklink>> = ({
  url_from,
  url_to_https,
  page_from_title,
  page_from_language,
  domain_from_platform_type
}) => {
  return (
    <div className="flex flex-col py-2">
      <Typography>{page_from_title}</Typography>
      <KeywordLinkBlank href={url_from as string} />
      <div className="flex gap-1 items-center">
        {page_from_language && <Chip label={page_from_language} />}
        {Array.isArray(domain_from_platform_type) &&
          domain_from_platform_type.length > 0 &&
          domain_from_platform_type.map(
            (element) => element !== 'unknown' && <Chip key={element} label={element} />
          )}
        {url_to_https && (
          <Chip label={generateIcon('LockOutlined', { sx: { mt: 1 }, fontSize: 'small' })} />
        )}
      </div>
    </div>
  );
};

export default BacklinkPageFromTitle;
