import { useState, useCallback } from 'react';
import { userService } from '../services/UserService';
import { NOREFERRER } from '../components/Utils/Constants';
import { BillingsResponse, BuyPlanRequest } from '../types/common.type';
import { useAccessToken } from './useAccessToken';

export const useUsers = () => {
  const [redirectStripe, setRedirectStripe] = useState<string>('');
  const [invoices, setInvoices] = useState<Array<BillingsResponse>>([]);
  const [checkoutData, setCheckoutData] = useState<any>();
  const [loadingRedirectStripe, setLoadingRedirectStripe] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [loadingRetrieveCheckout, setLoadingRetrieveCheckout] = useState(false);
  const [error, setError] = useState<any>(null);
  
  const token = useAccessToken();

  const fetchRedirectStripe = useCallback(async () => {
    setLoadingRedirectStripe(true);
    try {
      const data = await userService.getToRedirectStripe({ accessToken: token });
      window.open(data, '_blank', NOREFERRER);
      setRedirectStripe(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoadingRedirectStripe(false);
    }
  }, [token]);

  const fetchGetInvoice = useCallback(async () => {
    setLoadingInvoices(true);
    try {
      const data = await userService.getInvoices({ accessToken: token });
      setInvoices(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoadingInvoices(false);
    }
  }, [token]);

  const retrieveCheckout = useCallback(async (sessionId: string) => {
    setLoadingRetrieveCheckout(true);
    try {
      const data = await userService.retrieveCheckout({ accessToken: token }, sessionId);
      setCheckoutData(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoadingRetrieveCheckout(false);
    }
  }, [token]);

  const fetchRedirectToCheckoutStripe = useCallback(
    async (request: Omit<BuyPlanRequest, 'accessToken'>) => {
      setLoadingRedirectStripe(true);
      try {
        const data = await userService.redirectToCheckoutStripe({ accessToken: token, ...request });
        window.open(data, '_self', NOREFERRER);
      } catch (e) {
        setError(e);
      } finally {
        setLoadingRedirectStripe(false);
      }
    },
    [token]
  );

  return {
    invoices,
    checkoutData,
    loadingInvoices,
    loadingRedirectStripe,
    loadingRetrieveCheckout,
    redirectStripe,
    fetchGetInvoice,
    fetchRedirectStripe,
    retrieveCheckout,
    fetchRedirectToCheckoutStripe,
    error
  };
};