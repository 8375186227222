import { DomainsContentGapResponse } from '../../types/domainsContentGap.type';

class ContentGapDto {
  total: number;
  items: DomainsContentGapResponse;

  constructor(data: { total: number; items: DomainsContentGapResponse }) {
    this.total = data.total;
    this.items = data.items;
  }
}

export default ContentGapDto;
