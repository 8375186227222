import React from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import { StepOne, StepTwo, StepThree } from '../OnboardingSteps/OnboardingSteps';
import { stepConnectorClasses, styled, StepConnector } from '@mui/material';
import { useSelectedPlan } from '../../context/PlanContext';

const steps = ['Crea tu cuenta', 'Selecciona un plan', 'Paga de forma segura'];

const OnboardingStepsBar = ({ activeStep, setActiveStep, plan, billing, coupon }) => {
  const { selectedPlan, selectedBilling } = useSelectedPlan();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <StepOne />;
      case 1:
        return <StepTwo billing={billing} plan={plan} coupon={coupon} />;
      case 2:
        return <StepThree coupon={coupon} />;
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ mt: 4, mb: 1 }}>
            {getStepContent(activeStep)}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pb: 10 }}>
            <Button color="inherit" disabled={activeStep === 0||activeStep === 1} onClick={handleBack} sx={{ mr: 1 }}variant="outlined">
              Atrás
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* if button if disable tooltip */}
            <Button onClick={handleNext} disabled={selectedPlan == null} variant="outlined">
              {activeStep === steps.length - 1 ? 'Finish' : 'Siguiente'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default OnboardingStepsBar;