import './styles.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONTENT_GAP_URL_COLORS } from '../Utils/Constants';
import * as CONSTANTS from './../../components/Utils/TableConstants';
import { DomainSearchIntents } from '../../types/domainSearchIntents.type';

import { Grid, Skeleton } from '@mui/material';
import TableComponent from '../TableComponent';
import ProgressBarMultiple from './ProgressBarMultiple';

const KeywordByIntents = (props: { loading: boolean; data?: DomainSearchIntents }) => {
  const { t } = useTranslation();
  if (!props.data) {
    return (
      <div className="container-chart" style={{ minHeight: '349px' }}>
        <div className="skeleton flex-col" style={{ minHeight: '100%' }}>
          <Skeleton
            width="100%"
            variant="text"
            sx={{ fontSize: '1.4rem' }}
            animation={props.loading ? 'pulse' : false}
          />
          <Skeleton
            width="100%"
            height={400}
            variant="rectangular"
            animation={props.loading ? 'pulse' : false}
          />
        </div>
      </div>
    );
  }

  const totalKeywords = Object.values(props.data).reduce((acc, curr) => acc + curr, 0);
  const progressList = Object.entries(props.data)
    .map(([key, value], index) => ({
      key: index,
      name: key,
      keyword: value,
      value,
      result: totalKeywords > 0 ? `${((value / totalKeywords) * 100).toFixed(2)}%` : t('no_rows'),
      color: CONTENT_GAP_URL_COLORS[index]
    }))
    .flat();

  return (
    <Grid container spacing={3} height="100%">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ProgressBarMultiple data={progressList} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableComponent
          hideFooter
          rows={progressList}
          loading={props.loading}
          columns={CONSTANTS.COLUMNS_DOMAIN_KEYWORD_INTENT_TABLE(t)}
        />
      </Grid>
    </Grid>
  );
};

export default KeywordByIntents;
