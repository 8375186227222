import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import MenuSelector from '../../MenuSelector/MenuSelector';
import { ColumnSelectorProps, OptionsType } from '../types';

const ColumnSelector: React.FC<ColumnSelectorProps> = ({ columns, onChange }) => {
  const { t } = useTranslation();

  const columnOptions: OptionsType[] = [
    { value: '1', label: t('1Column') },
    { value: '2', label: t('2Columns') },
    { value: '3', label: t('3Columns') },
  ];

  const handleColumnChange = (option: OptionsType) => {
    const numColumns = parseInt(option.value, 10);
    onChange(numColumns);
  };

  const getColumnLabel = (value: number) => {
    const option = columnOptions.find((opt) => opt.value === value.toString());
    return option ? option.label : '';
  };

  return (
    <Box>
      <MenuSelector
        suggestion={columnOptions}
        onChange={handleColumnChange}
        defaultValue={getColumnLabel(columns)}
      />
    </Box>
  );
};

export default ColumnSelector;