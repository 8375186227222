import { ROUTES } from './Constants';

export const DRAWER_RIGHT_TUTORIAL: Record<string, { url: string; description: string }> = {
  [ROUTES.EXPLORE]: {
    url: 'https://www.youtube-nocookie.com/embed/aoPEKgCAjbY',
    description:
      'Revisa tus keywords con explorer, un módulo de Rankerfy que te permitirá el análisis íntegro de una palabra clave concreta.<br/><br/>Desde el volumen de búsqueda, la dificultad, la tendencia, el CPC HASTA LA MUESTRA DE Snippets y la intención de la palabra clave.<br/><br/>Explorer te permitirá también encontrar ideas de palabras clave sugeridas donde encontrarás un botón para poder seguir analizando.<br/><br/>Por último cuenta con el análisis de las serps, donde podrás ver a tus competidores en los 100 primeros resultados de google.'
  },
  [ROUTES.IDEAS]: {
    url: 'https://www.youtube-nocookie.com/embed/6SK1FRGGE1A',
    description:
      'El módulo ideas de keywords te permitirá encontrar palabras clave semánticas de una palabra clave concreta.<br/><br/>Utilizando su buscador, podrás colocar cualquier palabra clave, en el país que elijas, y te mostrará un listado de palabras clave relacionadas.<br/><br/>Tendrás la opción de revisar todas las palabras clave sugeridas, las preguntas relacionadas, o palabras clave que contengan esa palabra clave concreta<br/><br/>Además podrás aplicar los filtros que quieras para una búsqueda mucho más optimizada.<br/><br/>Por último, podrás analizar una palabra clave concreta pulsando sobre el enlace, que te llevará a la pantalla de explorer para saber más acerca de esta palabra clave'
  },
  [ROUTES.BATCH_ANALYSIS]: {
    url: 'https://www.youtube-nocookie.com/embed/HDA8nssKfzQ',
    description:
      'Analiza varias palabras clave en bulk.<br/><br/>Batch análisis te permitirá el análisis de palabras clave en un solo click.<br/><br/>Solo tienes que poner las palabras clave en buscador y el sistema te sacará el volumen, CPC competencia, dificiltad entre otras métricas.<br/><br/>Además si pulsas sobre el enlace te llevará a explorer, donde podrás analizar más a fondo esta palabra clave y revisar su campo semántico.'
  },
  [ROUTES.DOMAIN_VIEW]: {
    url: 'https://www.youtube-nocookie.com/embed/xXjsiAt5EtY',
    description: `
      <h2>Análisis de dominios</h2>
      <p>Desde este módulo podrás colocar la URL de tu competencia y averiguar su tráfico, el coste del tráfico, el total de palabras clave que posiciona, y las URLs que tiene generadas. También te aparecerá un listado con algunas de las palabras clave más relevantes, y podrás pulsar sobre el botón que te guiará al resto de palabras clave de ese dominio. Más abajo encontrarás los competidores directos de ese dominio, donde podrás saber la competencia que tiene.</p>
    `
  },
  [ROUTES.DOMAIN_KEYWORDS]: {
    url: 'https://www.youtube-nocookie.com/embed/gsBiZAUOL_k',
    description: `
      <h2>Keywords DM</h2>
      <p>En este módulo que hemos denominado como posiciones, encontrarás todas las palabras clave que está posicionando un dominio particular. Simplemente, escribirás la URL en el buscador, y el propio sistema se encargará de mostrarte las palabras clave más relevantes para ese dominio. También podrás analizar cada palabra clave pulsando sobre ellas. El sistema te redireccionará hacia la página de explorador para analizar esa palabra clave.</p>
    `
  },
  [ROUTES.DOMAIN_COMPETITORS]: {
    url: 'https://www.youtube-nocookie.com/embed/I_dxG5Pycbk',
    description: `
      <h2>Competidores</h2>
      <p>Este módulo te permitirá conocer de primera mano cuál es tu competencia directa en un solo click. Colocarás la URL en el buscador, y el sistema se encargará de mostrarte un listado con los competidores más relevantes que te encontrarás en las SERPs de Google. Podrás analizar cada uno de ellos pulsando sobre el enlace, y te llevará al módulo de vista general, para hacer un análisis previo del dominio seleccionado.</p>
    `
  },
  [ROUTES.DOMAIN_BACKLINKS]: {
    url: 'https://www.youtube-nocookie.com/embed/bK4hIqjum6w',
    description: `
      <h2>Backlinks</h2>
      <p>Desde el módulo de backlinks, podrás conocer cuáles son los enlaces más relevantes que tiene tu competencia. Simplemente colocando la URL en el buscador, el sistema te mostrará los diferentes enlaces que contiene ese dominio. Desde el título, la URL, los enlaces externos, internos y demás. Te sacará en los resultados, los 100 backlinks más relevantes que tiene ese dominio.</p>
    `
  },
  [ROUTES.DOMAIN_COMPARATOR]: {
    url: 'https://www.youtube-nocookie.com/embed/ZgRIP8XBzIo',
    description: `
      <h2>Content GAP</h2>
      <p>Content GAP, un módulo estrella de Rankerfy, con el que podrás hacer una comparativa de dominios de una forma rápida y sencilla. Simplemente colocarás la URL de tu página web y la de tu competencia. Después pulsarás sobre el botón de <strong>"Buscar"</strong> y el sistema te mostrará las palabras clave comunes, faltantes y las únicas. Un sistema que te servirá para hacer comparativas de dominio y poder averiguar qué palabras clave posiciona tu competencia y que tú no tienes en tu página web.</p>
    `
  },
  [ROUTES.PROJECTS_MANAGE]: {
    url: 'https://www.youtube-nocookie.com/embed/lPLfpPVgcPU',
    description:
      'Administra tus proyectos con Rankerfy. Desde este apartado podrás agregar diferentes cuentas de gmail pulsando sobre el botón y "agregar cuenta".<br/><br/>Una vez estén agregadas, ya podrás crear proyectos, simplemente pulsando sobre el botón de agregar proyectos y seleccionando la propiedad.<br/><br/>Una vez agregado ya podremos utilizar los diferentes módulos dentro del apartado proyectos'
  },
  [ROUTES.PROJECTS_CANNIBALIZATION]: {
    url: 'https://www.youtube-nocookie.com/embed/ef2FqqZg2T4',
    description:
      'Modulo de canibalización. Desde este módulo podrás observar si tus proyectos tienen palabras clave posicionando en varias URLS. Es por eso que este módulo es muy útil si quieres optimizar el SEO de tu página web<br/><br/>Solamente selecciona el proyecto y pulsa en el botón de obtener. Aparecerán las palabras clave y las urls por las cuales está canibalizando.'
  },
  [ROUTES.PROJECTS_CONTENT]: {
    url: 'https://www.youtube-nocookie.com/embed/uoLlYgdWU3c',
    description:
      'Thin Content o contenido de poco valor. Con este módulo podrás observar cuales son las URLS que menos impresiones, clics están teniendo. También te indicará cuales tienen problemas relacionados con el CTR.<br/><br/>Una vez tengas todo preparado, solamente tendrás que seleccionar tu proyecto, hacer el filtrado y pulsar sobre el botón.<br/><br/>Aparecerá un listado de ulrs, las cuales, a la derecha te pondrá en ver o en amarillo cuales están funcionando bien o tienen problemas.'
  },
  [ROUTES.PROJECTS_OPPOTUNITY]: {
    url: 'https://www.youtube-nocookie.com/embed/95eHRznaKcQ',
    description:
      'Saca oportunidades de keywords a golpe de click con este nuevo módulo. Solamente selecciona uno proyecto y ya podrás utilizar esta herramienta. Puedes filtrar, si lo deseas, para obtener un mejor resultado.<br/><br/>Obtendrás de esta forma un listado de urls junto con palabras clave que están posicionando. Además, te permitirá ver que palabras clave son las que tienen incluidas y cuales debes incluir.'
  },
  [ROUTES.PROJECTS_PERFORMANCE]: {
    url: 'https://www.youtube.com/embed/fkvs-LEaSMg?si=kPTaSk8oKIxiJXXo',
    description:
      ' Desde el módulo de rendimiento podrás obtener, en una misma pantalla, una visión mucho más amplia del proceso de tus páginas web.<br/><br/>Esta pantalla funciona agregando proyectos desde el módulo "Administrar", donde aparecerán todos los proyectos en los que estés trabajando, y te dará datos como los clicks, las impresiones, la posición y el CTR.<br/><br/>Además, es totalmente personalizable, ya que simplemente clicando en los diferentes apartados, podrás poner o quitar los datos que desees.<br/><br/>Por último tiene una gráfica comparativa de todos tus proyectos junto con las actualizaciones que gooogle va lanzando a lo largo del año.<br/><br/>Un módulo exclusivo que te permitirá, de un simple vistazo, como se comportan tus nichos en las serps de Google.'
  },
  PROJECTS_DETAIL_DYNAMIC: {
    url: 'https://www.youtube.com/embed/EpikYDXoAEM?si=vfnQiWqCVSerk_2C',
    description:
      'El sistema de detalles de rendimiento es una pantalla preparada para mostrar datos relevantes de un dominio concreto. <br/><br/> Desde ella, podrás sacar datos como el rendimiento, los sitemaps, las palabras clave del dominio, las mejores páginas y todo lo referente al tráfico dentro de tu página web. <br/><br/>Además contiene una gráfica mejorada con datos de tu Google search console que te dará detalles interesantes para la mejora de tus proyectos.'
  },
  [ROUTES.NICHE_KEYWORDS]: {
    url: 'https://www.youtube-nocookie.com/embed/7hNKSEYT33U',
    description:
      'Descubre como encontrar ideas de nicho con Rankerfy. Con un sistema sencillo, el módulo ideas de nicho es el perfecto aliado para que tu búsqueda de nichos sea lo más fácil posible.<br/><br/>Desde palabras clave totalmente aleatorias, hasta filtros preparados para encontrar ideas de nicho perfectos para ti.<br/><br/>Este módulo está pensado para todo aquel que tiene problemas para encontrar ideas nicho, Encuentra palabras clave de oportunidad a golpe de click.<br/><br/>Simplemente, rellena los campos, selecciona los filtros y pulsa en el buscador. Saldrán palabras clave totalmente aleatorias basadas en tus especificaciones.<br/><br/>¿No tienes ideas nicho? No te preocupes.<br/><br/>Puedes dejar todo en blanco y pulsar sobre el buscador. El sistema te mostrará un listado de palabras clave para su posterior análisis.'
  },
  [ROUTES.NICHE_DOMAINS]: {
    url: 'https://www.youtube.com/embed/usdeK2MM9MI?si=Y9wOvAC842xvsF-Y&',
    description:
      'Ideas de dominio es una nueva forma de encontrar nichos rentables basándonos en dominios de nuestra competencia.<br/><br/> Se trata de un sistema totalmente aleatorio y personalizable donde podrás encontrar dominios con palabras clave relevantes que te darán ideas para hacer nichos. <br/><br/>El módulo consta de varios apartados, una zona de palabras y unos simples filtros. <br/><br/>Coloca palabras que quieras que contengan los dominios o incluso que no quieres que contengan los dominios.<br/><br/> Ajusta los filtros para una búsqueda más personalizada y pulsa sobre el botón de buscar.<br/><br/> Aparecerá un listado de dominios para analizar y sacar sus mejores palabras clave.<br/><br/> Esto te dará nuevas ideas de nicho para hacer tus propias páginas web.'
  }
};
