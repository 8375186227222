import './SettingsModal.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from '../Utils/Utils';
import { useUserContext } from '../../context/UserContext';
import { useColorModeContext } from '../../context/ColorModeContext';
import {
  COLOR_MODE_DARK, COLOR_MODE_LIGHT, ROUTES
} from '../Utils/Constants';
import {
  List, Paper, Stack, Button, Divider, Popover, ListItemButton, ListItemText, Tooltip
} from '@mui/material';
import { Logout } from '@mui/icons-material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const SettingsModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { myUser, logout, plan, blur, toggleBlur } = useUserContext();
  const { toggleColorMode, mode, hasDarkMode } = useColorModeContext();

  const getCurrentTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(getCurrentTheme());

  const mqListener = (e: MediaQueryListEvent) => { setIsDarkTheme(e.matches); };

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMq.addEventListener('change', mqListener);
    return () => darkThemeMq.removeEventListener('change', mqListener);
  }, []);

  const id = open ? 'simple-popover' : undefined;
  const activeTheme = (theme: boolean) => (theme ? 'rgba(145, 97, 249, 0.253)' : 'transparent');

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      disableScrollLock
      className={classNames('wrap-popover setting-modal', mode)}
      anchorOrigin={{ vertical: 60, horizontal: 'right' }}
      slotProps={{ root: { sx: { overflowY: 'auto', overflowX: 'hidden' } } }}
    >
      <Paper className="settings-modal" sx={{ m: 0, p: 0, width: '100%' }}>
        <List disablePadding className="settings-list">
          <ListItemButton
            disabled={!plan}
            onClick={() => navigate(ROUTES.ACCOUNT_CONNECTIONS)}
            className="settings-list-item"
          >
            <AccountCircleIcon />
            <ListItemText primary={t('account')} />
          </ListItemButton>
          <ListItemButton
            onClick={(e) => { e.stopPropagation(); toggleBlur(); }}
            className="settings-list-item"
          >
            <Tooltip title={blur ? t('domains_hidden') : t('domains_visible')}>
              {blur ? (
                <VisibilityOffIcon className="sidebar-icon" />
              ) : (
                <VisibilityIcon className="sidebar-icon" />
              )}
            </Tooltip>
          </ListItemButton>
        </List>
        <Stack flexDirection="row" p={1} justifyContent="space-between">
          <Button
            onClick={() => toggleColorMode()}
            color={!hasDarkMode ? 'primary' : 'inherit'}
            sx={{ backgroundColor: activeTheme(!hasDarkMode) }}
          >
            <LightModeIcon fontSize="small" />
          </Button>
          <Button
            onClick={() => toggleColorMode()}
            color={hasDarkMode ? 'primary' : 'inherit'}
            sx={{ backgroundColor: activeTheme(hasDarkMode) }}
          >
            <DarkModeIcon fontSize="small" />
          </Button>
          <Button
            onClick={() => toggleColorMode(isDarkTheme ? COLOR_MODE_DARK : COLOR_MODE_LIGHT)}
            color="inherit"
          >
            <DesktopMacOutlinedIcon fontSize="small" />
          </Button>
        </Stack>
        <Divider />
        <List disablePadding>
          {myUser && (
            <ListItemButton
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              <Logout fontSize="small" />
              <ListItemText primary={t('logout')} />
            </ListItemButton>
          )}
        </List>
      </Paper>
    </Popover>
  );
};

export default SettingsModal;