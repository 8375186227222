import './styles.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridSearchIcon } from '@mui/x-data-grid';
import { Button, CircularProgress } from '@mui/material';

interface Props {
  title?: string;
  loading: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ButtonSearch: React.FC<Props> = ({ title, loading, onClick }) => {
  const { t } = useTranslation();
  return (
    <Button color="primary" type="button" className="capitalize btn-search" onClick={onClick}>
      {loading ? (
        <CircularProgress
          size={15}
          color="inherit"
          className="btn-progress"
          data-testid="btn-progress"
        />
      ) : (
        <>
          <GridSearchIcon />
          {title ?? t('button.search')}
        </>
      )}
    </Button>
  );
};

export default ButtonSearch;
