import React from 'react';
import { Paper, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import GoogleImage from '../../../../assets/img/Google.webp';

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  backgroundColor: theme.palette.grey[200],
}));

interface ToggleCustomDotsProps {
  active: boolean;
  onClick: () => void;
}

const ToggleCustomDots: React.FC<ToggleCustomDotsProps> = ({ active, onClick }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={active ? t('Ocultar Updates') : t('Mostrar Updates')}>
      <StyledPaper elevation={0}>
        <IconButton onClick={onClick} color={active ? 'primary' : 'default'}>
          <img
            src={GoogleImage}
            alt="Google"
            style={{ width: 24, height: 24, objectFit: 'contain', filter: active ? 'brightness(1)' : 'grayscale(100%)' }}
          />
        </IconButton>
      </StyledPaper>
    </Tooltip>
  );
};

export default ToggleCustomDots;