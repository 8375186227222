import { KeywordMetricsResponse } from '../../types';
import { convertObjectDateVolumeToArrayList } from '../../components/Utils/Utils';

const defaultValue = {
  items: [],
  total_keywords: 0
};

class KeywordMetricsDto {
  keywords: KeywordMetricsResponse;
  constructor(data: KeywordMetricsResponse) {
    this.keywords = this.mapKeywords(data);
  }
  private mapKeywords(data: KeywordMetricsResponse): KeywordMetricsResponse {
    if (data.items.length === 0) return defaultValue;
    if (data.items[0].keyword_info === undefined) return defaultValue;
    return {
      ...data,
      items: data.items.map((item) => ({
        ...item,
        keyword_info: {
          ...item.keyword_info,
          history: this.mapKeywordHistory(item.keyword_info?.history ?? {}) as any
        }
      })) as any[]
    };
  }

  private mapKeywordHistory(history: object) {
    const lastTwelveMonths = Object.fromEntries(Object.entries(history).slice(-12));
    return convertObjectDateVolumeToArrayList(lastTwelveMonths) ?? [];
  }
}

export default KeywordMetricsDto;
