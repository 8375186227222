import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, MenuItem } from '@mui/material';

import Menu from '../Menu/Menu';
import { OptionsType } from '../../types/common.type';
import { MenuSelectorContext } from '../../context/MenuSelectorContext';
import { enhancedValuesSplitNumber } from '../Utils/Utils';

interface Props<T = any> extends React.PropsWithChildren {
  value?: T;
  label?: string;
  isSelected?: boolean;
  defaultValue?: string;
  suggestion: OptionsType[];
  onChange: (value: OptionsType) => void;
}

const MenuSelector: React.FC<Props> = ({
  label,
  value,
  children,
  onChange,
  isSelected,
  suggestion,
  defaultValue
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClear = () => {
    onChange({ value: 0, label: '' });
  };

  // Función para verificar si el item está seleccionado
  const isItemSelected = (item) => {
    const { from: itemFrom, to: itemTo } = enhancedValuesSplitNumber(item.label);
    const { from: valueFrom, to: valueTo } = value || { from: 0, to: 0 };
    
    return itemFrom === valueFrom && itemTo === valueTo;
  };

  const getSelectedLabel = (value) => {
    if (value) {
      const { from, to } = value;
      if (from === 0 && to !== 0) {
        return `MIN-${to}`;
      }
      if (from !== 0 && to === 0) {
        return `${from}-MAX`;
      }
      if (from !== 0 && to !== 0) {
        return `${from}-${to}`;
      }
    }
    if (!value || value.from === 0 && value.to === 0) return defaultValue;
    const option = suggestion.find(option => {
      const { from, to } = enhancedValuesSplitNumber(option.label);
      return value.from >= from && value.to <= (to === Infinity ? value.to : to);
    });
    return option ? t(option.label) : `${value.from}-${value.to}`;
  };

  const menuItems = suggestion.map((item, index) => (
    <MenuItem
      key={index}
      autoFocus
      selected={isItemSelected(item)}
      onClick={() => {
        onChange(item);
        handleClose();
      }}>
      {t(item.label)}
    </MenuItem>
  ));

  return (
    <MenuSelectorContext.Provider value={{ onClose: handleClose }}>
      <Menu
        open={open}
        label={label}
        anchorEl={anchorEl}
        onClear={handleClear}
        onClick={handleClick}
        onClose={handleClose}
        isSelected={isSelected}
        defaultValue={getSelectedLabel(value)}
      >
        {children ? [menuItems, <Divider key="divider" />, children] : menuItems}
      </Menu>
    </MenuSelectorContext.Provider>
  );
};

export default MenuSelector;