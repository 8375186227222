import React from 'react';
import { Link } from 'react-router-dom';

import { useColorModeContext } from '../../context/ColorModeContext';

import LaunchIcon from '@mui/icons-material/Launch';
import { ListItem, ListItemText, useMediaQuery } from '@mui/material';

interface Props {
  path: string;
  selected: boolean;
  external?: boolean;
  subRouteName: string;
}

export function SubRoute({ path, selected, external, subRouteName }: Props) {
  const { onOpenSidebar } = useColorModeContext();
  const matchQuery = useMediaQuery('(max-width:600px)');

  return (
    <ListItem
      button
      to={path}
      component={Link}
      selected={selected}
      onClick={() => matchQuery && onOpenSidebar(false)}
      sx={{ borderRadius: 1, paddingBlock: 0, pl: 3.5 }}>
      <ListItemText primary={subRouteName} />
      {external && <LaunchIcon fontSize="small" />}
    </ListItem>
  );
}
