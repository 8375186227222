import {
  DomainsCompetitorResponse,
  CompetitorDataItemResponse,
  DomainCompetitorResponseWithTotal
} from '../../types/domainCompetitor.type';

class DomainCompetitorDto {
  total: number;
  keywords: DomainsCompetitorResponse;

  constructor(data: DomainCompetitorResponseWithTotal) {
    this.total = data.total;
    this.keywords = this.mapKeywords(data.items);
  }

  private mapKeywords(data: CompetitorDataItemResponse[]): DomainsCompetitorResponse {
    return data.map((item) => ({
      domain: item.domain,
      traffic: item.traffic,
      traffic_cost: item.traffic_cost,
      common_keywords: item.common_keywords
    }));
  }
}

export default DomainCompetitorDto;
