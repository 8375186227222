export interface CountryType {
  location: number;
  country: string;
  label: string;
  currency: number;
}

export const countries: ReadonlyArray<CountryType> = [
  { location: 2032, country: 'AR', label: 'Argentina', currency: 0o32 },
  { location: 2152, country: 'CL', label: 'Chile', currency: 152 },
  { location: 2170, country: 'CO', label: 'Colombia', currency: 170 },
  { location: 2840, country: 'US', label: 'UnitedStates', currency: 978 },
  { location: 2724, country: 'ES', label: 'Spain', currency: 978 },
  { location: 2484, country: 'MX', label: 'Mexico', currency: 484 },
  { location: 2862, country: 'VE', label: 'Venezuela', currency: 926 }
];

export default countries;
