import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAccessToken } from './useAccessToken';

type ReturnCallback = (data: { q: string; db: number }) => Promise<void>;

/**
 * A custom hook that retrieves the access token and search parameters.
 *
 * @returns {Function} validateQueriesParams - A function that validates the query parameters and executes a callback function.
 * @param {Object} data - The query parameters object containing 'q' and 'db' properties.
 * @param {string} data.q - The query string.
 * @param {number} data.db - The database number.
 * @throws {Error} If the access token is missing or the query parameters are invalid.
 * @async
 */
export default function useQueriesParams() {
  const token = useAccessToken();
  const [searchParams] = useSearchParams();

  const validateQueriesParams = React.useCallback(
    async (callback: ReturnCallback) => {
      const q = searchParams.get('q');
      const db = searchParams.get('db');
      if (q && db && token) {
        const data = { q, db: Number(db) };
        await callback(data);
      }
    },
    [token, searchParams]
  );

  return validateQueriesParams;
}
