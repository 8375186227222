import { format } from 'date-fns';
import { SearchDataItem } from './types';

export const generateMockSearchData = (days: number, startDateStr: string): SearchDataItem[] => {
  const data: SearchDataItem[] = [];
  const startDate = new Date(startDateStr);

  for (let i = 0; i < days; i++) {
    const date = new Date(startDate);
    date.setDate(date.getDate() - i);

    data.push({
      date: format(date, 'yyyy-MM-dd'),
      impressions: Math.floor(Math.random() * 1000) + 500,
      clicks: Math.floor(Math.random() * 200) + 100,
      ctr: parseFloat((Math.random() * 10).toFixed(2)),
      position: Math.floor(Math.random() * 101),
    });
  }

  return data.reverse();
};

export const formatNumber = (num: number) => {
  const fixed = (n: number, d: number) => n.toFixed(d).replace(/\.?0+$/, "");

  if (num >= 1e9) {
    return `${fixed(num / 1e9, 2)}B`;
  } else if (num >= 1e6) {
    return `${fixed(num / 1e6, 2)}M`;
  } else if (num >= 1e3) {
    return `${fixed(num / 1e3, 2)}K`;
  }
  return fixed(num, 2);
};

export const getTickInterval = (totalDays: number) => {
  if (totalDays <= 30) return 2;
  if (totalDays <= 90) return 6;
  if (totalDays <= 180) return 11;
  if (totalDays <= 240) return 15;
  return 22;
};

export const formatGoogleUpdateDate = (date: string | number | Date): string => 
  format(new Date(date), 'MMM dd yyyy');


