import React from 'react';
import './TableComponent.scss';
import { useTranslation } from 'react-i18next';
import { LinearProgress, styled, Box, Skeleton } from '@mui/material';
import { DataGrid, GridSlotsComponent, GridRenderCellParams } from '@mui/x-data-grid';
import {
  UncapitalizeObjectKeys,
  DataGridPropsWithDefaultValues,
  DataGridPropsWithoutDefaultValue,
} from '@mui/x-data-grid/internals';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: 65,
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0 !important',
  },
  '& .MuiDataGrid-main': {
    overflow: 'visible',
  },
  '& .paxton-table--row': {
    minHeight: '50px !important',
  },
  '& .blurred': {
    filter: 'blur(4px)',
  },
  '& .MuiDataGrid-cell:first-of-type': {
    position: 'relative',
  },
}));

interface Props extends Partial<DataGridPropsWithDefaultValues>, Omit<DataGridPropsWithoutDefaultValue, 'rows'> {
  rows: any[] | undefined | null;
  slots?: UncapitalizeObjectKeys<Partial<GridSlotsComponent>>;
  blurTitle?: boolean;
  overlayPercentages?: number[];
}

const TableComponent: React.FC<Props> = ({ rows, columns, slots, loading, blurTitle, overlayPercentages, ...props }) => {
  const { t } = useTranslation();

  const renderSkeleton = () => (
    <Box px={2} py={1}>
      {[...Array(10)].map((_, index) => (
        <Skeleton key={index} animation="wave" height={40} sx={{ mb: 1 }} />
      ))}
    </Box>
  );

  if (loading) {
    return renderSkeleton();
  }

  if (!rows || !columns) return null;

  const firstColumnField = columns[0]?.field;

  const translatedColumns = columns.map((column) => {
    const customRenderer = column.renderCell || ((params) => String(params.value));

    return {
      ...column,
      renderCell: (params: GridRenderCellParams) => {
        const cellValue = customRenderer(params);
        const isInitialColumn = params.field === firstColumnField;
        const shouldBlur = blurTitle && isInitialColumn;
        const rowIndex = rows.findIndex(row => row.id === params.id);
        const overlayPercentage = overlayPercentages?.[rowIndex] ?? 0;

        return (
          <div className={shouldBlur ? 'blurred' : ''} style={{ display: 'flex', alignItems: 'center' }}>
            {cellValue}
            {isInitialColumn && (
              <div
                className="morado-overlay"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: `${overlayPercentage}%`,
                  height: '100%',
                  backgroundColor: '#A198FB15',
                  pointerEvents: 'none',
                }}
              ></div>
            )}
          </div>
        );
      },
    };
  });

  rows = rows.map((row, index) => ({
    ...row,
    id: row.id ?? index + 1,
  }));

  return (
    <StyledDataGrid
      autoHeight
      initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
      rows={rows}
      columns={translatedColumns}
      loading={loading}
      slots={{
        loadingOverlay: LinearProgress,
        ...slots,
      }}
      localeText={{
        noRowsLabel: t('no_rows'),
        MuiTablePagination: {
          labelRowsPerPage: `${t('row_per_page')}:`,
        },
      }}
      {...props}
      pagination
      columnBuffer={10}
      pageSizeOptions={[10, 25, 50, 100]}
      getRowClassName={() => 'paxton-table--row'}
      getCellClassName={(params) => (params.field === firstColumnField ? 'morado-overlay' : '')}
    />
  );
};

export default TableComponent;