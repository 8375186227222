import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColorModeContextType, ThemeModeType } from '../interfaces';
import {
  COLOR_MODE_DARK,
  COLOR_MODE_LIGHT,
  LIMIT_SCREEN_INNER_WIDTH
} from '../components/Utils/Constants';

import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';

export const ColorModeContext = React.createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(true);
  const [mode, setMode] = React.useState<ThemeModeType>(COLOR_MODE_LIGHT);

  const onOpenSidebar = (show: boolean) => {
    localStorage.setItem('open', JSON.stringify(show));
    setOpen(show);
  };

  const windowResizeDrawer = () => {
    if (window.innerWidth >= LIMIT_SCREEN_INNER_WIDTH) {
      onOpenSidebar(true);
    } else {
      onOpenSidebar(false);
    }
  };

  const toggleColorMode = (color?: ThemeModeType) => {
    if (color) {
      localStorage.setItem('themeMode', color);
      return setMode(color);
    }
    const newMode = mode === COLOR_MODE_LIGHT ? COLOR_MODE_DARK : COLOR_MODE_LIGHT;
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const hasDarkMode = mode === COLOR_MODE_DARK;
  const hasLightMode = mode === COLOR_MODE_LIGHT;

  const value = React.useMemo(
    () => ({
      mode,
      open,
      hasDarkMode,
      hasLightMode,
      onOpenSidebar,
      toggleColorMode
    }),
    [mode, open]
  );

  const COLOR_THEME_CHANGE = hasLightMode ? '#1c1c1c' : '#f3f4f6';
  const theme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiDrawer: {
            styleOverrides: {
              paper: {
                background: mode === COLOR_MODE_LIGHT ? '#f3f4f6' : '#1c1c1c'
              }
            }
          },
          MuiButton: {
            styleOverrides: {
              outlined: {
                color: mode === COLOR_MODE_DARK ? '#f3f4f6' : 'primary',
                borderColor: mode === COLOR_MODE_DARK ? '#f3f4f6' : 'primary'
              }
            }
          },
          MuiButtonGroup: {
            styleOverrides: {
              root: {
                borderRadius: 8,
                overflow: 'hidden',
                border: '1px solid #E5E7EB',
                '& button[data-current=false]': {
                  background: mode === COLOR_MODE_LIGHT ? '#f3f4f6' : '#1c1c1c'
                }
              }
            }
          },
          MuiTablePagination: {
            styleOverrides: {
              actions: {
                '>button': {
                  color: COLOR_THEME_CHANGE
                }
              },
              displayedRows: {
                color: COLOR_THEME_CHANGE
              },
              selectLabel: {
                color: COLOR_THEME_CHANGE
              }
            }
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                color: COLOR_THEME_CHANGE
              }
            }
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                backgroundColor: mode === COLOR_MODE_LIGHT ? '#f3f4f6' : '#1f1f1f',
                borderColor: mode === COLOR_MODE_LIGHT ? 'inherit' : '#6c6e79'
              }
            }
          },
          MuiListItemText: {
            styleOverrides: {
              root: {
                color: mode === COLOR_MODE_LIGHT ? '#6c6e79' : '#f3f4f6'
              }
            }
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: mode === COLOR_MODE_LIGHT ? '#fff' : '#000'
              }
            }
          }
        },
        palette: {
          primary: {
            main: '#9061f9'
          },
          secondary: {
            main: '#a586ea'
          },
          mode,
          background: {
            default: mode === COLOR_MODE_LIGHT ? '#f3f4f6' : '#1c1c1c', // Color de fondo en modo oscuro
            paper: mode === COLOR_MODE_LIGHT ? '#fff' : '#1f1f1f' // Color de fondo de los componentes
          },
          text: {
            primary: mode === COLOR_MODE_LIGHT ? '#000' : '#fff', // Color de texto principal
            secondary: mode === COLOR_MODE_LIGHT ? '#000' : '#fff' // Color de texto principal
          }
        }
      }),
    [mode]
  );

  React.useEffect(() => {
    const storedMode = localStorage.getItem('themeMode') as ThemeModeType | null;
    const storedOpen = localStorage.getItem('open');

    const initialOpenState =
      storedOpen !== null ? storedOpen === 'true' : window.innerWidth >= LIMIT_SCREEN_INNER_WIDTH;

    setOpen(initialOpenState);
    if (storedMode !== null) setMode(storedMode);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', windowResizeDrawer);
    return () => window.removeEventListener('resize', windowResizeDrawer);
  }, [open]);

  return (
    <ThemeProvider theme={theme}>
      <ColorModeContext.Provider value={value}>
        <CssBaseline />
        {children}
      </ColorModeContext.Provider>
    </ThemeProvider>
  );
};

export const useColorModeContext = () => {
  const { t } = useTranslation();
  const context = React.useContext(ColorModeContext);
  if (!context) throw new Error(t('componentMustBeInside'));
  return context;
};
