import './ContainsKeyword.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

interface Props {
  value?: string;
}

const ContainsKeyword: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();
  if (value === undefined) return null;

  return (
    <CustomTooltip content={t(`contains_keyword_.${value.toString()}_tooltip`)}>
      <div className="contains-keyword">
        <div className={`border ${value}`}>
          <Typography fontWeight={700} className={value.toString()}>
            {t(`contains_keyword_.${value.toString()}`)}
          </Typography>
        </div>
      </div>
    </CustomTooltip>
  );
};

export default ContainsKeyword;
