import './HistoryTrendChart.css';
import React from 'react';
import { AreaChart, Area, XAxis, YAxis } from 'recharts';

interface Props {
  data: any;
  height?: number | `${number}`;
}

const HistoryTrendChart: React.FC<Props> = ({ data, height }) => {
  if ([null, undefined].includes(data)) return '—';
  return (
    <div className="historyTrendChart" style={{ height: height ?? 100 }}>
      <AreaChart width={300} height={125} data={data}>
        <XAxis dataKey="date" tick={false} axisLine={false} />
        <YAxis tick={false} axisLine={false} />
        <Area
          type="monotone"
          dataKey="volume"
          stroke="#8884d8"
          fill="#8884d8"
          width={100}
          height={100}
        />
      </AreaChart>
    </div>
  );
};

export default HistoryTrendChart;
