import './Sidebar.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../Utils/Constants';
import { useUsers } from '../../hooks/useUsers';
import { classNames, generateIcon } from '../Utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

import { List, Collapse, ListItem, ListItemText } from '@mui/material';
import { SubRoute } from './SubRouteAccount';

export function SidebarAccount() {
  const { t } = useTranslation();
  const location = useLocation();
  const { open, mode } = useColorModeContext();

  const { fetchRedirectStripe } = useUsers();

  const handleRedirectStripe = async () => await fetchRedirectStripe();

  const exactRoute = (path: string) => location.pathname.includes(path);

  return (
    <div className={classNames('sidebar account', open ? '' : 'sidebar-closed', mode)}>
      <List>
        <Collapse in sx={{ pt: 2 }}>
          <List component="div" disablePadding>
            <ListItem
              button
              component="a"
              href={ROUTES.HOME}>
              <ListItemText primary={t('go_back')} />
            </ListItem>
            
            <SubRoute
              path={ROUTES.ACCOUNT_CONNECTIONS}
              subRouteName={t('routes.connections')}
              selected={exactRoute(ROUTES.ACCOUNT_CONNECTIONS)}
            />
            <SubRoute
              path={ROUTES.ACCOUNT_BILLING}
              subRouteName={t('routes.billings')}
              selected={exactRoute(ROUTES.ACCOUNT_BILLING)}
            />
            <ListItem
              button
              onClick={handleRedirectStripe}
              sx={{ borderRadius: 1, paddingBlock: 0, pl: 3.5 }}>
              <ListItemText primary={t('manage_subscription')} />
              {generateIcon('Launch', { fontSize: 'small' })}
            </ListItem>
            <SubRoute
              external
              selected={false}
              path="https://rankerfy.freshdesk.com/"
              subRouteName={t('routes.support')}
            />
          </List>
        </Collapse>
      </List>
    </div>
  );
}