import '../../styles/messing-around.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { generateIcon } from '../../components/Utils/Utils';
import PaperItem from '../../components/Essential/PaperItem';

interface Props {
  icon: any;
  info: string;
  title: string;
  progress: number;
}

const CardProgressLimit: React.FC<Props> = ({ title, info, icon, progress }) => (
  <div className="mess-items">
    <div className="mess-border icon">{generateIcon(icon)}</div>
    <div className="mess-elements mess-border">
      <div className="mess-progress" style={{ '--mess-progress': `${progress}%` }} />
      <p className="mess-title">{title}</p>
      <p>{info}</p>
    </div>
  </div>
);

export const AccountMessingAroundPage = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <h1>Tus {t('routes.messing-around')}</h1>
      <PaperItem className="boxShadow flex flex-col gap-2" sx={{ p: 3 }}>
        <CardProgressLimit
          progress={10}
          info="Semanal"
          icon="AccessTimeOutlined"
          title="Frecuencia de rastreo de rankings"
        />
        <CardProgressLimit
          info="1/0"
          progress={0}
          icon="KeyOutlined"
          title="Palabras claves anadinas"
        />
        <CardProgressLimit
          info="1/5"
          progress={25}
          icon="LayersOutlined"
          title="Sitios web anadidos"
        />
        <CardProgressLimit
          info="0/0"
          progress={0}
          icon="InsertLinkOutlined"
          title="Monitoreo de blacklinks"
        />
        <CardProgressLimit
          progress={0}
          info="0/0 Paginas"
          icon="SearchOutlined"
          title="Auditoria (por cuenta)"
        />
        <CardProgressLimit
          progress={0}
          info="0 Paginas"
          icon="PersonalVideoOutlined"
          title="Auditoria web (por sitio)"
        />
        <CardProgressLimit
          progress={1}
          icon="InsertChartOutlined"
          title="infomes programados"
          info="1/ilimitado informes utilizados este mes"
        />
        <CardProgressLimit
          info="0"
          progress={0}
          icon="GroupsOutlined"
          title="competidores (por sitio)"
        />
      </PaperItem>
    </div>
  );
};
