import React, { createContext, useState, useEffect, useContext, ReactNode, useCallback } from 'react';

interface DomainsCacheContextType {
  getCache: (key: string) => any;
  updateCache: (key: string, newData: any) => void;
}

const DomainsCacheContext = createContext<DomainsCacheContextType | undefined>(undefined);

interface DomainsCacheProviderProps {
  children: ReactNode;
}

const LOCAL_STORAGE_KEY = 'multiDomainsCache';

export const DomainsCacheProvider: React.FC<DomainsCacheProviderProps> = ({ children }) => {
  const [caches, setCaches] = useState(() => {
    const storedCaches = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedCaches ? JSON.parse(storedCaches) : {};
  });

  const getCache = useCallback((key: string) => caches[key] || {}, [caches]);

  const updateCache = useCallback((key: string, newData: any) => {
    setCaches(prevCaches => ({
      ...prevCaches,
      [key]: { ...prevCaches[key], ...newData },
    }));
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(caches));
  }, [caches]);

  return (
    <DomainsCacheContext.Provider value={{ getCache, updateCache }}>
      {children}
    </DomainsCacheContext.Provider>
  );
};

export const useDomainsCache = () => {
  const context = useContext(DomainsCacheContext);
  if (!context) {
    throw new Error('useDomainsCache must be used within a DomainsCacheProvider');
  }
  return context;
};