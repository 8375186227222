import React from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import { useUserContext } from '../context/UserContext';
import { ROUTES_PLANS_GUARD } from '../components/Utils/routes';

export default function useRedirect() {
  const navigate = useNavigate();
  const { plan } = useUserContext();
  const { pathname } = useLocation();

  const options: NavigateOptions = {
    state: {
      from: location.pathname
    },
    replace: true
  };

  React.useEffect(() => {
    const primaryKey = pathname.split('/')[1];
    const matchPlanRoute = ROUTES_PLANS_GUARD[`/${primaryKey}/`];
    if (matchPlanRoute && plan) {
      matchPlanRoute.forEach((route) => {
        if (route.path.includes(pathname) && !route.plans.includes(plan)) {
          navigate('/', options);
        }
      });
    }
  }, [plan, pathname]);
}
