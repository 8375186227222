import React from 'react';
import { User, useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { ActionsUserProviderType } from '../interfaces';
import { BASE_URL } from '../components/Utils/Constants';
import Splash from '../components/Splash';

const CustomUserContext = React.createContext<ActionsUserProviderType>(
  {} as ActionsUserProviderType
);

const isBuyPlanRoute = () => /^\/buy-plan.*$/i.test(window.location.pathname);
const hasAuthCode = () => new URLSearchParams(window.location.search).has('code');

export const UserProvider = ({ children }: React.PropsWithChildren) => {
  const [plan, setPlan] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [myUser, setMyUser] = React.useState<User | undefined>();
  const [loadingPlan, setLoadingPlan] = React.useState<boolean>(true);
  const { user, logout, isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const getAccessTokenWithPlan = React.useCallback(async () => {
    try {
      const response = await getAccessTokenSilently();
      const decodedToken = jwtDecode(response);
      setPlan(decodedToken[`${BASE_URL}/subscription`]?.plan);
      setLoadingPlan(false);
    } catch (e) {
    }
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenWithPlan();
    }
  }, [isAuthenticated, getAccessTokenWithPlan]);

  React.useEffect(() => {
    if (user) {
      setMyUser(user);
      setLoading(false);
    }
  }, [user]);

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !isBuyPlanRoute() && !hasAuthCode()) {
      (async function fn() {
        await loginWithRedirect({
          appState: { returnTo: window.location.href },
          authorizationParams: { promotekit_referral: window['promotekit_referral'] || '' }
        });
      })();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const [blur, setBlur] = React.useState<boolean>(() => {
    const savedBlur = localStorage.getItem('blur');
    return savedBlur ? JSON.parse(savedBlur) : false;
  });

  const toggleBlur = React.useCallback(() => {
    setBlur(prevBlur => {
      const newBlur = !prevBlur;
      localStorage.setItem('blur', JSON.stringify(newBlur));
      return newBlur;
    });
  }, []);

  const values = React.useMemo(
    () => ({
      myUser,
      isLoading,
      plan,
      loadingPlan,
      isAuthenticated,
      logout,
      loginWithRedirect,
      getAccessTokenWithPlan,
      getAccessTokenSilently,
      blur,
      toggleBlur
    }),
    [
      myUser,
      isLoading,
      plan,
      loadingPlan,
      isAuthenticated,
      logout,
      loginWithRedirect,
      getAccessTokenSilently,
      blur,
      toggleBlur
    ]
  );

  return (
    <CustomUserContext.Provider value={values}>
      {loading && !isBuyPlanRoute() && <Splash />}
      {children}
    </CustomUserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = React.useContext(CustomUserContext);
  if (!context) {
    throw new Error("Component must be inside 'UserProvider'");
  }
  return context;
};