import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, useTheme, Dialog, DialogActions, DialogContent, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuSelector from '../../MenuSelector/MenuSelector';
import { LocalDateRangeSelectorProps, OptionsType } from '../types';
import { getTodayISODate, subtractDays, getPresetOptions, predefinedRanges, DAYS_OFFSET } from '../utils';
import { DateRange } from '../types';

const DateRangeSelector: React.FC<LocalDateRangeSelectorProps> = ({ onChange, initialRange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultRange = initialRange || (isMobile ? '28d' : '28d');
  const defaultOffsetDays = predefinedRanges[defaultRange] as number;
  const today = getTodayISODate();
  const adjustedToday = subtractDays(today, DAYS_OFFSET);
  const defaultStartDate = subtractDays(adjustedToday, defaultOffsetDays);
  const [selectedRange, setSelectedRange] = useState<DateRange>({ startDate: defaultStartDate, endDate: adjustedToday });
  const [selectedRangeKey, setSelectedRangeKey] = useState<string>(defaultRange);
  const [isCustomOpen, setIsCustomOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState<string>(defaultStartDate);
  const [customEndDate, setCustomEndDate] = useState<string>(adjustedToday);

  useEffect(() => {
    if (initialRange && initialRange !== defaultRange) {
      const newRange = calculateDateRange(initialRange);
      setSelectedRange(newRange);
      setSelectedRangeKey(initialRange);
    }
  }, [initialRange]);

  const handleRangeChange = (range: OptionsType) => {
      if (range.value === "custom") {
          setIsCustomOpen(true);
      } else {
          const calculatedRange = calculateDateRange(range.value);
          setSelectedRange(calculatedRange);
          setSelectedRangeKey(range.value);
      }
  };

  const calculateDateRange = (rangeKey: string): DateRange => {
    const offsetDays = predefinedRanges[rangeKey] as number;
    if (offsetDays == null) return { startDate: '', endDate: '' };

    const adjustedToday = subtractDays(getTodayISODate(), DAYS_OFFSET);
    return { startDate: subtractDays(adjustedToday, offsetDays), endDate: adjustedToday };
  };
  const handleCustomApply = () => {
      setSelectedRange({ startDate: subtractDays(customStartDate, 0), endDate: subtractDays(customEndDate, 0) });
      setSelectedRangeKey("custom");
      setIsCustomOpen(false);
  };

  useEffect(() => {
    if (selectedRange.startDate && selectedRange.endDate) {
      onChange({
          startDate: subtractDays(selectedRange.startDate, 0),
          endDate: subtractDays(selectedRange.endDate, 0)
      });
    }
  }, [selectedRange, onChange]);

  const getDisplayText = () => {
    const option = getPresetOptions(t, isMobile).find((opt) => opt.value === selectedRangeKey);
    return option ? t(option.label) : '';
  };

  return (
      <Box>
          <MenuSelector
              suggestion={getPresetOptions(t, isMobile)}
              onChange={handleRangeChange}
              defaultValue={getDisplayText()}
          />
          <Dialog open={isCustomOpen} onClose={() => setIsCustomOpen(false)}>
              <DialogContent>
                  <TextField
                      label="Start Date"
                      type="date"
                      value={customStartDate}
                      onChange={(e) => setCustomStartDate(e.target.value)}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
                  <TextField
                      label="End Date"
                      type="date"
                      value={customEndDate}
                      onChange={(e) => setCustomEndDate(e.target.value)}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCustomApply} color="primary">
                      Apply
                  </Button>
              </DialogActions>
          </Dialog>
      </Box>
  );
};

export default DateRangeSelector;