import React from 'react';
import { styled } from '@mui/system';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/base/TextareaAutosize';

const purple = {
  200: '#C5AFE6',
  400: '#8A4FBC',
  600: '#5F1A8B'
};

const grey = {
  50: '#F3F6F9',
  200: '#DAE2ED',
  300: '#C7D0DD',
  700: '#434D5B',
  900: '#1C2025'
};

const TextareaMui = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  resize: none;
  width: 100%;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? purple[600] : purple[300]};
  }

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? purple[600] : purple[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? purple[600] : purple[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const Textarea: React.FC<TextareaAutosizeProps> = (props) => (
  <TextareaMui id="editor" maxRows={4} minRows={1} name="editor" sx={{ flex: 1 }} {...props} />
);

export default Textarea;
