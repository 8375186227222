import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../Utils/Constants';
import { classNames } from '../Utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';

interface Props {
  url: string;
  location?: number;
  path?: `${ROUTES}`;
  value?: string | number;
}

const LinkRedirect: React.FC<Props> = ({ url, path, value, location }) => {
  const { mode } = useColorModeContext();
  const href = `${path ?? ROUTES.DOMAIN_VIEW}?q=${url}&db=${location}`;

  return (
    <div className="flex flex-col py-2">
      <Link to={href} relative="path" className={classNames('btn-link keyword', mode)}>
        {value ?? url}
      </Link>
    </div>
  );
};

export default LinkRedirect;
