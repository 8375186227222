import { MOCK_DATA_KEYWORDS_SERVICES } from '../../components/Utils/Constants';
import { convertObjectDateVolumeToArrayList } from '../../components/Utils/Utils';
import { KeywordResponse } from '../../types';


class DomainsKeywordDto {
  keywords: KeywordResponse;

  constructor(data: KeywordResponse) {
    this.keywords = this.mapKeywords(data);
  }

  private mapKeywords(data: KeywordResponse): KeywordResponse {
    if (data.items.length === 0) return MOCK_DATA_KEYWORDS_SERVICES;
    if (data.items[0].keyword_info === undefined) return MOCK_DATA_KEYWORDS_SERVICES;
    return {
      ...data,
      items: data.items.map((item) => ({
        ...item,
        serp_info: {
          ...item.serp_info,
          item_types: item.serp_info?.item_types || [],
          se_results_count: item.serp_info?.se_results_count || 0
        },
        search_intent_info: item.search_intent_info || undefined,
        keyword_info: {
          ...item.keyword_info,
          cpc: item.keyword_info?.cpc || 0,
          competition: item.keyword_info?.competition || 0,
          time_update: item.keyword_info?.time_update || '',
          search_volume: item.keyword_info?.search_volume || 0,
          history: this.mapKeywordHistory(item.keyword_info?.history ?? {}) as any
        },
        extra:{
          keyword_difficulty: item.extra?.keyword_difficulty || 0
        }
      })) as any[]
    };
  }
  private mapKeywordHistory(history: object) {
    const lastTwelveMonths = Object.fromEntries(Object.entries(history).slice(-12));
    return convertObjectDateVolumeToArrayList(lastTwelveMonths) ?? [];
  }
}

export default DomainsKeywordDto;
