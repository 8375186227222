import * as React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

export interface Props extends React.PropsWithChildren {
  open: boolean;
  title: string;
  onClose: (value: boolean) => void;
}

export default function SimpleDialog(props: Props) {
  const { onClose, open } = props;

  const handleClose = () => onClose(false);

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
}
