import { TFunction } from 'i18next';
export const DAYS_OFFSET = 3;

export const getTodayISODate = (): string => {
    const today = new Date();
    return today.toISOString().split('T')[0];
};

export const subtractDays = (dateString: string, days: number): string => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - days);
    return date.toISOString().split('T')[0];
};

export const predefinedRanges: { [key: string]: number | 'custom' } = {
    '7d': 6,
    '28d': 27,
    '3m': 89,
    '6m': 179,
    '12m': 364,
    '16m': 479,
    'custom': 'custom',
};

export const getPresetOptions = (t: TFunction, isMobile: boolean) => {
    return Object.keys(predefinedRanges).map(key => {
      const label = key === 'custom' ? t(key) : `${t('last')} ${t(key)}`;
      return {
        value: key,
        label: isMobile ? key : label,
      };
    });
  };