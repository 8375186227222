import React from 'react';

import { IconWithTitle, capitalizedFirstLetter, generateUuid, serpIcons } from '../Utils/Utils';
import { Skeleton } from '@mui/material';

const KeywordFeatures = ({ data }: { data: string[] }) => {
  if (!data) return '—';
  else if (data?.length === 0)
    return (
      <div className="flex gap-1">
        {Array.from({ length: 3 }).map(() => (
          <Skeleton key={generateUuid()} variant="circular" width={20} height={20} />
        ))}
      </div>
    );
  return (
    <div>
      {data?.map((item: any) => (
        <IconWithTitle
          key={generateUuid()}
          icon={serpIcons[item]}
          title={capitalizedFirstLetter(item).replace(/_/g, ' ')}
        />
      ))}
    </div>
  );
};

export default KeywordFeatures;
