import React from 'react';
import { useTranslation } from 'react-i18next';
import './KeywordDifficulty.scss';
import { Tooltip, Typography } from '@mui/material';
import { keywordDifficultyValues } from '../Utils/Utils';

interface Props {
  value: number;
}

const KeywordDifficulty: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();
  const { htmlClass, htmlTooltip } = keywordDifficultyValues(value, t);

  return (
    <Tooltip
      arrow
      followCursor
      title={
        <div>
          <div>{htmlTooltip.title}</div>
          <div>{htmlTooltip.description}</div>
        </div>
      }>
      <div className="difficulty flex-end">
        <div className={`border ${htmlClass}`}>
          <p className={htmlClass} data-testid={htmlClass}></p>
          <Typography>{value === undefined ? '—' : value}</Typography>
        </div>
      </div>
    </Tooltip>
  );
};

export default KeywordDifficulty;
