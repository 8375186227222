import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { LOCATION_CODE_ES } from '../components/Utils/Constants';
import { useSearchCache } from '../context/SearchCacheContext';

export default function useRefreshQueries(cacheKey: string) {
  const [query, setQuery] = React.useState<string>('');
  const [location, setLocation] = React.useState<number>(LOCATION_CODE_ES);
  const [searchParams] = useSearchParams();
  const { getLocation, updateLocation } = useSearchCache();

  const handleQuery = (q: string) => {
    if (query !== q && q) {
      setQuery(q);
    }
  };

  const handleLocation = (db: number) => {
    if (location !== db && db) {
      setLocation(db);
      updateLocation(cacheKey, db);
    }
  };

  React.useEffect(() => {
    const queryParam = searchParams.get('q');
    const locale = searchParams.get('db');
    const cachedLocation = getLocation(cacheKey);

    if (queryParam && queryParam !== query) {
      setQuery(queryParam);
    }
    if (locale && Number(locale) !== location) {
      setLocation(Number(locale));
      updateLocation(cacheKey, Number(locale));
    } else if (cachedLocation && cachedLocation !== location) {
      setLocation(cachedLocation);
    }
  }, [searchParams, query, location, cacheKey, getLocation, updateLocation]);

  return { query, location, handleQuery, handleLocation };
}