import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';

import { CannibalizationResponse } from '../../types';
import KeywordLinkBlank from '../KeywordLinkBlank';

const CannibalizeResults = ({ results }: CannibalizationResponse) => {
  return (
    <div
      className="flex flex-col w-full gap-2 py-3 px-3"
      data-testid={results.length > 0 ? 'full' : 'empty'}>
      {results.map((item: CannibalizationResponse['results'][0], index: number) => (
        <React.Fragment key={index}>
          <Grid container rowSpacing={3}>
            <Grid item xs={8.7} lg={8} xl={9.5}>
              <KeywordLinkBlank href={item?.url} />
            </Grid>
            <Grid item xs={1} xl={0.8}>
              <Typography textAlign="center">{item?.impressions ?? '—'}</Typography>
            </Grid>
            <Grid item xs={1} lg={1.5} xl={0.8}>
              <Typography textAlign="center">{item?.clicks ?? '—'}</Typography>
            </Grid>
            <Grid item xs={1} xl={0.6}>
              <Typography textAlign="center">{item.position?.toFixed(0) ?? '—'}</Typography>
            </Grid>
          </Grid>
          {results.length > index + 1 && <Divider sx={{ borderStyle: 'dashed' }} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CannibalizeResults;
