import React from 'react';
import './OnboardingSteps.scss';
import { Box } from '@mui/material';
import LoginButton from '../LoginButton/LoginButton';
import RankerfyPlans from '../RankerfyPlans/RankerfyPlans';
import Checkout from '../Checkout/Checkout';
import { useSelectedPlan } from '../../context/PlanContext';
import RankerfyImage from '../../assets/img/isotipo.png';

interface StepTwoProps {
  plan: string | null;
  billing: string | null;
  coupon: string | null;
}

interface StepThreeProps {
  coupon: string | null;
}

const StepOne: React.FC = () => (
  <Box
    sx={{
      width: '100%',
      textAlign: 'center',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center'
    }}>
    <img src={RankerfyImage} alt="Rankerfy" style={{ width: '200px', marginBottom: '20px' }} />
    <h2>¡Nos alegra tenerte aquí!</h2>
    <LoginButton />
  </Box>
);

const StepTwo: React.FC<StepTwoProps> = ({ plan, billing, coupon }) => {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <h2>🎉 Te damos la bienvenida, ahora selecciona un plan</h2>
      <RankerfyPlans plan={plan} billing={billing} coupon={coupon} />
    </Box>
  );
};

const StepThree: React.FC<StepThreeProps> = ({ coupon }) => {
  const { selectedPlan, selectedBilling } = useSelectedPlan();

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <h2>💳 Paga de forma segura en Stripe</h2>
      <Checkout plan={selectedPlan} billing={selectedBilling} coupon={coupon} />
    </Box>
  );
};

export { StepOne, StepTwo, StepThree };
