import React from 'react';
import Menu from '../Menu/Menu';
import { MenuSelectorContext } from '../../context/MenuSelectorContext';

interface Props extends React.PropsWithChildren {
  label?: string;
  isSelected?: boolean;
  defaultValue?: string;
  onClear?: () => void;
}

const ModeSelectorEmpty: React.FC<Props> = ({
  label,
  children,
  isSelected,
  defaultValue,
  onClear
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClear = () => onClear?.();
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  return (
    <MenuSelectorContext.Provider value={{ onClose: handleClose }}>
      <Menu
        open={open}
        label={label}
        anchorEl={anchorEl}
        onClick={handleClick}
        onClear={handleClear}
        onClose={handleClose}
        isSelected={isSelected}
        defaultValue={defaultValue}>
        {children}
      </Menu>
    </MenuSelectorContext.Provider>
  );
};

export default ModeSelectorEmpty;
