import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { XAxis as RechartsXAxis, YAxis as RechartsYAxis, Tooltip as RechartsTooltip, ResponsiveContainer, AreaChart, Area, Legend, ReferenceDot as RechartsReferenceDot, CartesianGrid, ReferenceLine } from 'recharts';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format, differenceInDays, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { toZonedTime, format as formatTz } from 'date-fns-tz';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import CustomDot from '../CustomDot/CustomDot';
import { getTickInterval } from '../../utils';
import { ChartProps } from '../../types';
import { generateColorList, generateRandomColor, processData } from './dataProcessor';

const Chart: React.FC<ChartProps> = ({
                                       searchData = [],
                                       metrics,
                                       googleUpdates = [],
                                       isGeneralView,
                                       colors = {},
                                       showLegend = true,
                                       showCustomDots,
                                       isCustomDomain,
                                       hiddenLines,
                                       blurTitle,
                                       numberOfColumns
                                     }) => {
  const { t, i18n } = useTranslation();
  const [activeDotId, setActiveDotId] = useState<string | null>(null);
  const [localHiddenLines, setLocalHiddenLines] = useState<{ [key: string]: boolean }>({});
  const [yAxisState, setYAxisState] = useState({
    custom: { visible: true, left: true },
    clicks: { visible: false, left: true },
    impressions: { visible: false, left: true },
    ctr: { visible: false, left: true },
    position: { visible: false, left: true },
  });

  const colorCache = useRef<{ [domain: string]: string }>({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localeMap = { en: enLocale, es: esLocale };
  const currentLocale = localeMap[i18n.language] || enLocale;

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatDate = (dateString: string, locale: any) => {
    const date = new Date(dateString);
    const timeZone = 'UTC';
    const zonedDate = toZonedTime(date, timeZone);
    const formattedDate = capitalizeFirstLetter(formatTz(zonedDate, 'LLL dd', { locale }));
    return formattedDate;
  };

  const validSearchData = useMemo(() => {
    return searchData.map(d => {
      const formattedDate = format(parseISO(d.date), 'yyyy-MM-dd');
      return { ...d, date: formattedDate };
    })
      .filter(d => !isNaN(new Date(d.date).getTime()))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [searchData]);

  const totalDays = validSearchData.length
    ? differenceInDays(new Date(validSearchData[validSearchData.length - 1].date), new Date(validSearchData[0].date))
    : 0;

  const tickInterval = useMemo(() => {
    const interval = getTickInterval(totalDays);
    return interval;
  }, [totalDays]);

  const {
    normalizedSearchData,
    minCustom,
    maxCustom,
    minClicks,
    maxClicks,
    minCtr,
    maxCtr,
    minPosition,
    maxPosition,
    minImpressions,
    maxImpressions,
    allDomains
  } = useMemo(() => {
    return processData(validSearchData, isCustomDomain, colorCache);
  }, [validSearchData, isCustomDomain]);

  useEffect(() => {
    let activeKeys;

    if (isCustomDomain) {
      activeKeys = allDomains.filter(key => !localHiddenLines[key]);
    } else {
      activeKeys = Object.keys(metrics || {}).filter(key => metrics[key] && !localHiddenLines[key]);
    }

    const customYAxis = isCustomDomain;
    const yAxisUpdates = {
      custom: { visible: customYAxis, left: true },
      clicks: { visible: false, left: true },
      impressions: { visible: false, left: true },
      ctr: { visible: false, left: true },
      position: { visible: false, left: true },
    };

    if (!customYAxis) {
      if (activeKeys.length === 1) {
        yAxisUpdates[activeKeys[0]].visible = true;
        yAxisUpdates[activeKeys[0]].left = true;
      } else if (activeKeys.length === 2) {
        yAxisUpdates[activeKeys[0]].visible = true;
        yAxisUpdates[activeKeys[0]].left = true;
        yAxisUpdates[activeKeys[1]].visible = true;
        yAxisUpdates[activeKeys[1]].left = false;
      }
    }

    setYAxisState(yAxisUpdates);
  }, [metrics, localHiddenLines, isCustomDomain, normalizedSearchData, allDomains]);

  const handleLegendClickLocal = (event: any) => {
    const { dataKey } = event;
    setLocalHiddenLines(prevHiddenLines => ({
      ...prevHiddenLines,
      [dataKey]: !prevHiddenLines[dataKey]
    }));
  };

  const axisColors = {
    impressions: colors.impressions || '#5e35b1',
    clicks: colors.clicks || '#4285f4',
    ctr: colors.ctr || '#00897b',
    position: colors.position || '#e8710a'
  };

  const calculateTicks = (min: number, max: number) => {
    const step = (max - min) / 3;
    return [min, min + step, min + 2 * step, max];
  };

  const yAxisConfig = useMemo(() => isCustomDomain ? (
    <RechartsYAxis yAxisId="custom" domain={[minCustom, maxCustom]} hide={isMobile} axisLine={false} tickLine={false} />
  ) : (
    <>
      {yAxisState.impressions.visible && (
        <RechartsYAxis
          yAxisId="Impressions"
          domain={[minImpressions, maxImpressions]}
          ticks={calculateTicks(minImpressions, maxImpressions)}
          orientation={yAxisState.impressions.left ? 'left' : 'right'}
          tickFormatter={(value) => value.toFixed(0)}
          hide={isMobile}
          stroke={axisColors.impressions}
          axisLine={false}
          tickLine={false}
        />
      )}
      {yAxisState.clicks.visible && (
        <RechartsYAxis
          yAxisId="clicks"
          domain={[minClicks, maxClicks]}
          ticks={calculateTicks(minClicks, maxClicks)}
          orientation={yAxisState.clicks.left ? 'left' : 'right'}
          tickFormatter={(value) => value.toFixed(0)}
          hide={isMobile}
          stroke={axisColors.clicks}
          axisLine={false}
          tickLine={false}
        />
      )}
      {yAxisState.ctr.visible && (
        <RechartsYAxis
          yAxisId="ctr"
          domain={[minCtr * 100, maxCtr * 100]}
          ticks={calculateTicks(minCtr * 100, maxCtr * 100)}
          orientation={yAxisState.ctr.left ? 'left' : 'right'}
          offset={50}
          tickFormatter={(value) => `${value.toFixed(0)}%`}
          hide={isMobile}
          stroke={axisColors.ctr}
          axisLine={false}
          tickLine={false}
        />
      )}
      {yAxisState.position.visible && (
        <RechartsYAxis
          yAxisId="position"
          domain={[minPosition, maxPosition]}
          ticks={calculateTicks(minPosition, maxPosition)}
          orientation={yAxisState.position.left ? 'left' : 'right'}
          offset={100}
          tickFormatter={(value) => (maxPosition - (value - minPosition)).toFixed(0)}
          hide={isMobile}
          stroke={axisColors.position}
          axisLine={false}
          tickLine={false}
        />
      )}
    </>
  ), [isCustomDomain, minCustom, maxCustom, minImpressions, maxImpressions, minClicks, maxClicks, minCtr, maxCtr, minPosition, maxPosition, isMobile, axisColors, yAxisState]);

  const colorList = generateColorList(20);
  const getColor = useCallback((domain: string, index: number) => {
    if (!colorCache.current[domain]) {
      colorCache.current[domain] = index < colorList.length ? colorList[index] : generateRandomColor();
    }
    return colorCache.current[domain];
  }, [colorList]);

  const areaConfig = useMemo(() => (
    isCustomDomain ? allDomains : ['impressions', 'clicks', 'ctr', 'position']
  ).map((key, index) => {
    const color = isCustomDomain ? getColor(key, index) : axisColors[key];
    const shouldRender = !(hiddenLines && hiddenLines[key]) && !localHiddenLines[key];
    return (
      <Area
        key={key}
        type="monotone"
        dataKey={key}
        stroke={color}
        fillOpacity={0.1}
        fill={color}
        yAxisId={isCustomDomain ? 'custom' : 'hidden'}
        hide={!shouldRender}
      />
    );
  }), [isCustomDomain, allDomains, getColor, hiddenLines, localHiddenLines, axisColors]);

  const responsiveContainerStyle = useMemo(() => {
    if (isMobile) {
      return { width: '108%', height: 100, marginLeft: 0 };
    }
    if (isCustomDomain) {
      if (numberOfColumns === 1) {
        return { width: '103%', height: 200, marginLeft: '-20px' };
      } else {
        return { width: '100%', height: 200, marginLeft: '0px' };
      }
    } else {
      const activeKeysCount = Object.keys(metrics || {}).filter(key => metrics[key] && !localHiddenLines[key]).length;
      if (activeKeysCount > 2) {
        return { width: '100%', height: 200, marginLeft: '20px' };
      } else if (numberOfColumns === 1) {
        return { width: '103%', height: 200, marginLeft: '-20px' };
      } else if (activeKeysCount === 1) {
        return { width: '105%', height: 200, marginLeft: '-20px' };
      } else if (activeKeysCount === 2) {
        return { width: '108%', height: 200, marginLeft: '-20px' };
      } else {
        return { width: '113%', height: 200, marginLeft: '-25px' };
      }
    }
  }, [isMobile, isCustomDomain, numberOfColumns, metrics, localHiddenLines]);

  return (
    <div className="chart-container" style={{ position: 'relative', width: '100%', height: isMobile ? 100 : 200 }}>
      {validSearchData.length === 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', position: 'absolute', width: '100%' }}>
          <Typography variant="h6" color="textSecondary">{t('no_data')}</Typography>
        </Box>
      ) : (
        <div style={responsiveContainerStyle}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={normalizedSearchData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <RechartsXAxis
                dataKey="date"
                tickFormatter={date => {
                  const formattedDate = formatDate(date, currentLocale);
                  return formattedDate;
                }}
                interval={tickInterval}
                {...(isGeneralView && { tick: false, axisLine: false, tickLine: false })}
                hide={isMobile}
                axisLine={false}
                tickLine={false}
              />
              <RechartsYAxis yAxisId="hidden" hide />
              {yAxisConfig}
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <RechartsTooltip content={<CustomTooltip isCustomDomain={isCustomDomain} blurTitle={blurTitle} />} />
              {areaConfig}
              {showCustomDots && googleUpdates.map((update, index) => (
                <React.Fragment key={index}>
                  <RechartsReferenceDot
                    x={format(new Date(update.created), 'yyyy-MM-dd')}
                    y={isMobile ? 8 : 0}
                    yAxisId="hidden"
                    ifOverflow="extendDomain"
                    r={0}
                    shape={<CustomDot update={update} activeDotId={activeDotId} setActiveDotId={setActiveDotId} />}
                  />
                  <ReferenceLine
                    x={format(new Date(update.created), 'yyyy-MM-dd')}
                    yAxisId="hidden"
                    stroke="#FFA500"
                    strokeDasharray="3 3"
                  />
                </React.Fragment>
              ))}
              {showLegend && isCustomDomain && !isMobile && (
                <Legend
                  verticalAlign="top"
                  height={36}
                  onClick={handleLegendClickLocal}
                  formatter={(value, entry: any) => (
                    <span style={{ opacity: localHiddenLines[entry.dataKey] ? 0.5 : 1, filter: blurTitle && isCustomDomain ? 'blur(4px)' : 'none' }}>
                      {value}
                    </span>
                  )}
                />
              )}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default Chart;