import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Grow,
  Paper,
  Popper,
  Button,
  MenuItem,
  MenuList,
  ButtonGroup,
  ClickAwayListener,
  CircularProgress
} from '@mui/material';

interface Props {
  loading?: boolean;
  onClickAccount: () => void;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function SplitButton(props: Readonly<Props>) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        ref={anchorRef}
        variant="contained"
        sx={{ border: 'none' }}
        aria-label="Button group with a nested menu">
        <Button disabled={props.loading} className="btn-manage" onClick={props.onClick}>
          {props.loading ? (
            <CircularProgress size={15} sx={{ bgcolor: 'transparent', color: 'white' }} />
          ) : (
            t('add_projects')
          )}
        </Button>
        <Button
          size="small"
          onClick={handleToggle}
          aria-haspopup="menu"
          aria-label="select merge strategy"
          aria-expanded={open ? 'true' : undefined}
          aria-controls={open ? 'split-button-menu' : undefined}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        transition
        open={open}
        role={undefined}
        sx={{ zIndex: 10 }}
        anchorEl={anchorRef.current}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={props.onClickAccount}>{t('add_account')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
